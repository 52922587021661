import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__SHIPPING_ORDERS = 'shippingOrders';

export interface ShippingOrderListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: ShippingOrderListReduxState = {
  loading: false,
};

export default (state: ShippingOrderListReduxState = defaultState): ShippingOrderListReduxState => {
  return state;
};
