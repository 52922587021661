import actionCreatorFactory from 'typescript-fsa';

import { ErrorWrapper } from 'store/base';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import { InvitationApi } from 'api/Invitation.api';
import { InvitationRequestViewModel } from 'viewModels/common/InvitationRequestViewModel';

const actionCreator = actionCreatorFactory();
const type = 'TENANT/USER/INVITATION/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const inviteNewUserAsync =
  (formValues: InvitationRequestViewModel, tenant: string) => async (dispatch) => {
    let result: boolean = false;

    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      if (formValues.role === 'Administrator') {
        result = await InvitationApi.inviteTenantAdmin(formValues, tenant);
      } else if (formValues.role === 'User') {
        result = await InvitationApi.inviteTenantUser(formValues, tenant);
      } else {
        result = await InvitationApi.inviteTenantDriver(formValues, tenant);
      }

      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'User invited successfully');
    }

    async function catchAction(exception: any): Promise<void> {
      toastr.error('Error', 'User not invited');
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    dispatch(asyncActions.started({}));

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return result;
  };

export default inviteNewUserAsync;
