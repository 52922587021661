import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__TRANSLATIONS = 'translations';

export interface TranslationListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: TranslationListReduxState = {
  loading: false,
};

export default (
  state: TranslationListReduxState = defaultState,
  action: Action,
): TranslationListReduxState => {
  return state;
};
