import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { PaginatedListResult } from 'store/base';
import { AddressBookItemContactListResponseViewModel } from 'App/routes/Tenant/AddressBook/ViewModels';
import AddressBookItemContactCreateOrUpdateResponseViewModel from '../viewModels/address/AddressBookItemContactCreateOrUpdateResponseViewModel';

import PartnersRequestViewModel from 'App/routes/Tenant/Partner/ViewModels/PartnersRequestViewModel';
import PartnersResponseViewModel from 'App/routes/Tenant/Partner/ViewModels/PartnersResponseViewModel';
import PartnerResponseViewModel from 'App/routes/Tenant/Partner/ViewModels/PartnerResponseViewModel';
import PartnerServiceViewModel from '../App/routes/Tenant/Partner/ViewModels/PartnerServiceViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/partner`;
}

export class PartnerApi {
  public static getList(
    params: PartnersRequestViewModel,
  ): Promise<PaginatedListResult<PartnersResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<PartnersResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<PartnerResponseViewModel | null> {
    return ApiUtilities.handleGet<PartnerResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: PartnerResponseViewModel): Promise<string> {
    return ApiUtilities.handlePost<string>(getRoot(), formValues);
  }

  public static update(id: string, formValues: PartnerResponseViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static getContactList(id: string): Promise<AddressBookItemContactListResponseViewModel[]> {
    return ApiUtilities.handleGet<AddressBookItemContactListResponseViewModel[]>(
      `${getRoot()}/${id}/contact`,
      {},
    );
  }

  public static addContact(
    id: string,
    formValues: AddressBookItemContactCreateOrUpdateResponseViewModel,
  ): Promise<number> {
    return ApiUtilities.handlePost<number>(`${getRoot()}/${id}/contact`, formValues);
  }

  public static updateContact(
    id: string,
    formValues: AddressBookItemContactCreateOrUpdateResponseViewModel,
  ) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}/contact/`, formValues);
  }

  public static getContact(
    id: string,
    contactId: number,
  ): Promise<AddressBookItemContactCreateOrUpdateResponseViewModel> {
    return ApiUtilities.handleGet<AddressBookItemContactCreateOrUpdateResponseViewModel>(
      `${getRoot()}/${id}/contact/${contactId}`,
      {},
    );
  }

  public static deleteContact(id: string, contactId: number): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/contact/${contactId}/delete`, {});
  }

  public static getServiceList(id: string): Promise<PartnerServiceViewModel[]> {
    return ApiUtilities.handleGet<PartnerServiceViewModel[]>(`${getRoot()}/${id}/service`, {});
  }

  public static addService(id: string, formValues: PartnerServiceViewModel): Promise<number> {
    return ApiUtilities.handlePost<number>(`${getRoot()}/${id}/service`, formValues);
  }

  public static updateService(id: string, formValues: PartnerServiceViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}/service/`, formValues);
  }

  public static getService(id: string, contactId: number): Promise<PartnerServiceViewModel> {
    return ApiUtilities.handleGet<PartnerServiceViewModel>(
      `${getRoot()}/${id}/service/${contactId}`,
      {},
    );
  }

  public static deleteService(id: string, contactId: number): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/service/${contactId}/delete`, {});
  }
}
