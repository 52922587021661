import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { PaginatedListResult } from 'store/base';
import { ShipmentStatusUpdateRequest } from 'viewModels/shipment/ShipmentStatusUpdateRequest';
import {
  PriceRequestDetailResponseViewModel,
  PriceRequestResponseViewModel,
  PriceRequestsResponseViewModel,
} from 'App/routes/Tenant/PriceRequest/ViewModels';
import PriceRequestPricesUpdateRequestViewModel from '../App/routes/Tenant/PriceRequest/ViewModels/PriceRequestPricesUpdateRequestViewModel';
import PriceRequestDecisionUpdateRequestViewModel from '../App/routes/Tenant/PriceRequest/ViewModels/PriceRequestDecisionUpdateRequestViewModel';
import PriceRequestsRequestViewModel from '../App/routes/Tenant/PriceRequest/ViewModels/PriceRequestsRequestViewModel';
import CommonUtilities from 'helpers/CommonUtilities';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/price-request`;
}

export class PriceRequestApi {
  public static getList(
    params: PriceRequestsRequestViewModel,
  ): Promise<PaginatedListResult<PriceRequestsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<PriceRequestsResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<PriceRequestDetailResponseViewModel | null> {
    return ApiUtilities.handleGet<PriceRequestDetailResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: PriceRequestResponseViewModel): Promise<string> {
    return ApiUtilities.handlePost<string>(getRoot(), formValues);
  }

  public static update(id: string, formValues: PriceRequestResponseViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static updateStatus(
    id: string,
    formValues: ShipmentStatusUpdateRequest,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/update-status`, formValues);
  }

  public static getOpenedRequestsCount(params: any): Promise<number> {
    const URL = `${getRoot()}/opened-requests-count`;
    return ApiUtilities.handleGet<number>(URL, { ...params });
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static saveOfferedPrices(
    id: string,
    formValues: PriceRequestPricesUpdateRequestViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/save-offered-prices`, formValues);
  }

  public static addOfferedPrices(
    id: string,
    formValues: PriceRequestPricesUpdateRequestViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/add-offered-prices`, formValues);
  }

  public static giveDecision(
    id: string,
    formValues: PriceRequestDecisionUpdateRequestViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/give-decision`, formValues);
  }

  public static async downloadPriceQuotePdfFile(id: string, number: string) {
    const URL = `${getRoot()}/${id}/quote-print`;
    const file = await ApiUtilities.handleFileGet(URL);
    const filename = 'PriceQuote_' + number + '.pdf';
    CommonUtilities.downloadFile(file, false, filename);
  }
}
