import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const FullTrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 41,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="12 59 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-05"
         ry="1.0898695"
         rx="1.0904982"
         cy="66.277367"
         cx="18.123833" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-07"
         ry="1.0898681"
         rx="1.0904984"
         cy="66.277367"
         cx="21.563667" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-16"
         cx="18.123863"
         cy="66.277893"
         rx="0.5615539"
         ry="0.56122905" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-7"
         cx="21.563446"
         cy="66.277893"
         rx="0.5615539"
         ry="0.56122905" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 22.699203,66.045897 h 2.336278 V 64.922853 H 14.652019 v 1.123044 h 2.336279"
         id="path23925" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 25.035501,64.458417 v -4.827251 l -10.383502,10e-7 v 4.82725 z"
         id="path23927" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 19.324577,66.045896 h 1.038346"
         id="path28474" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 15.949938,64.458417 V 63.69622"
         id="path28476" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 23.737563,64.458417 V 63.69622"
         id="path28478" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 21.141688,64.458417 V 63.69622"
         id="path28480" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 18.545813,64.458417 V 63.69622"
         id="path28482" />
    </svg>
  );
};

export default FullTrailerIcon;
