import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper } from '../../../../../../../store/base';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';
import UserCreateOrUpdateRequestViewModel from '../../../../../../../viewModels/user/UserCreateOrUpdateRequestViewModel';
import { UserApi } from '../../../../../../../api/User.api';
import UserAccountViewModel from '../../../../../../../viewModels/user/UserAccountViewModel';

const actionCreator = actionCreatorFactory();
const type = 'TENANT/USERNAME/EXISTS';
export const asyncActions = actionCreator.async<
  {},
  { existingUser: UserAccountViewModel },
  ErrorWrapper
>(type);

export default function start() {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const state = getState();
      let formValues: UserCreateOrUpdateRequestViewModel = state.form[''].values || {};
      var user;

      if (
        formValues.username != null &&
        formValues.username !== undefined &&
        formValues.username.length > 0
      )
        user = await UserApi.findUserNameExists(formValues.username);
      dispatch(asyncActions.done({ params: {}, result: { existingUser: user } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
