import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_KEY__INVOICE = 'invoice';

export class InvoiceState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  invoiceId?: string;
  loading: boolean;
}

const defaultState: InvoiceState = {
  loading: false,
};
export default (state: InvoiceState = defaultState, action: Action): InvoiceState => {
  return state;
};
