import {
  APP_NAME,
  APP_PORTAL_NAME,
  IS_CLIENT_PORTAL_URL,
  IS_DEV,
  IS_MOBILE_APP_URL,
  MOBILE_APP_NAME,
} from './constant';

const BACKEND_URL = (): string => {
  if (IS_CLIENT_PORTAL_URL)
    return window.location.origin.replace(`://${APP_PORTAL_NAME}.`, '://api.') + '/';
  if (IS_MOBILE_APP_URL)
    return window.location.origin.replace(`://${MOBILE_APP_NAME}.`, '://api.') + '/';
  return window.location.origin.replace(`://${APP_NAME}.`, '://api.') + '/';
};
// By convention we use the backend on the same domain as the UI.
// E.g. 'tms.cargokeep.com' uses backend 'api.cargokeep.com'
// This preserves localhost as is.
export const BACKEND_HOST = IS_DEV ? 'http://localhost:64665/' : BACKEND_URL();

export const BACKEND_HOST_AUTH = (): string => {
  return BACKEND_HOST;
};

export const BACKEND_HOST_MAIN = (): string => {
  return BACKEND_HOST;
};

export const BACKEND_HOST_TRANSLATIONS = (): string => {
  // for developer's convenience, use translations from sandbox normally.
  if (IS_DEV) return 'http://localhost:64665/';
  else return BACKEND_HOST;
};

export const BACKEND_HOST_FILE_UPLOAD = (): string => {
  return BACKEND_HOST;
};

/*
    Backend routes
 */
export const endpoints = {
  auth: {
    register: 'api/account/register',
    login: 'connect/token',
  },
};

/*
    Frontend routes
 */
export const routePaths = {
  dashboard: '/dashboard',
  dashboard1: '/',
  login: '/login',
  loginClientPortal: '/client-portal',
  controlTower: '/ct',
  invitation: '/invitation',
  spotRequest: '/spot_request',
  shippingOrder: '/shipping-order',
  publicInvoice: '/public-invoice',
  changePassword: '/change-password',
  resetPassword: '/reset-password',
  profile: '/profile',
  settings: '/settings',
  settingsNavs: {
    users: '/employees',
    vat: '/vat',
    packageTypes: '/package_types',
    termsAndConditions: '/terms_and_conditions',
    invoice: '/invoice_settings',
    pdf: '/pdf_settings',
    messageTemplates: '/message_templates',
    ipAccessManagement: '/ip_access_management',
    preferences: '/preferences',
  },
  messagetemplates: {
    list: '/message_template',
    new: '/message_template/new',
    edit: '/message_template/:id',
    editId: (id: number) => `/message_template/${id}`,
  },
  tenants: {
    list: '/tenants',
    new: '/tenants/new',
    edit: '/tenants/:id',
    editId: (id: string) => `/tenants/${id}`,
  },
  inbox: {
    list: '/inbox',
  },
  priceRequests: {
    list: '/price-requests',
    display: '/price-request/:id',
    new: '/price-requests/new',
    edit: '/price-requests/:id',
    editId: (id: string) => `/price-requests/${id}`,
    displayId: (id: string) => `/price-request/${id}`,
  },
  addressBook: {
    list: '/address-book',
    new: '/address-book/new',
    edit: '/address-book/:id',
    editId: (id: string) => `/address-book/${id}`,
  },
  projects: {
    list: '/projects',
    edit: '/projects/:id',
    editId: (id: string) => `/projects/${id}`,
  },
  shipments: {
    list: '/shipments',
    display: '/shipment/:id',
    new: '/shipments/new',
    edit: '/shipments/:id',
    editId: (id: string) => `/shipments/${id}`,
    displayId: (id: string) => `/shipment/${id}`,
  },
  shippingOrders: {
    list: '/shipping-orders',
    edit: '/shipping-orders/:id',
    displayId: (id: string) => `/shipping-orders/${id}`,
    editId: (id: string) => `/shipping-orders/${id}`,
  },
  terminals: {
    list: '/terminal',
    new: '/terminal/new',
    edit: '/terminal/:id',
    editId: (id: string) => `/terminal/${id}`,
  },
  clients: {
    list: '/client',
    new: '/client/new',
    edit: '/client/:id',
    import: '/client/import',
    editId: (id: string) => `/client/${id}`,
  },
  fleet: {
    list: '/fleet',
    new: '/fleet/new',
    edit: '/fleet/:id',
    editId: (id: string) => `/fleet/${id}`,
  },
  partners: {
    list: '/partners',
    new: '/partners/new',
    edit: '/partners/:id',
    editId: (id: string) => `/partners/${id}`,
  },
  invoices: {
    list: '/invoices',
    edit: '/invoices/:id',
    editId: (id: string) => `/invoices/${id}`,
  },
  priceLists: {
    list: '/price-lists',
  },
  comments: {
    list: '/comments',
  },
  reports: {
    list: '/reports',
  },
  map: {
    list: '/map',
  },
  translations: {
    list: '/translations',
  },
  errors: {
    offline: '/offline',
  },
};

export const navigateToPaths = {
  login: '/login',
};
