import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const StatusCanceledIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 38,
  height = 38,
  fill = '#0A6CBA',
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.686824 0.00199967C0.473078 0.0460063 0.393584 0.0824929 0.322144 0.131777C0.262318 0.173043 0.177705 0.25687 0.135825 0.316326C-0.0453165 0.573459 -0.0452764 0.919362 0.135954 1.17645C0.177804 1.23579 0.262561 1.31857 0.314051 1.37006L6.84732 7.90292C6.85956 7.91516 6.86927 7.9297 6.8759 7.94569C6.88253 7.96169 6.88594 7.97884 6.88594 7.99615C6.88594 8.01347 6.88253 8.03061 6.87591 8.04661C6.86928 8.06261 6.85958 8.07714 6.84734 8.08939L0.314032 14.6248C0.262552 14.6763 0.177705 14.7591 0.135825 14.8186C-0.0453165 15.0757 -0.0452764 15.4216 0.135954 15.6787C0.177804 15.7381 0.261928 15.8222 0.32136 15.8641C0.57841 16.0453 0.924202 16.0453 1.18121 15.864C1.24053 15.8221 1.32329 15.7374 1.37478 15.6859L7.90808 9.15046C7.92032 9.13822 7.93485 9.12851 7.95084 9.12188C7.96684 9.11526 7.98397 9.11185 8.00128 9.11185C8.01859 9.11185 8.03573 9.11526 8.05172 9.12189C8.06772 9.12852 8.08224 9.13824 8.09448 9.15048L14.6252 15.6858C14.6767 15.7374 14.7595 15.8222 14.819 15.8641C15.076 16.0453 15.4218 16.0453 15.6788 15.864C15.7381 15.8221 15.8223 15.738 15.8642 15.6785C16.0453 15.4214 16.0453 15.0755 15.864 14.8184C15.8222 14.7591 15.7375 14.6763 15.686 14.6248L9.15522 8.08941C9.1305 8.06467 9.11661 8.03113 9.11662 7.99615C9.11662 7.96118 9.13051 7.92764 9.15523 7.90291L15.686 1.37008C15.7374 1.31858 15.8223 1.23572 15.8642 1.17627C16.0453 0.919139 16.0453 0.573236 15.864 0.316147C15.8222 0.256806 15.7377 0.172984 15.6779 0.131638C15.5714 0.0580032 15.4475 0.013294 15.3185 0.00195095C15.2462 -0.00442827 15.1275 0.005373 15.0571 0.0235309C14.9723 0.0454096 14.8919 0.0820848 14.8198 0.131818C14.76 0.173083 14.6767 0.257499 14.6252 0.308996L8.0945 6.84183C8.06977 6.86656 8.03624 6.88045 8.00128 6.88046C7.96632 6.88046 7.93279 6.86657 7.90806 6.84184L1.37479 0.308984C1.3233 0.257498 1.24006 0.173014 1.18022 0.131704C1.09163 0.070553 0.990835 0.0292477 0.88482 0.0106523C0.813267 -0.00189832 0.649827 0.00199967 0.686824 0.00199967Z"
        fill="black"
      />
    </svg>
  );
};

export default StatusCanceledIcon;
