import { AddressViewModel } from 'viewModels/common/AddressViewModel';
import { OpeningHourViewModel } from 'viewModels/common/OpeningHourViewModel';

export default class AddressBookItemResponseViewModel {
  name: string;
  customer: string;
  address: AddressViewModel;
  isDefault: boolean;
  openingHours: OpeningHourViewModel[];
}
