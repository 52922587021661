import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import { ShipmentApi } from 'api/Shipment.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { ShipmentDetailResponseViewModel } from '../../ViewModels';

const actionCreator = actionCreatorFactory();
const type = 'SHIPMENT/EDIT/START';
export const asyncActions = actionCreator.async<{}, { shipmentId: string }, ErrorWrapper>(type);

export default function start(shipmentId: string) {
  return async (dispatch: Dispatch<any>) => {
    let shipmentInfo: ShipmentDetailResponseViewModel = new ShipmentDetailResponseViewModel();

    async function mainAction() {
      dispatch(asyncActions.started({}));
      const shipment = await ShipmentApi.getSingle(shipmentId);
      if (!shipment) {
        throw new NotFoundErrorWrapper(`Item with Id ${shipmentId} not found`);
      }
      shipmentInfo = shipment;
      if (shipment.useAlternativeLoadingAddress) {
        shipmentInfo.consignor.alternativeAddress = true;
      }
      if (shipment.useAlternativeDeliveryAddress) {
        shipmentInfo.consignee.alternativeAddress = true;
      }
      dispatch(asyncActions.done({ params: {}, result: { shipmentId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return shipmentInfo;
  };
}
