import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__EMPLOYEES = 'employees';

export interface EmployeesReduxState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  loading: boolean;
}

const defaultState: EmployeesReduxState = {
  loading: false,
};
export default (state: EmployeesReduxState = defaultState, action: Action): EmployeesReduxState => {
  return state;
};
