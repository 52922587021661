import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const StatusDeliveredIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 38,
  height = 38,
  fill = 'black',
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.098 0.00910539C13.9024 0.0393649 13.7282 0.132811 13.6132 0.269188C13.5705 0.319881 13.5204 0.412353 13.4892 0.469131L6.03288 14.0397C6.02695 14.0506 6.01782 14.06 6.00635 14.067C5.99489 14.0741 5.98147 14.0785 5.96738 14.08C5.95328 14.0814 5.93898 14.0798 5.92583 14.0754C5.91267 14.0709 5.9011 14.0636 5.89222 14.0543L1.4454 9.38255C1.39891 9.33371 1.32419 9.25351 1.26838 9.21226C1.02834 9.03484 0.679288 9.00228 0.398787 9.13116C0.333684 9.16108 0.238692 9.22495 0.189609 9.27184C-0.0214991 9.47359 -0.0602389 9.76694 0.0931143 10.0027C0.128712 10.0574 0.205319 10.1362 0.251789 10.1851L5.47451 15.6758C5.52095 15.7246 5.59618 15.8046 5.65238 15.8454C5.99114 16.0918 6.51754 16.037 6.77442 15.7285C6.81704 15.6773 6.86702 15.5845 6.89821 15.5277L14.8717 1.0125C14.9029 0.955713 14.9551 0.863754 14.9743 0.80341C15.0566 0.54453 14.9372 0.267921 14.6764 0.113671C14.6157 0.0777617 14.5054 0.0347699 14.4334 0.0190709C14.4112 0.0142345 14.3888 0.0102114 14.3661 0.00708698C14.2928 -0.0030994 14.1711 -0.00220199 14.098 0.0091156L14.098 0.00910539Z"
        fill={fill}
      />
    </svg>
  );
};

export default StatusDeliveredIcon;
