import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as createStartActions } from './New/actions/Tenant.create.start.action';
import { asyncActions as createSubmitActions } from './New/actions/Tenant.create.submit.action';
import { asyncActions as editStartActions } from './Edit/actions/Tenant.edit.start.action';
import { asyncActions as editSubmitActions } from './Edit/actions/Tenant.edit.submit.action';
import { asyncActions as getUsersActions } from './Edit/actions/Tenant.User.get-users.action';
import { asyncActions as getUserTenantsActions } from './Edit/actions/Tenant.username.exists.action';
import { asyncActions as addExistingUserActions } from './Edit/actions/Tenant.add.existing.user.action';
import { asyncActions as invioteUserSubmitActions } from 'shared/actions/invitation/User.invitation.submit.action';
import { createCancel } from './New/actions';
import { editCancel } from './Edit/actions';
import { ErrorWrapper, TableData } from 'store/base';
import UsersResponseViewModel from 'viewModels/user/UsersResponseViewModel';
import UserAccountViewModel from 'viewModels/user/UserAccountViewModel';

export const REDUCER_KEY__TENANT = 'tenant';

export class TenantState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  errorWrapperUser?: ErrorWrapper;
  tenantId?: number;
  loading: boolean;
  tenantUserId: number;
  usersTableData: TableData<UsersResponseViewModel>;
  usersPagination: { total: number; current: number; pageSize: number };
  loadingUsers: boolean;
  loadingUser: boolean;
  existingUser?: UserAccountViewModel;
}

const defaultState: TenantState = {
  loading: false,
  usersPagination: { total: 0, current: 0, pageSize: 50 },
  usersTableData: TableData.getDefault<UsersResponseViewModel>(),
  tenantUserId: 0,
  loadingUser: false,
  loadingUsers: false,
  existingUser: undefined,
};
export default (state: TenantState = defaultState, action: Action): TenantState => {
  // Create actions
  if (isType(action, createCancel)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, createStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, createStartActions.done)) {
    return { type: action.type, ...defaultState };
  }
  if (isType(action, createStartActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, createSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, createSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, createSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Edit actions
  if (isType(action, editCancel)) {
    return { ...state, type: action.type, loading: false, tenantId: undefined };
  }
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editStartActions.done)) {
    return { type: action.type, tenantId: action.payload.result.tenantId, ...defaultState };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  /** USERS **/

  if (isType(action, getUsersActions.started)) {
    return {
      ...state,
      type: action.type,
      loadingUsers: true,
      usersTableData: TableData.getDefault<UsersResponseViewModel>(),
    };
  }

  if (isType(action, getUsersActions.done)) {
    return {
      ...state,
      type: action.type,
      loadingUsers: false,
      usersTableData: action.payload.result.tableData,
    };
  }

  if (isType(action, getUsersActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loadingUsers: false };
  }

  /* find user exists*/
  if (isType(action, getUserTenantsActions.started)) {
    return {
      ...state,
      type: action.type,
      loadingUser: true,
      errorWrapperUser: undefined,
      existingUser: undefined,
    };
  }
  if (isType(action, getUserTenantsActions.done)) {
    return {
      ...state,
      type: action.type,
      loadingUser: false,
      existingUser: action.payload.result.existingUser,
    };
  }
  if (isType(action, getUserTenantsActions.failed)) {
    return { ...state, type: action.type, loadingUser: false, existingUser: undefined };
  }

  /* add Existing User Actions */
  if (isType(action, addExistingUserActions.started)) {
    return { ...state, type: action.type, loadingUser: true, errorWrapperUser: undefined };
  }
  if (isType(action, addExistingUserActions.done)) {
    return { ...state, type: action.type, loadingUser: false, existingUser: undefined };
  }
  if (isType(action, addExistingUserActions.failed)) {
    return {
      ...state,
      type: action.type,
      loadingUser: false,
      errorWrapperUser: action.payload.error,
    };
  }

  /* invite users Actions */
  if (isType(action, invioteUserSubmitActions.started)) {
    return { ...state, type: action.type, errorWrapperUser: undefined };
  }

  if (isType(action, invioteUserSubmitActions.failed)) {
    return {
      ...state,
      type: action.type,
      loadingUser: false,
      errorWrapperUser: action.payload.error,
    };
  }

  return state;
};
