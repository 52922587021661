export class PublicInvoiceViewModel {
  tenantName: string;
  number: string;
  date?: Date;
  shipments: InvoicePublicAccessShipmentViewModel[];
}

export type InvoicePublicAccessShipmentViewModel = {
  id: string;
  number: string;
  pickupCountry: string;
  deliveryCountry: string;
  pickupPostalCode: string;
  deliveryPostalCode: string;
  pods: InvoicePublicAccessPodViewModel[];
};

export type InvoicePublicAccessPodViewModel = {
  fileName: string;
  id: string;
};
