import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { Dispatch } from 'redux';
import { SpotRequestApi } from 'api/SpotRequest.api';
import SpotRequestPriceOfferViewModel from 'viewModels/spotRequest/SpotRequestPriceOfferViewModel';
import SpotRequestUtilities from 'helpers/ModelUtilities/SpotRequestUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('APP/SPOT_REQUEST/SUBMIT');

export default function submitSpotRequest(
  token: string,
  formValues: SpotRequestPriceOfferViewModel,
) {
  let result: boolean = false;

  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      SpotRequestUtilities.formatOfferFormValuesNumbers(formValues);
      const data = await SpotRequestApi.submitOfferPricesForm(token, formValues);

      if (!data) {
        throw new NotFoundErrorWrapper(`Item not found`);
      }
      result = data;

      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return result;
  };
}
