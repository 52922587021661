import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper } from 'store/base';
import { AddressBookApi } from 'api/AddressBook.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import AddressUtilities from 'helpers/ModelUtilities/AddressUtilities';
import { AddressBookItemResponseViewModel } from '../../ViewModels';
import LocaleUtilities from 'helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'ADDRESS_BOOK/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const submitAction =
  (formValues: AddressBookItemResponseViewModel, id: string) => async (dispatch) => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      AddressUtilities.formatFormValuesNumbers(formValues);
      await AddressBookApi.update(id, formValues);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: any): Promise<void> {
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };

export default submitAction;
