import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper } from 'store/base';
import { ShipmentApi } from 'api/Shipment.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import ShipmentUtilities from 'helpers/ModelUtilities/ShipmentUtilities';
import { ShipmentResponseViewModel } from '../../ViewModels';
import LocaleUtilities from 'helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'SHIPMENT/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const submitAction =
  (formValues: ShipmentResponseViewModel, id: string | undefined) => async (dispatch) => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      ShipmentUtilities.formatFormValuesNumbers(formValues);
      if (!id) {
        const response = await ShipmentApi.add(formValues);
        if (response) id = response;
      } else {
        await ShipmentApi.update(id, formValues);
      }
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
    return id;
  };

export default submitAction;
