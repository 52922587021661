import { connect } from 'react-redux';
import App, { AppComponentDispatchProps, AppComponentStateProps } from './App.component';
import { AppReduxState, REDUCER_NAME__APP } from './App.reducer';
import { AuthUtils } from '../helpers/AuthUtils';

const mapStateToProps = (state: any): AppComponentStateProps => {
  const currentState: AppReduxState = state[REDUCER_NAME__APP];
  return {
    loading: currentState.loading,
    isLoggedIn: !!AuthUtils.currentUserData(),
    settings: currentState.settings,
  };
};

const mapDispatchToProps = {};
export default connect<AppComponentStateProps, AppComponentDispatchProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(App);
