import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as createSubmitActions } from './New/actions/Fleet.create.submit.action';
import { asyncActions as editStartActions } from './Edit/actions/Fleet.edit.start.action';
import { asyncActions as editSubmitActions } from './Edit/actions/Fleet.edit.submit.action';
import { ErrorWrapper } from '../../../../../store/base';

export const REDUCER_KEY__FLEET = 'fleet';

export class FleetState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  errorWrapperUser?: ErrorWrapper;
  fleetId?: string;
  loading: boolean;
}

const defaultState: FleetState = {
  loading: false,
};
export default (state: FleetState = defaultState, action: Action): FleetState => {
  // Create actions

  if (isType(action, createSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, createSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }

  if (isType(action, createSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Edit actions
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editStartActions.done)) {
    return { type: action.type, fleetId: action.payload.result.fleetId, ...defaultState };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  return state;
};
