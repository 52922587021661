import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryColor } from 'theme';

const HouseIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 76,
  height = 76,
  fill = primaryColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 75C58.2107 75 75 58.2107 75 37.5C75 16.7893 58.2107 0 37.5 0C16.7893 0 0 16.7893 0 37.5C0 58.2107 16.7893 75 37.5 75Z"
        fill="white"
      />
      <path
        d="M37.5 71.2747C56.1533 71.2747 71.2747 56.1532 71.2747 37.5C71.2747 18.8468 56.1533 3.72534 37.5 3.72534C18.8468 3.72534 3.72534 18.8468 3.72534 37.5C3.72534 56.1532 18.8468 71.2747 37.5 71.2747Z"
        stroke="#0A6CBA"
        strokeWidth="0.973686"
      />
      <path
        d="M17.6565 54.3672V28.0742C17.6565 27.9224 17.6951 27.7731 17.7687 27.6403C17.8423 27.5075 17.9484 27.3956 18.0772 27.3152L37.0796 15.4387C37.2057 15.3598 37.3515 15.318 37.5002 15.318C37.649 15.318 37.7948 15.3598 37.9209 15.4387L56.9233 27.3152C57.052 27.3956 57.1582 27.5075 57.2318 27.6403C57.3054 27.7731 57.344 27.9224 57.344 28.0742V54.3672C57.344 54.4987 57.2917 54.6249 57.1987 54.7179C57.1057 54.811 56.9795 54.8632 56.8479 54.8632H50.3987C50.2671 54.8632 50.1409 54.811 50.0479 54.7179C49.9548 54.6249 49.9026 54.4987 49.9026 54.3672V30.5547C49.9026 30.4231 49.8503 30.2969 49.7573 30.2039C49.6643 30.1108 49.5381 30.0586 49.4065 30.0586H25.594C25.4624 30.0586 25.3362 30.1108 25.2432 30.2039C25.1502 30.2969 25.0979 30.4231 25.0979 30.5547V54.3672C25.0979 54.4987 25.0456 54.6249 24.9526 54.7179C24.8596 54.811 24.7334 54.8632 24.6018 54.8632H18.1526C18.021 54.8632 17.8948 54.811 17.8018 54.7179C17.7088 54.6249 17.6565 54.4987 17.6565 54.3672Z"
        fill="#0A6CBA"
      />
      <path
        d="M26.5859 54.3672V32.043C26.5859 31.9114 26.6382 31.7852 26.7312 31.6922C26.8243 31.5992 26.9505 31.5469 27.082 31.5469H47.918C48.0495 31.5469 48.1757 31.5992 48.2688 31.6922C48.3618 31.7852 48.4141 31.9114 48.4141 32.043V54.3672C48.4141 54.4988 48.3618 54.625 48.2688 54.718C48.1757 54.811 48.0495 54.8633 47.918 54.8633H27.082C26.9505 54.8633 26.8243 54.811 26.7312 54.718C26.6382 54.625 26.5859 54.4988 26.5859 54.3672Z"
        fill="#0A6CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3086 51.1836H41.6914C41.7566 51.1836 41.8211 51.1964 41.8813 51.2214C41.9414 51.2463 41.9961 51.2828 42.0422 51.3289C42.0883 51.375 42.1248 51.4296 42.1497 51.4898C42.1747 51.55 42.1875 51.6145 42.1875 51.6797V52.0937C42.1875 52.2253 42.1352 52.3515 42.0422 52.4445C41.9492 52.5376 41.823 52.5898 41.6914 52.5898H33.3086C33.177 52.5898 33.0508 52.5376 32.9578 52.4445C32.8648 52.3515 32.8125 52.2253 32.8125 52.0937V51.6797C32.8125 51.6145 32.8253 51.55 32.8503 51.4898C32.8752 51.4296 32.9117 51.375 32.9578 51.3289C33.0039 51.2828 33.0586 51.2463 33.1187 51.2214C33.1789 51.1964 33.2434 51.1836 33.3086 51.1836Z"
        fill="white"
      />
    </svg>
  );
};

export default HouseIcon;
