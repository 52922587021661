import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { NullableLocalStorageUserModel } from 'helpers/AuthUtils';
import { ErrorWrapper } from 'store/base';
import { asyncActions as startActions } from './actions/App.start.action';
import { asyncActions as loginActions } from './routes/Sessions/Login/actions/App.login.submit.action';
import { asyncActions as loginClientPortalActions } from './routes/Sessions/LoginClientPortal/actions/App.clientLogin.submit.action';
import { asyncActions as startInvitationActions } from './routes/Sessions/Invitation/actions/App.invitation.start.action';
import { asyncActions as submitInvitationActions } from './routes/Sessions/Invitation/actions/App.invitation.submit.action';
import { asyncActions as startSpotRequestActions } from './routes/Public/SpotRequest/actions/SpotRequest.start.action';
import { asyncActions as submitSpotRequestActions } from './routes/Public/SpotRequest/actions/SpotRequest.submit.action';
import { asyncActions as startPulicInvoiceRequestActions } from './routes/Public/Invoice/actions/Invoice.start.action';
import { asyncActions as startShippingOrderConfirmation } from './routes/Public/ShippingOrder/actions/ShippingOrderConfirmation.start.action';
import { asyncActions as submitShippingOrderConfirmation } from './routes/Public/ShippingOrder/actions/ShippingOrderConfirmation.submit.acton';

export const REDUCER_NAME__APP = 'app';

export interface AppReduxState {
  loading: boolean;
  user: NullableLocalStorageUserModel;
  settings: any;
  error?: ErrorWrapper;
  loginSuccessful: boolean;
  lastUsername?: string;
  lastClientCode?: string;
}

const defaultState: AppReduxState = {
  loading: false,
  user: null,
  settings: {},
  loginSuccessful: false,
  lastUsername: undefined,
  lastClientCode: undefined,
};

export default (state: AppReduxState = defaultState, action: Action) => {
  // Set or unset user
  if (isType(action, startActions.started)) {
    return { ...state, type: action.type };
  }
  if (isType(action, startActions.done)) {
    return { ...state, type: action.type, loading: false, user: action.payload.result.user };
  }
  if (isType(action, startActions.failed)) {
    return { ...state, type: action.type, loading: false, user: null, error: action.payload.error };
  }

  // Login actions
  if (isType(action, loginActions.started)) {
    return {
      ...state,
      type: action.type,
      loading: true,
      user: state.user,
      error: undefined,
      loginSuccessful: false,
      lastUsername: action.payload.lastUsername,
      lastClientCode: undefined,
    };
  }
  if (isType(action, loginActions.done)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      user: action.payload.result.user,
      error: undefined,
      loginSuccessful: true,
      lastUsername: undefined,
      lastClientCode: undefined,
    };
  }
  if (isType(action, loginActions.failed)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      user: state.user,
      error: action.payload.error,
    };
  }
  // Login  client portal actions
  if (isType(action, loginClientPortalActions.started)) {
    return {
      ...state,
      type: action.type,
      loading: true,
      user: state.user,
      error: undefined,
      loginSuccessful: false,
      lastUsername: action.payload.lastUsername,
      lastClientCode: action.payload.lastClientCode,
    };
  }
  if (isType(action, loginClientPortalActions.done)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      user: action.payload.result.user,
      error: undefined,
      loginSuccessful: true,
      lastUsername: undefined,
    };
  }
  if (isType(action, loginClientPortalActions.failed)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      user: state.user,
      error: action.payload.error,
    };
  }

  // Invitation start action
  if (isType(action, startInvitationActions.started)) {
    return { ...state, type: action.type, error: undefined };
  }

  if (isType(action, startInvitationActions.failed)) {
    return {
      ...state,
      type: action.type,
      error: action.payload.error,
    };
  }
  // Invitation submit action
  if (isType(action, submitInvitationActions.started)) {
    return { ...state, type: action.type, error: undefined };
  }

  if (isType(action, submitInvitationActions.failed)) {
    return {
      ...state,
      type: action.type,
      error: action.payload.error,
    };
  }

  // Spot request start action
  if (isType(action, startSpotRequestActions.started)) {
    return { ...state, type: action.type, error: undefined };
  }

  if (isType(action, startSpotRequestActions.failed)) {
    return {
      ...state,
      type: action.type,
      error: action.payload.error,
    };
  }

  // Public invoice request start action
  if (isType(action, startPulicInvoiceRequestActions.started)) {
    return { ...state, type: action.type, error: undefined };
  }

  if (isType(action, startPulicInvoiceRequestActions.failed)) {
    return {
      ...state,
      type: action.type,
      error: action.payload.error,
    };
  }

  // Spot request submit action
  if (isType(action, submitSpotRequestActions.started)) {
    return { ...state, type: action.type, error: undefined };
  }

  if (isType(action, submitSpotRequestActions.failed)) {
    return {
      ...state,
      type: action.type,
      error: action.payload.error,
    };
  }

  // Public shipping order confirmation submit action
  if (isType(action, startShippingOrderConfirmation.started)) {
    return { ...state, type: action.type, error: undefined };
  }

  if (isType(action, submitShippingOrderConfirmation.failed)) {
    return {
      ...state,
      type: action.type,
      error: action.payload.error,
    };
  }
  
  return state;
};
