import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const MegaTrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="9 35 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20047" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858"
         ry="1.090391"
         rx="1.0903902"
         cy="42.465626"
         cx="17.065624" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20047" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2"
         ry="1.090391"
         rx="1.0903903"
         cy="42.465626"
         cx="19.711458" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20047" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0"
         ry="1.0903896"
         rx="1.0903906"
         cy="42.465626"
         cx="22.357292" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199878" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="m 23.471963,42.233394 h 1.563514 V 41.110356 H 12.006189 v 1.123038 h 3.908787"
         id="path13905" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 18.25625,42.251042 h 0.264583"
         id="path13907" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 20.902083,42.251042 h 0.264584"
         id="path13909" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19992" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911"
         cx="17.065624"
         cy="42.465626"
         rx="0.56149834"
         ry="0.56149763" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19992" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0"
         cx="19.711458"
         cy="42.465626"
         rx="0.56149834"
         ry="0.56149763" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19992" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9"
         cx="22.357292"
         cy="42.465626"
         rx="0.56149834"
         ry="0.56149763" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 25.03548,40.645896 v -4.82721 H 6.7145202 v 4.82721 z"
         id="path15000" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 9.3318001,40.645896 v -0.76219"
         id="path15002" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 11.949081,40.645896 v -0.76219"
         id="path15004" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 14.566361,40.645896 v -0.76219"
         id="path15006" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 17.183639,40.645896 v -0.76219"
         id="path15008" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 19.80092,40.645896 v -0.76219"
         id="path15010" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 22.418199,40.645896 v -0.76219"
         id="path15012" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 10.640439,39.121514 v -2.286573 l -0.523454,0.762191"
         id="path15026" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 10.640439,36.834941 0.523457,0.762191"
         id="path15028" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 15.874999,39.121514 v -2.286573 l -0.523456,0.762191"
         id="path15030" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 15.874999,36.834941 0.523458,0.762191"
         id="path15032" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 21.109559,39.121514 10e-7,-2.286573 -0.523456,0.762191"
         id="path15034" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 21.10956,36.834941 0.523455,0.762191"
         id="path15036" />
    </svg>
  );
};

export default MegaTrailerIcon;
