import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import ShippingOrderCreateViewModel from 'App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrderCreateViewModel';
import { PaginatedListResult } from 'store/base';
import ShippingOrdersRequestViewModel from 'App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrdersRequestViewModel';
import ShippingOrdersResponseViewModel from 'App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrdersResponseViewModel';
import {
  ShippingOrderResponseViewModel,
  ShippingOrderShipmentResponseViewModel,
  ShippingOrderShipmentUpdateViewModel,
} from 'App/routes/Tenant/ShippingOrder/ViewModels';
import ShippingOrderUpdateViewModel from 'App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrderUpdateViewModel';
import { ShipmentStatusUpdateRequest } from '../viewModels/shipment/ShipmentStatusUpdateRequest';
import ShippingOrderPublicViewModel from 'viewModels/shippingOrder/ShippingOrderPublicViewModel';
import ShippingOrderShipmentStatusRequestViewModel from 'App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrderShipmentStatusRequestViewModel';
import ShippingOrderRouteAddressViewModel from 'App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrderRouteAddressViewModel';
import ShippingOrderRouteAddressUpdateViewModel from 'App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrderRouteAddressUpdateViewModel';
import ShippingOrderRouteAddressAddViewModel from '../App/routes/Tenant/ShippingOrder/ViewModels/ShippingOrderRouteAddressAddViewModel';
import CommonUtilities from '../helpers/CommonUtilities';
import AddressBookItemContactListResponseViewModel from '../App/routes/Tenant/AddressBook/ViewModels/AddressBookItemContactListResponseViewModel';
import { CmrRequestViewModel } from '../shared/types/CmrRequestViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/shipping-order`;
}

export class ShippingOrderApi {
  public static getList(
    params: ShippingOrdersRequestViewModel,
  ): Promise<PaginatedListResult<ShippingOrdersResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<ShippingOrdersResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<ShippingOrderResponseViewModel | null> {
    return ApiUtilities.handleGet<ShippingOrderResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static create(formValues: ShippingOrderCreateViewModel): Promise<string> {
    return ApiUtilities.handlePost<string>(getRoot(), formValues);
  }

  public static update(id: string, formValues: ShippingOrderUpdateViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static shipments(id: string): Promise<ShippingOrderShipmentResponseViewModel[]> {
    return ApiUtilities.handleGet<ShippingOrderShipmentResponseViewModel[]>(
      `${getRoot()}/${id}/shipments`,
    );
  }

  public static shipmentShippingOrders(
    shipmentId: string,
  ): Promise<ShippingOrderShipmentResponseViewModel[]> {
    return ApiUtilities.handleGet<ShippingOrderShipmentResponseViewModel[]>(
      `${getRoot()}/shipment-orders/${shipmentId}`,
    );
  }
  public static importShipments(
    id: string,
    formValues: ShippingOrderCreateViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/import-shipment`, formValues);
  }

  public static removeShipments(
    id: string,
    formValues: ShippingOrderCreateViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/remove-shipment`, formValues);
  }

  public static updateShipments(
    id: string,
    formValues: ShippingOrderShipmentUpdateViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/update-shipment`, formValues);
  }

  public static updateStatus(
    id: string,
    formValues: ShipmentStatusUpdateRequest,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/update-status`, formValues);
  }

  public static updateShipmentsStatus(
    id: string,
    formValues: ShippingOrderShipmentStatusRequestViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(
      `${getRoot()}/${id}/update-shipments-status`,
      formValues,
    );
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static getShipmentsList(
    params: ShippingOrdersRequestViewModel,
  ): Promise<PaginatedListResult<ShippingOrderShipmentResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<ShippingOrderShipmentResponseViewModel>>(
      `${getRoot()}/search-shipments`,
      {
        ...params,
      },
    );
  }

  public static shippingOrderRoutes(
    shippingOrderId: string,
  ): Promise<ShippingOrderRouteAddressViewModel[]> {
    return ApiUtilities.handleGet<ShippingOrderRouteAddressViewModel[]>(
      `${getRoot()}/${shippingOrderId}/routes/`,
    );
  }

  public static updateShippingOrderRoutes(
    shippingOrderId: string,
    formValues: ShippingOrderRouteAddressUpdateViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${shippingOrderId}/routes`, formValues);
  }

  public static addNewShippingOrderRouteAddress(
    shippingOrderId: string,
    formValues: ShippingOrderRouteAddressAddViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${shippingOrderId}/route`, formValues);
  }

  public static deleteRouteAddress(shippingOrderId: string, routeId: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${shippingOrderId}/route/${routeId}`);
  }

  public static async downloadRoutePdfFile(id: string, number: string) {
    const URL = `${getRoot()}/${id}/route-print`;
    const file = await ApiUtilities.handleFileGet(URL);
    const filename = 'route_' + number + '.pdf';
    CommonUtilities.downloadFile(file, false, filename);
  }

  public static async downloadOrderPdfFile(id: string, number: string) {
    const URL = `${getRoot()}/${id}/order-print`;
    const file = await ApiUtilities.handleFileGet(URL);
    const filename = 'shippingOrder_' + number + '.pdf';
    CommonUtilities.downloadFile(file, false, filename);
  }

  public static sendOrderEmailToPartner(
    shippingOrderId: string,
    formValues: AddressBookItemContactListResponseViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(
      `${getRoot()}/${shippingOrderId}/send-email-partner`,
      formValues,
    );
  }

  public static async downloadWaybill(data: CmrRequestViewModel, waybillNumber: string) {
    const URL = `${getRoot()}/waybill`;
    const file = await ApiUtilities.handleFileGetPost(URL, data);
    const filename = 'Waybill_' + waybillNumber + '.pdf';
    CommonUtilities.downloadFile(file, false, filename);
  }

  public static getPublicSO(tenantCode: string, token: string): Promise<ShippingOrderPublicViewModel | null> {
    return ApiUtilities.handleGet<ShippingOrderPublicViewModel>(`${getRoot()}/${tenantCode}/${token}/public`);
  }

  public static submitShippingOrderConfirmation(tenantCode: string, token: string): Promise<boolean> {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${tenantCode}/${token}/public`, null);
  }

}