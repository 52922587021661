import { routePaths } from './store/api.constants';

export default {
  items: [
    {
      id: 'support',
      title: '',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'dashboard',
          title: 'sidebar-menu-Dashboard',
          type: 'item',
          url: routePaths.dashboard1,
          classes: 'nav-item',
          icon: 'feather icon-home',
        },
        {
          id: 'inbox',
          title: 'sidebar-menu-Inbox',
          type: 'item',
          url: routePaths.inbox.list,
          classes: 'nav-item',
          icon: 'feather icon-mail',
          badge: {
            type: ' badge-light pull-right sidebar-badge sidebar-badge-inbox',
            title: '0',
          },
        },
        {
          id: 'price-requests',
          title: 'sidebar-menu-Price requests',
          type: 'item',
          url: routePaths.priceRequests.list,
          classes: 'nav-item',
          icon: 'feather icon-layers',
          badge: {
            type: ' badge-light pull-right sidebar-badge sidebar-badge-inbox',
            title: '0',
          },
        },
        {
          id: 'shipments',
          title: 'sidebar-menu-Shipments',
          type: 'item',
          url: routePaths.shipments.list,
          classes: 'nav-item',
          icon: 'fa fa-boxes',
        },
        {
          id: 'shipping-orders',
          title: 'sidebar-menu-Shipping orders',
          type: 'item',
          url: routePaths.shippingOrders.list,
          classes: 'nav-item',
          icon: 'fa fas fa-truck-loading',
        },
        {
          id: 'comments',
          title: 'sidebar-menu-Comments',
          type: 'item',
          url: routePaths.comments.list,
          classes: 'nav-item',
          icon: 'fa fas fa-comment',
        },
        {
          id: 'projects',
          title: 'sidebar-menu-Projects',
          type: 'item',
          url: routePaths.projects.list,
          classes: 'nav-item',
          icon: 'fa fas fa-briefcase',
        },
        {
          id: 'addressBook',
          title: 'sidebar-menu-Address book',
          type: 'item',
          url: routePaths.addressBook.list,
          classes: 'nav-item',
          icon: 'fa fas fa-address-card',
        },
        {
          id: 'companies',
          title: 'sidebar-menu-Companies / clients',
          type: 'item',
          url: routePaths.clients.list,
          classes: 'nav-item',
          icon: 'fa fas fa-building',
        },

        {
          id: 'terminals',
          title: 'sidebar-menu-Terminal / warehouses',
          type: 'item',
          url: routePaths.terminals.list,
          classes: 'nav-item',
          icon: 'fa fas fa-warehouse',
        },
        {
          id: 'fleet',
          title: 'sidebar-menu-Fleet / transport units',
          type: 'item',
          url: routePaths.fleet.list,
          classes: 'nav-item',
          icon: 'fa fas fa-truck',
        },
        {
          id: 'partners',
          title: 'sidebar-menu-Partners',
          type: 'item',
          url: routePaths.partners.list,
          classes: 'nav-item',
          icon: 'fa fas fa-hands-helping',
        },
        {
          id: 'invoices',
          title: 'sidebar-menu-Invoices',
          type: 'item',
          url: routePaths.invoices.list,
          classes: 'nav-item',
          icon: 'fa fas fa-money-bill-alt',
        },
        {
          id: 'controlTower',
          title: 'sidebar-menu-Control tower',
          type: 'item',
          url: routePaths.controlTower,
          classes: 'nav-item',
          icon: 'fa fa-map-signs',
        },
        /*
        {
          id: 'price-lists',
          title: LocaleUtilities.i18n('sidebar-menu-Price lists', 'sidebar', true),
          type: 'item',
          url: routePaths.priceLists.list,
          classes: 'nav-item',
          icon: 'feather icon-file-text',
        },
        {
          id: 'map',
          title: LocaleUtilities.i18n('sidebar-menu-Map', 'sidebar', true),
          type: 'item',
          url: routePaths.map.list,
          classes: 'nav-item',
          icon: 'feather icon-map',
        },
        {
          id: 'reports',
          title: LocaleUtilities.i18n('sidebar-menu-Reports', 'sidebar', true),
          type: 'item',
          url: routePaths.reports.list,
          classes: 'nav-item',
          icon: 'feather icon-pie-chart',
        },*/
      ],
    },
  ],
};
