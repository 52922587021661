import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import { PriceRequestApi } from 'api/PriceRequest.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { PriceRequestDetailResponseViewModel } from '../../ViewModels';

const actionCreator = actionCreatorFactory();
const type = 'PRICE_REQUEST/EDIT/START';
export const asyncActions = actionCreator.async<{}, { priceRequestId: string }, ErrorWrapper>(type);

export default function start(priceRequestId: string) {
  return async (dispatch: Dispatch<any>) => {
    let priceRequestInfo: PriceRequestDetailResponseViewModel =
      new PriceRequestDetailResponseViewModel();

    async function mainAction() {
      dispatch(asyncActions.started({}));
      const priceRequest = await PriceRequestApi.getSingle(priceRequestId);
      if (!priceRequest) {
        throw new NotFoundErrorWrapper(`Item with Id ${priceRequestId} not found`);
      }
      priceRequestInfo = priceRequest;
      dispatch(asyncActions.done({ params: {}, result: { priceRequestId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return priceRequestInfo;
  };
}
