import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const InLoaderTrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="8 86 15 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200399" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-98-4"
         ry="1.0894128"
         rx="1.0905997"
         cy="92.737526"
         cx="17.064188" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200399" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-4"
         ry="1.0894128"
         rx="1.0905998"
         cy="92.737526"
         cx="19.710529" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200399" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-01"
         ry="1.0894114"
         rx="1.0906"
         cy="92.737526"
         cx="22.356873" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19997" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="m 23.203344,93.297932 h 1.832088 V 91.381235 H 6.7145682 v 0.718761 H 11.16392 v 1.197936 h 4.972807"
         id="path13905-0-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199929" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 17.991667,93.315607 h 0.79375"
         id="path13907-7-19" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199929" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 20.6375,93.315607 h 0.79375"
         id="path13909-75-57" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19985" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3-1"
         cx="17.064188"
         cy="92.737526"
         rx="0.56160623"
         ry="0.56099391" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19985" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-6"
         cx="19.710529"
         cy="92.737526"
         rx="0.56160623"
         ry="0.56099391" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19985" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-77"
         cx="22.356873"
         cy="92.737526"
         rx="0.56160623"
         ry="0.56099391" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199781" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 25.035526,90.916776 V 90.058224 H 6.7144741 v 0.858552 z"
         id="path43338" />
    </svg>
  );
};

export default InLoaderTrailerIcon;
