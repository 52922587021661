import { ShipmentResponseViewModel } from './index';

export default class ShipmentDetailResponseViewModel extends ShipmentResponseViewModel {
  number?: string;
  customer: string;
  statusString: string;
  salesPrice: number;
  purchasePrice: number;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  invoiceId: string;
  invoiceNumber: string;
  projectName: string;
  priceRequestId: string;
  priceRequestNumber: string;
}
