import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorWrapper } from 'store/base';
import CommonUtils from 'helpers/CommonUtilities';
import AuthUtils, { NullableLocalStorageUserModel } from 'helpers/AuthUtils/AuthUtils';
import * as actionTypes from 'store/actions';
import LocaleUtilities from 'helpers/LocaleUtilities';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import TranslationApi from 'api/Translations.api';
import { store } from 'index';
import { routePaths } from 'store/api.constants';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<
  {},
  { user: NullableLocalStorageUserModel },
  ErrorWrapper
>('APP/START');

export default function submit() {
  return (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      // @ts-ignore
      const location = window.location;
      const translations =
        location.pathname !== routePaths.errors.offline ? await TranslationApi.getAll() : [];
      configureApiInterceptors();
      let user: NullableLocalStorageUserModel | null;
      user = new AuthUtils().getCurrentUser();
      AuthUtils.setThemeSettings();
      const menuIsCollapsed = AuthUtils.getThemeSettingValue('collapseRightMenu');
      if (menuIsCollapsed) dispatch({ type: actionTypes.COLLAPSE_MENU });

      try {
        const currentLanguage = LocaleUtilities.getCurrentLanguage();

        if (store) {
          // @ts-ignore
          syncTranslationWithStore(store);
        }

        dispatch(loadTranslations(translations));
        dispatch(setLocale(currentLanguage));
      } catch (e) {
        // console.log(e);
      }

      dispatch(asyncActions.done({ params: {}, result: { user } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      CommonUtils.scrollToTop();
    }

    CommonUtils.tryCatchWrapper(mainAction, catchAction);
  };
}

/**
 * Configurations when starting the application.
 */
function configureApiInterceptors() {
  const requestCallbacks = {
    onFulfilled: function onFulfilled(config: AxiosRequestConfig) {
      config.validateStatus = (status: number) => {
        return status < 400 || status === 422;
      };
      return config;
    },
    onRejected: function onRejected(error: AxiosError) {
      return Promise.reject(error);
    },
  };

  const responseCallbacks = {
    onFulfilled: function onFulfilled(response: AxiosResponse) {
      return response;
    },
    onRejected: function onRejected(error: AxiosError) {
      return Promise.reject(error);
    },
  };

  axios.interceptors.request.use(requestCallbacks.onFulfilled, requestCallbacks.onRejected);
  axios.interceptors.response.use(responseCallbacks.onFulfilled, responseCallbacks.onRejected);
}
