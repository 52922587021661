import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import { FleetApi } from 'api/Fleet.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { FleetResponseViewModel } from '../../../ViewModels';

const actionCreator = actionCreatorFactory();
const type = 'FLEET/EDIT/START';
export const asyncActions = actionCreator.async<{}, { fleetId: string }, ErrorWrapper>(type);

export default function start(fleetId: string) {
  return async (dispatch: Dispatch<any>) => {
    var fleetInfo: any = new FleetResponseViewModel();

    async function mainAction() {
      dispatch(asyncActions.started({}));
      const fleet = await FleetApi.getSingle(fleetId);
      if (!fleet) {
        throw new NotFoundErrorWrapper(`Item with Id ${fleetId} not found`);
      }
      fleetInfo = fleet;
      dispatch(asyncActions.done({ params: {}, result: { fleetId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return fleetInfo;
  };
}
