import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const SideLoaderTrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="8 74 15 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200051" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-98-3-3"
         ry="1.0911158"
         rx="1.0902356"
         cy="81.623505"
         cx="17.066654" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200051" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-0-6"
         ry="1.0911158"
         rx="1.0902357"
         cy="81.623505"
         cx="19.712114" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200051" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-2-2"
         ry="1.0911144"
         rx="1.0902359"
         cy="81.623505"
         cx="22.357573" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199886" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="m 23.465105,81.391724 h 1.570368 v -1.12303 H 6.7145265 v 1.12303 h 9.1604725"
         id="path13905-0-69-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 18.25625,81.409375 h 0.264583"
         id="path13907-7-4-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 20.902083,81.409622 h 0.264584"
         id="path13909-75-5-4" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200051" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3-29-3"
         cx="17.066654"
         cy="81.623505"
         rx="0.56141871"
         ry="0.56187087" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200051" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-30-1"
         cx="19.712114"
         cy="81.623505"
         rx="0.56141871"
         ry="0.56187087" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200051" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-8-3"
         cx="22.357573"
         cy="81.623505"
         rx="0.56141871"
         ry="0.56187087" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 8.567615,79.805034 V 79.296735 H 8.8333383 V 75.484491 H 8.567615 v -0.508299 h 14.614752 v 0.508299 h -0.265723 l 10e-7,3.812244 h 0.265722 v 0.508299 z"
         id="path51108-1" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 9.8962302,75.484492 v 3.812244"
         id="path51110-5" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 11.224842,75.484492 v 3.812244"
         id="path51112-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 12.553457,75.484492 v 3.812244"
         id="path51114-1" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 13.882072,75.484492 v 3.812244"
         id="path51116-3" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 15.210683,75.484492 v 3.812244"
         id="path51118-1" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 16.539299,75.484492 v 3.812244"
         id="path51120-5" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 17.867913,75.484492 v 3.812244"
         id="path51122-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 19.196526,75.484492 v 3.812244"
         id="path51124-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 20.52514,75.484492 v 3.812244"
         id="path51126-6" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20007" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 21.853753,75.484492 v 3.812244"
         id="path51128-6" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20146" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 24.431522,76.772235 H 23.978031"
         id="path50255-1-22" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 23.903982,79.812685 h 0.601589 v -4.834923 h -0.601589 z"
         id="path60490-5" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 7.7644551,76.768633 H 7.3167947"
         id="path50255-1-7" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199897" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 7.2436987,79.804217 H 7.8375511 V 74.977032 H 7.2436987 Z"
         id="path60490-6" />
    </svg>
  );
};

export default SideLoaderTrailerIcon;
