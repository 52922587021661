import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryColor } from 'theme';

const AccountingIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 76,
  height = 76,
  fill = primaryColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 75C58.2107 75 75 58.2107 75 37.5C75 16.7893 58.2107 0 37.5 0C16.7893 0 0 16.7893 0 37.5C0 58.2107 16.7893 75 37.5 75Z"
        fill="white"
      />
      <path
        d="M37.5 71.2746C56.1533 71.2746 71.2747 56.1532 71.2747 37.5C71.2747 18.8467 56.1533 3.72528 37.5 3.72528C18.8468 3.72528 3.72534 18.8467 3.72534 37.5C3.72534 56.1532 18.8468 71.2746 37.5 71.2746Z"
        stroke="#0A6CBA"
        stroke-width="0.973686"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.3689 18.5244H52.6306C52.6958 18.5244 52.7603 18.5372 52.8205 18.5622C52.8806 18.5871 52.9353 18.6236 52.9814 18.6697C53.0275 18.7158 53.064 18.7705 53.089 18.8307C53.1139 18.8908 53.1267 18.9554 53.1267 19.0205V55.7314C53.1267 55.7966 53.1139 55.8611 53.089 55.9213C53.064 55.9815 53.0275 56.0362 52.9814 56.0822C52.9353 56.1283 52.8806 56.1649 52.8205 56.1898C52.7603 56.2147 52.6958 56.2275 52.6306 56.2275H22.3689C22.2373 56.2275 22.1111 56.1753 22.0181 56.0822C21.9251 55.9892 21.8728 55.863 21.8728 55.7314V19.0205C21.8728 18.9554 21.8856 18.8908 21.9106 18.8307C21.9355 18.7705 21.972 18.7158 22.0181 18.6697C22.0642 18.6237 22.1189 18.5871 22.179 18.5622C22.2392 18.5372 22.3037 18.5244 22.3689 18.5244Z"
        fill="#0A6CBA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.2031 25.9658H46.4297C46.4948 25.9658 46.5593 25.9786 46.6195 26.0036C46.6797 26.0285 46.7344 26.0651 46.7805 26.1111C46.8266 26.1572 46.8631 26.2119 46.888 26.2721C46.913 26.3323 46.9258 26.3968 46.9258 26.4619V26.876C46.9258 26.9411 46.913 27.0056 46.888 27.0658C46.8631 27.126 46.8266 27.1807 46.7805 27.2268C46.7344 27.2728 46.6797 27.3094 46.6195 27.3343C46.5593 27.3592 46.4948 27.3721 46.4297 27.3721H28.2031C28.138 27.3721 28.0735 27.3592 28.0133 27.3343C27.9531 27.3094 27.8984 27.2728 27.8523 27.2268C27.8063 27.1807 27.7697 27.126 27.7448 27.0658C27.7199 27.0056 27.707 26.9411 27.707 26.876V26.4619C27.707 26.3968 27.7199 26.3323 27.7448 26.2721C27.7697 26.2119 27.8063 26.1572 27.8523 26.1111C27.8984 26.0651 27.9531 26.0285 28.0133 26.0036C28.0735 25.9787 28.138 25.9658 28.2031 25.9658Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1836 37.665H30.9414C31.0066 37.665 31.0711 37.6779 31.1313 37.7028C31.1914 37.7277 31.2461 37.7643 31.2922 37.8103C31.3383 37.8564 31.3748 37.9111 31.3997 37.9713C31.4247 38.0315 31.4375 38.096 31.4375 38.1611V38.5752C31.4375 38.7068 31.3852 38.8329 31.2922 38.926C31.1992 39.019 31.073 39.0713 30.9414 39.0713H28.1836C28.1185 39.0713 28.0539 39.0585 27.9937 39.0335C27.9336 39.0086 27.8789 38.9721 27.8328 38.926C27.7867 38.8799 27.7502 38.8252 27.7253 38.765C27.7003 38.7049 27.6875 38.6403 27.6875 38.5752V38.1611C27.6875 38.096 27.7003 38.0315 27.7253 37.9713C27.7502 37.9111 27.7867 37.8564 27.8328 37.8103C27.8789 37.7643 27.9336 37.7277 27.9937 37.7028C28.0539 37.6779 28.1185 37.665 28.1836 37.665Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.9143 37.665H46.0471C46.1787 37.665 46.3049 37.7173 46.3979 37.8103C46.4909 37.9034 46.5432 38.0296 46.5432 38.1611V38.5752C46.5432 38.7068 46.4909 38.8329 46.3979 38.926C46.3049 39.019 46.1787 39.0713 46.0471 39.0713H33.9143C33.7827 39.0713 33.6565 39.019 33.5635 38.926C33.4705 38.8329 33.4182 38.7068 33.4182 38.5752V38.1611C33.4182 38.096 33.431 38.0315 33.456 37.9713C33.4809 37.9111 33.5174 37.8564 33.5635 37.8103C33.6096 37.7643 33.6643 37.7277 33.7245 37.7028C33.7846 37.6779 33.8492 37.665 33.9143 37.665Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1836 42.1299H30.9414C31.073 42.1299 31.1992 42.1822 31.2922 42.2752C31.3852 42.3683 31.4375 42.4945 31.4375 42.626V43.0401C31.4375 43.1052 31.4247 43.1698 31.3997 43.2299C31.3748 43.2901 31.3383 43.3448 31.2922 43.3909C31.2461 43.437 31.1914 43.4735 31.1313 43.4984C31.0711 43.5234 31.0066 43.5362 30.9414 43.5362H28.1836C28.1185 43.5362 28.0539 43.5234 27.9937 43.4984C27.9336 43.4735 27.8789 43.437 27.8328 43.3909C27.7867 43.3448 27.7502 43.2901 27.7253 43.2299C27.7003 43.1698 27.6875 43.1052 27.6875 43.0401V42.626C27.6875 42.5609 27.7003 42.4964 27.7253 42.4362C27.7502 42.376 27.7867 42.3213 27.8328 42.2752C27.8789 42.2292 27.9336 42.1926 27.9937 42.1677C28.0539 42.1428 28.1185 42.1299 28.1836 42.1299Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1836 46.0986H30.9414C31.073 46.0986 31.1992 46.1509 31.2922 46.2439C31.3852 46.337 31.4375 46.4632 31.4375 46.5947V47.0088C31.4375 47.0739 31.4247 47.1384 31.3997 47.1986C31.3748 47.2588 31.3383 47.3135 31.2922 47.3596C31.2461 47.4056 31.1914 47.4422 31.1313 47.4671C31.0711 47.492 31.0066 47.5049 30.9414 47.5049H28.1836C28.1185 47.5049 28.0539 47.4921 27.9937 47.4671C27.9336 47.4422 27.8789 47.4056 27.8328 47.3596C27.7867 47.3135 27.7502 47.2588 27.7253 47.1986C27.7003 47.1384 27.6875 47.0739 27.6875 47.0088V46.5947C27.6875 46.5296 27.7003 46.4651 27.7253 46.4049C27.7502 46.3447 27.7867 46.29 27.8328 46.2439C27.8789 46.1979 27.9336 46.1613 27.9937 46.1364C28.0539 46.1115 28.1185 46.0986 28.1836 46.0986Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.9143 42.1299H46.0471C46.1787 42.1299 46.3049 42.1822 46.3979 42.2752C46.4909 42.3683 46.5432 42.4945 46.5432 42.626V43.0401C46.5432 43.1717 46.4909 43.2979 46.3979 43.3909C46.3049 43.4839 46.1787 43.5362 46.0471 43.5362H33.9143C33.8492 43.5362 33.7846 43.5234 33.7245 43.4984C33.6643 43.4735 33.6096 43.437 33.5635 43.3909C33.5174 43.3448 33.4809 43.2901 33.456 43.2299C33.431 43.1698 33.4182 43.1052 33.4182 43.0401V42.626C33.4182 42.4945 33.4705 42.3683 33.5635 42.2752C33.6565 42.1822 33.7827 42.1299 33.9143 42.1299Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.9143 46.0986H46.0471C46.1787 46.0986 46.3049 46.1509 46.3979 46.2439C46.4909 46.337 46.5432 46.4632 46.5432 46.5947V47.0088C46.5432 47.1404 46.4909 47.2665 46.3979 47.3596C46.3049 47.4526 46.1787 47.5049 46.0471 47.5049H33.9143C33.8492 47.5049 33.7846 47.492 33.7245 47.4671C33.6643 47.4422 33.6096 47.4056 33.5635 47.3596C33.5174 47.3135 33.4809 47.2588 33.456 47.1986C33.431 47.1384 33.4182 47.0739 33.4182 47.0088V46.5947C33.4182 46.4632 33.4705 46.337 33.5635 46.2439C33.6565 46.1509 33.7827 46.0986 33.9143 46.0986Z"
        fill="white"
      />
    </svg>
  );
};

export default AccountingIcon;
