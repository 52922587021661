import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryColor } from 'theme';

const EllipsisIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 76,
  height = 76,
  fill = primaryColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 75C58.2107 75 75 58.2107 75 37.5C75 16.7893 58.2107 0 37.5 0C16.7893 0 0 16.7893 0 37.5C0 58.2107 16.7893 75 37.5 75Z"
        fill="white"
      />
      <path
        d="M37.4998 71.2747C56.153 71.2747 71.2745 56.1532 71.2745 37.5C71.2745 18.8468 56.153 3.72534 37.4998 3.72534C18.8465 3.72534 3.7251 18.8468 3.7251 37.5C3.7251 56.1532 18.8465 71.2747 37.4998 71.2747Z"
        stroke="#0A6CBA"
        stroke-width="0.960805"
      />
      <path
        d="M37.6148 42.4176C40.2384 42.4176 42.3652 40.2908 42.3652 37.6672C42.3652 35.0437 40.2384 32.9169 37.6148 32.9169C34.9913 32.9169 32.8645 35.0437 32.8645 37.6672C32.8645 40.2908 34.9913 42.4176 37.6148 42.4176Z"
        fill="#0A6CBA"
        fill-opacity="0.968627"
      />
      <path
        d="M52.1944 42.4176C54.818 42.4176 56.9448 40.2908 56.9448 37.6672C56.9448 35.0437 54.818 32.9169 52.1944 32.9169C49.5709 32.9169 47.4441 35.0437 47.4441 37.6672C47.4441 40.2908 49.5709 42.4176 52.1944 42.4176Z"
        fill="#0A6CBA"
        fill-opacity="0.968627"
      />
      <path
        d="M23.035 42.4176C25.6586 42.4176 27.7854 40.2908 27.7854 37.6672C27.7854 35.0437 25.6586 32.9169 23.035 32.9169C20.4115 32.9169 18.2847 35.0437 18.2847 37.6672C18.2847 40.2908 20.4115 42.4176 23.035 42.4176Z"
        fill="#0A6CBA"
        fill-opacity="0.968627"
      />
    </svg>
  );
};

export default EllipsisIcon;
