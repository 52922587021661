import { Action } from 'redux';

export const REDUCER_NAME__CONTROL_TOWER = 'controlTower';

export interface ControlTowerReduxState {
  type?: string;
  loading: boolean;
}

const defaultState: ControlTowerReduxState = {
  loading: false,
};
export default (
  state: ControlTowerReduxState = defaultState,
  action: Action,
): ControlTowerReduxState => {
  return state;
};
