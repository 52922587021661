import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { Dispatch } from 'redux';
import { InvitationApi } from 'api/Invitation.api';
import { InvitationRequestViewModel } from 'viewModels/common/InvitationRequestViewModel';

const actionCreator = actionCreatorFactory();

export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('APP/INVITATION/START');

export default function startInvitation(token: string, email: string) {
  let result: InvitationRequestViewModel = new InvitationRequestViewModel();

  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      email = encodeURI(email);
      const data = await InvitationApi.getActiveInvitation(token, email);

      if (!data) {
        throw new NotFoundErrorWrapper(`Item not found`);
      }
      result = data;

      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return result;
  };
}
