import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { PublicInvoiceViewModel } from 'viewModels/public/PublicInvoiceViewModel';
import CommonUtilities from 'helpers/CommonUtilities';

export function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/v1`;
}

export class PublicApi {
  public static getInvoiceInfo(
    tenantCode: string,
    invoiceId: string,
  ): Promise<PublicInvoiceViewModel> {
    return ApiUtilities.handleGet<PublicInvoiceViewModel>(
      `${getRoot()}/invoice/${tenantCode}/${invoiceId}`,
    );
  }

  public static async downloadFile(
    tenantCode: string,
    objectId: string,
    fileId: string,
    entityName: 'pod',
    fileName?: string,
  ) {
    const URL = `${getRoot()}/download/${tenantCode}/${objectId}/${fileId}/${entityName}`;
    const file = await ApiUtilities.handleFileGet(URL);
    CommonUtilities.downloadFile(file, true, fileName);
  }
}
