import { AddressViewModel } from 'viewModels/common/AddressViewModel';
import { Moment } from 'moment';

export default class ShipmentResponseViewModel {
  type: number;
  saveAsDraft: boolean;
  collectionTailLiftTruck: boolean;
  deliveryTailLiftTruck: boolean;
  customerId: string | null;
  operatorTag: string | null;
  operatorId: string | null;
  projectId: string | null;
  reference: string | null;
  loadingReference: string | null;
  deliveryReference: string | null;
  incoterm: string | null;
  incotermPlace: string | null;
  consignor: AddressViewModel;
  consignee: AddressViewModel;
  collection: AddressViewModel;
  delivery: AddressViewModel;
  fixedPickUpDate: boolean = false;
  fixedDeliveryDate: boolean = false;
  pickUpDate?: Date | Moment;
  pickUpTime: string | null;
  pickUpTimeTo: string | null;
  latestPickUpDate?: Date | Moment;
  latestPickUpTime: string | null;
  latestPickUpTimeTo: string | null;
  deliveryDate?: Date | Moment;
  deliveryTime: string | null;
  deliveryTimeTo: string | null;
  latestDeliveryDate?: Date | Moment;
  latestDeliveryTime: string | null;
  latestDeliveryTimeTo: string | null;
  useAlternativeLoadingAddress: boolean;
  useAlternativeDeliveryAddress: boolean;
  packages: ShipmentPackageViewModel[];
  status: number;
  ldmCorrection: number | null;
  estimatePickup?: Date | Moment;
  estimateDelivery?: Date | Moment;
  agreedPrice?: number | null;
  hasShippingOrders?: boolean;
  additionalInformation: string | null;
  documentsVerified?: boolean;
  documentsVerifiedAt?: Date | Moment;
  documentsVerifiedBy: string | null;
}

export class ShipmentPackageViewModel {
  id: number;
  unitId: string;
  quantity: number | string | null = '';
  length: number | string | null = '';
  width: number | string | null = '';
  height: number | string | null = '';
  weight: number | string | null = '';
  totalWeight: number | string | null = '';
  volume: number | string | null = '';
  ldm: number | string | null = '';
  description: string | null = '';
  reference: string | null = '';
  dangerousGoods: boolean = false;
  coldFrozen: boolean = false;
  fragile: boolean = false;
  stackable: boolean = true;
  temperature: string | null = '';
  temperatureMax: string | null = '';
  value: number | null;
  delicateGoods: boolean = false;
  classVal: string | null = '';
  un: string | null = '';
  properShippingName: string | null = '';
  pg: string | null = '';
}
export class ShipmentPackagesTotalViewModel {
  quantity: number;
  weight: number;
  ldm: number;
  volume: number;
}
