import { BACKEND_HOST_AUTH } from '../store/api.constants';
import { PaginatedListResult } from '../store/base';
import { UserCreateOrUpdateRequestViewModel, UsersResponseViewModel } from '../viewModels/user';
import UsersRequestViewModel from '../viewModels/user/UsersRequestViewModel';
import ApiUtilities from '../helpers/ApiUtils';
import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';
import UserAccountViewModel from '../viewModels/user/UserAccountViewModel';

function getRoot() {
  return `${BACKEND_HOST_AUTH()}api/users`;
}

export class UserApi {
  public static getList(
    params: UsersRequestViewModel,
  ): Promise<PaginatedListResult<UsersResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<UsersResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<UserCreateOrUpdateRequestViewModel | null> {
    return ApiUtilities.handleGet<UserCreateOrUpdateRequestViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: UserCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static update(id: string, formValues: UserCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static remove(id: number, tenantId?: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}/${tenantId}`);
  }

  public static getSelectComponentList(params: {}): Promise<Array<SelectComponentValue>> {
    const URL = `${getRoot()}/select-component-values`;
    return ApiUtilities.handleGet<Array<SelectComponentValue>>(URL, { ...params });
  }

  public static findUserNameExists(username: string) {
    return ApiUtilities.handleGet<UserAccountViewModel>(`${getRoot()}/username-exists`, {
      username: username,
    });
  }

  public static addUserForTenant(tenantId: number, userId: string) {
    return ApiUtilities.handlePost<UserAccountViewModel>(
      `${getRoot()}/add-existing-user-to-tenant/${tenantId}/${userId}`,
      {},
    );
  }
}
