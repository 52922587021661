import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__PROJECT = 'project';

export interface ProjectDisplayReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
  projectId?: string;
}

const defaultState: ProjectDisplayReduxState = {
  loading: false,
};

export default (
  state: ProjectDisplayReduxState = defaultState,
  action: Action,
): ProjectDisplayReduxState => {
  return state;
};
