import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const BoxSemitrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="10 17 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200526" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-98-8"
         ry="1.0911411"
         rx="1.0902475"
         cy="23.94404"
         cx="17.066671" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200526" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-2"
         ry="1.0911411"
         rx="1.0902476"
         cy="23.94404"
         cx="19.712158" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200526" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-9"
         ry="1.0911397"
         rx="1.0902479"
         cy="23.94404"
         cx="22.357647" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199934" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="m 23.472171,23.711649 h 1.563309 v -1.12381 H 12.007897 v 1.12381 h 3.908276"
         id="path13905-0-4" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200056" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 18.25714,23.729309 h 0.264549"
         id="path13907-7-0" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200056" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 20.902627,23.729309 h 0.26455"
         id="path13909-75-6" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199976" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3-0"
         cx="17.066671"
         cy="23.94404"
         rx="0.56142485"
         ry="0.56188387" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199976" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-3"
         cx="19.712158"
         cy="23.94404"
         rx="0.56142485"
         ry="0.56188387" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199976" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-1"
         cx="22.357647"
         cy="23.94404"
         rx="0.56142485"
         ry="0.56188387" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 25.03548,22.125063 v -4.82721 H 6.7145202 v 4.82721 z"
         id="path22153" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 19.80092,17.297853 v 4.82721"
         id="path22155" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 11.949081,17.297853 v 4.82721"
         id="path22157" />
    </svg>
  );
};

export default BoxSemitrailerIcon;
