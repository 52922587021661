import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const DumpTrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="30 35 15 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-98"
         ry="1.0902047"
         rx="1.068553"
         cy="41.67173"
         cx="38.127838" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8"
         ry="1.0902047"
         rx="1.0685531"
         cy="41.67173"
         cx="40.720684" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7"
         ry="1.0902033"
         rx="1.0685533"
         cy="41.67173"
         cx="43.31353" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="M 44.405879,41.439537 H 45.93808 V 40.316691 H 33.16973 v 1.122846 h 3.830505"
         id="path13905-0" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 39.294621,41.457182 h 0.259284"
         id="path13907-7" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 41.887466,41.457182 h 0.259285"
         id="path13909-75" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3"
         cx="38.127838"
         cy="41.67173"
         rx="0.55025321"
         ry="0.56140167" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7"
         cx="40.720684"
         cy="41.67173"
         rx="0.55025321"
         ry="0.56140167" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199978" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6"
         cx="43.31353"
         cy="41.67173"
         rx="0.55025321"
         ry="0.56140167" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199806" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 45.937088,39.851377 44.367008,36.32274 H 29.451233 l -0.26168,-0.504093 h -1.308402 l 1.570082,4.03273 z"
         id="path17306" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199806" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 29.843752,37.204897 0.785041,1.764319"
         id="path46709" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199806" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 33.245597,37.204897 0.78504,1.764319"
         id="path46711" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199806" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 37.432481,38.969216 36.647439,37.204897"
         id="path46713" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199806" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 40.834322,38.969216 -0.78504,-1.764319"
         id="path46717" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199806" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 44.236166,38.969216 -0.78504,-1.764319"
         id="path46719" />
    </svg>
  );
};

export default DumpTrailerIcon;
