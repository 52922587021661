import { PriceRequestResponseViewModel } from 'App/routes/Tenant/PriceRequest/ViewModels';
import {
  PriceRequestPackagesTotalViewModel,
  PriceRequestPackageViewModel,
} from 'App/routes/Tenant/PriceRequest/ViewModels/PriceRequestResponseViewModel';
import { NumberUtilities } from '../NumberUtilities';
import { PriceRequestRejectReason, PriceRequestStatus, ShipmentType } from 'store/enums';
import * as React from 'react';
import LocaleUtilities from '../LocaleUtilities';
import StatusProcessingIcon from 'components/Icons/Shipment/StatusProcessingIcon';
import StatusDraftIcon from 'components/Icons/Shipment/StatusDraftIcon';
import StatusDeliveredIcon from 'components/Icons/Shipment/StatusDeliveredIcon';
import StatusCanceledIcon from 'components/Icons/Shipment/StatusCanceledIcon';
import { SelectComponentValue } from 'viewModels/common/SelectComponentValue';
import { priceRequestFilterStatuses } from 'store/constant';
import PriceRequestPricesUpdateRequestViewModel from 'App/routes/Tenant/PriceRequest/ViewModels/PriceRequestPricesUpdateRequestViewModel';
import Badge from 'reactstrap/lib/Badge';

export default class PriceRequestUtilities {
  public static formatFormValuesNumbers(formValues: PriceRequestResponseViewModel): any {
    if (!formValues) {
      return;
    }
    formValues.type =
      NumberUtilities.tryFormatFloat(formValues.type.toString()) ?? ShipmentType.Sender;
    if (formValues.ldmCorrection) {
      formValues.ldmCorrection =
        NumberUtilities.tryFormatFloat(formValues.ldmCorrection?.toString()) ?? null;
    } else {
      formValues.ldmCorrection = null;
    }

    formValues.packages.map((item) => {
      item.totalWeight = NumberUtilities.tryFormatFloat(item.totalWeight?.toString()) ?? null;
      item.volume = NumberUtilities.tryFormatFloat(item.volume?.toString()) ?? null;
      item.value = NumberUtilities.tryFormatFloat(item.value?.toString()) ?? null;
      item.height = NumberUtilities.tryFormatFloat(item.height?.toString()) ?? null;
      item.width = NumberUtilities.tryFormatFloat(item.width?.toString()) ?? null;
      item.length = NumberUtilities.tryFormatFloat(item.length?.toString()) ?? null;
      item.ldm = NumberUtilities.tryFormatFloat(item.ldm?.toString()) ?? null;
      item.quantity = NumberUtilities.tryFormatFloat(item.quantity?.toString()) ?? null;
      item.weight = NumberUtilities.tryFormatFloat(item.weight?.toString()) ?? null;
      return true;
    });
  }

  public static formatPricesValuesNumbers(
    formValues: PriceRequestPricesUpdateRequestViewModel,
  ): any {
    if (!formValues?.offeredPrices) {
      return;
    }
    formValues.offeredPrices.map((item) => {
      item.price = NumberUtilities.tryFormatFloat(item.price?.toString()) ?? 0;
      item.purchasePrice = NumberUtilities.tryFormatFloat(item.purchasePrice?.toString()) ?? 0;
      return true;
    });
  }

  public static calculateTotals(
    packages: PriceRequestPackageViewModel[],
  ): PriceRequestPackagesTotalViewModel {
    let result = new PriceRequestPackagesTotalViewModel();

    result.quantity = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.quantity?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);
    result.volume = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.volume?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);
    result.ldm = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.ldm?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);
    result.weight = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.totalWeight?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);

    return result;
  }

  public static getStatusColor(status: string) {
    switch (status) {
      case PriceRequestStatus[PriceRequestStatus.WaitingConfirmed]:
        return 'info';
      case PriceRequestStatus[PriceRequestStatus.Received]:
        return 'secondary';
      case PriceRequestStatus[PriceRequestStatus.Rejected]:
        return 'danger';
      case PriceRequestStatus[PriceRequestStatus.Accepted]:
        return 'success';
      case PriceRequestStatus[PriceRequestStatus.Canceled]:
        return 'danger';
      case PriceRequestStatus[PriceRequestStatus.Draft]:
        return 'secondary';
      case PriceRequestStatus[PriceRequestStatus.Expired]:
        return 'secondary';
      default:
        return 'secondary';
    }
  }

  public static getRejectReasonTranslation(str: string): string {
    return LocaleUtilities.i18n(
      `priceRequest-rejectReason-${PriceRequestRejectReason[str]}`,
      'priceRequest.status',
      true,
    );
  }

  public static getStatusTranslation(str: string): string {
    return LocaleUtilities.i18n(
      `priceRequest-status-${PriceRequestStatus[str]}`,
      'priceRequest.status',
      true,
    );
  }

  public static getStatusIcon(status: string): JSX.Element {
    switch (status) {
      case PriceRequestStatus[PriceRequestStatus.Received]:
        return <StatusProcessingIcon width={16} height={16} />;
      case PriceRequestStatus[PriceRequestStatus.Accepted]:
        return <StatusDeliveredIcon width={15} height={16} />;
      case PriceRequestStatus[PriceRequestStatus.Canceled]:
        return <StatusCanceledIcon width={16} height={16} />;
      case PriceRequestStatus[PriceRequestStatus.Rejected]:
        return <StatusCanceledIcon width={12} height={16} />;
      case PriceRequestStatus[PriceRequestStatus.Draft]:
        return <StatusDraftIcon width={18} height={16} />;
      case PriceRequestStatus[PriceRequestStatus.WaitingConfirmed]:
        return <StatusProcessingIcon width={16} height={16} />;
      default:
        return <></>;
    }
  }

  public static filterStatuses(): SelectComponentValue[] {
    const result: SelectComponentValue[] = priceRequestFilterStatuses.map((item) => {
      return {
        label: PriceRequestUtilities.getStatusTranslation(PriceRequestStatus[item.toString()]),
        value: item.toString(),
      };
    });
    return result;
  }

  public static rejectReasonOptions(): SelectComponentValue[] {
    const options = [
      PriceRequestRejectReason[PriceRequestRejectReason.TooHighPrice],
      PriceRequestRejectReason[PriceRequestRejectReason.Postpone],
      PriceRequestRejectReason[PriceRequestRejectReason.DeliveryNotNeedAnyMore],
      PriceRequestRejectReason[PriceRequestRejectReason.SomethingElse],
    ];
    const result: SelectComponentValue[] = options.map((item) => {
      return {
        label: PriceRequestUtilities.getRejectReasonTranslation(
          PriceRequestRejectReason[item.toString()],
        ),
        value: item.toString(),
      };
    });
    return result;
  }

  public static hasShipment(value: boolean): JSX.Element {
    return value ? <span className="badge-light-primary ladgend" /> : <></>;
  }

  public static statusFormat(status: string): JSX.Element {
    if (!status) {
      return <div />;
    }
    const icon = PriceRequestUtilities.getStatusIcon(status);
    return (
      <>
        <Badge className={'shipment-status'} color={PriceRequestUtilities.getStatusColor(status)}>
          {LocaleUtilities.i18n(`priceRequest-status-${status}`, 'priceRequest.status', true)}
          {icon}
        </Badge>
      </>
    );
  }
}
