import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export default class FlagAndAddressComponent extends React.Component<{
  flag: string;
  address: string;
  tooltipText?: any;
}> {
  render() {
    const { flag, address } = this.props;
    try {
      return (
        <>
          <img
            alt={flag}
            className={'mr-1 flag-component'}
            src={require(`../../assets/images/flag-icons/svg/${flag.toLowerCase()}.svg`)}
          />
          {this.props.tooltipText !== undefined && this.props.tooltipText.length > 0 ? (
            <Tooltip title={this.props.tooltipText}>
              <span dangerouslySetInnerHTML={{ __html: address }}></span>
            </Tooltip>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: address }}></span>
          )}
        </>
      );
    } catch (error) {
      return <span>{flag}</span>;
    }
  }
}
