import { Moment } from 'moment';

export default class FleetResponseViewModel {
  type: string;
  regNumber: string;
  model: string;
  driverId: string;
  firstRegistrationDate?: Date | Moment;
  nextInspectionDate?: Date | Moment;
  insuranceUntilDate?: Date | Moment;
  height?: number;
  length?: number;
  width?: number;
  weight?: number;
  maxWeight?: number;
  maxLdm?: number;
  maxVolume?: number;
  averageConsumption?: number;
  costPerHour?: number;
  costPerKm?: number;
  adrEquipment: boolean;
  tailLift: boolean;
  doubleDeckLoading: boolean;
  sideOpening: boolean;
  dualTemperature: boolean;
  minTemp: string;
  maxTemp: string;
  isActive: boolean;
}
