import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import AccountApi from 'api/Account.api';
import { ErrorType, ErrorWrapper } from 'store/base';

const actionCreator = actionCreatorFactory();
const type = 'PROFILE/CHANGE_PASSWORD/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(formValues: any, onSuccess: Function) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      await AccountApi.resetPassword(formValues);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'Password has been changed');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      if (exception.type === ErrorType.InternalServerError)
      {
        toastr.error('Error', 'Password has not been changed');
      }
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
