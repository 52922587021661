import { PaginatedSearchBaseOptionsResult } from 'store/base';
import RouterUtilities from 'helpers/RouterUtilities';

export default class FleetsRequestViewModel extends PaginatedSearchBaseOptionsResult {
  keyword: string;
  public parse(location: Location) {
    const obj = RouterUtilities.locationSearchStringParse(location);
    if (!obj) {
      return;
    }

    super.baseParse(obj);
    const keys = Object.keys(new FleetsRequestViewModel());
    for (let i = 0; i < keys.length; i++) {
      try {
        const classKey = keys[i];
        if (classKey in obj && typeof obj[classKey] === 'string') {
          this[classKey] = obj[classKey];
        }
      } catch (e) {}
    }
  }
}
