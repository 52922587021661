import actionCreatorFactory from 'typescript-fsa';

import { ErrorWrapper } from 'store/base';
import { PartnerApi } from 'api/Partner.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import PartnerResponseViewModel from '../../ViewModels/PartnerResponseViewModel';
import { Dispatch } from 'redux';
import PartnerUtilities from 'helpers/ModelUtilities/PartnerUtilities';
import LocaleUtilities from 'helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'PARTNER/CREATE_EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submitCreateAction(
  formValues: PartnerResponseViewModel,
  id: string | undefined,
) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      PartnerUtilities.formatFormValuesNumbers(formValues);
      if (!id) {
        const response = await PartnerApi.add(formValues);
        if (response) id = response;
      } else {
        await PartnerApi.update(id, formValues);
      }
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
    return id;
  };
}
