import * as React from 'react';
import LocaleUtilities from '../../helpers/LocaleUtilities';
import { NavLink } from 'react-router-dom';

interface Component_404Props {}

export default class Component404 extends React.Component<Component_404Props> {
  render() {
    return (
      <div className="error-wrapper">
        <div className="session-inner-wrapper">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-9 mx-auto">
              <div className="error-body text-center">
                <h2 className="oops">Oops.. </h2>
                <h1 className="bold mb-2 mt-2">404</h1>
                <h2 className="error-msg">
                  {LocaleUtilities.i18n('session-error-text-Sorry page not found', 'session.error')}
                </h2>
                <NavLink to={'/'} className="btn btn-primary bg-theme mt-4">
                  <span className="MuiButton-label">
                    {LocaleUtilities.i18n('session-error-button-Go to dashboard', 'session.error')}
                  </span>
                  <span className="MuiTouchRipple-root" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
