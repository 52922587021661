import { Action } from 'redux';
import { ErrorWrapper } from '../../../../../store/base';

export const REDUCER_NAME__FLEETS = 'fleets';

export interface FleetListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: FleetListReduxState = {
  loading: false,
};

export default (state: FleetListReduxState = defaultState, action: Action): FleetListReduxState => {
  return state;
};
