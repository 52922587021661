import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { Dispatch } from 'redux';
import { ShippingOrderApi } from 'api/ShippingOrder.api';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('APP/SHIPPING_ORDER/SUBMIT');


export default function submitShippingOrderConfirmation(tenantCode: string, token: string) {
  let result: boolean = false;

  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      const data = await ShippingOrderApi.submitShippingOrderConfirmation(tenantCode, token);

      if (!data) {
        throw new NotFoundErrorWrapper(`Item not found`);
      }
      result = data;

      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return result;
  };
}