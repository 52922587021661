import { combineReducers, Reducer } from 'redux';
import { default as authReducer, REDUCER_NAME__APP } from '../App/App.reducer';
import { default as appReducer, REDUCER_NAME__SETTINGS } from './app.reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { i18nReducer } from 'react-redux-i18n';

const conf: any = {
  [REDUCER_NAME__SETTINGS]: appReducer,
  [REDUCER_NAME__APP]: authReducer,
  toastr: toastrReducer,
  i18n: i18nReducer,
};

export default function createReducer(asyncReducers: Reducer<any>) {
  return combineReducers({
    ...conf,
    ...asyncReducers,
  });
}
