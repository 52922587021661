import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const VanIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 41,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="43 91 15 9"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-05-5-2-3-2-4"
         ry="1.0902224"
         rx="1.0904409"
         cy="98.028519"
         cx="48.551521" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-07-3-9-9-4-9"
         ry="1.0902208"
         rx="1.0904411"
         cy="98.027557"
         cx="54.636608" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-16-0-1-2-5-8"
         cx="48.551552"
         cy="98.02906"
         rx="0.56152445"
         ry="0.56141078" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-7-5-4-7-2-2"
         cx="54.636387"
         cy="98.028084"
         rx="0.56152445"
         ry="0.56141078" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199872" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 55.751522,97.795899 h 1.298542 V 96.672853 H 46.40202 l 0.01429,1.123046 h 1.024554"
         id="path23925-2-9-4-4-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199872" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 49.778227,97.795899 h 3.635919"
         id="path28474-6-4-7-7-7" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199834" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 57.050082,96.208416 H 46.402 l 0.0014,-1.198834 1.048227,-0.518148 1.547464,-3.110267 h 8.050989 z"
         id="path46972" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199834" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 48.499856,92.418847 h 1.834397 v 2.072587 H 47.45163"
         id="path46974" />
    </svg>
  );
};

export default VanIcon;
