import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__PROJECTS = 'projects';

export interface ProjectListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: ProjectListReduxState = {
  loading: false,
};

export default (
  state: ProjectListReduxState = defaultState,
  action: Action,
): ProjectListReduxState => {
  return state;
};
