import React, { Component } from 'react';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';

import NavLeft from './NavLeft';
import NavRight from './NavRight';
import Aux from '../../../../hoc/_Aux';
import SYSTEM from '../../../../store/constant';
import * as actionTypes from '../../../../store/actions';

import logo from '../../../../assets/images/logo.png';
import { logout } from '../../../actions';
import { AuthUtils } from '../../../../helpers/AuthUtils';
import clsx from 'clsx';
import { routePaths } from '../../../../store/api.constants';
import PlusIcon from '../../../../components/Icons/Plus/PlusIcon';
import LocaleUtilities from '../../../../helpers/LocaleUtilities';

class NavBar extends Component {
  state = {
    rightToggle: false,
    collapseMenu: false,
  };

  render() {
    let headerClass = [
      'navbar',
      'pcoded-header',
      'navbar-expand-lg',
      'header-dark',
      'headerpos-fixed',
    ];

    document.body.classList.remove('background-blue');
    document.body.classList.remove('background-red');
    document.body.classList.remove('background-purple');
    document.body.classList.remove('background-info');
    document.body.classList.remove('background-green');
    document.body.classList.remove('background-dark');

    document.body.classList.remove('background-grd-blue');
    document.body.classList.remove('background-grd-red');
    document.body.classList.remove('background-grd-purple');
    document.body.classList.remove('background-grd-info');
    document.body.classList.remove('background-grd-green');
    document.body.classList.remove('background-grd-dark');

    document.body.classList.remove('background-img-1');
    document.body.classList.remove('background-img-2');
    document.body.classList.remove('background-img-3');
    document.body.classList.remove('background-img-4');
    document.body.classList.remove('background-img-5');
    document.body.classList.remove('background-img-6');
    document.body.classList.add(this.props.headerBackColor);

    if (this.props.headerFixedLayout) {
      headerClass = [...headerClass, AuthUtils.isClientPortal() && 'client-portal-header'];
    }

    let toggleClass = ['mobile-menu'];
    if (this.props.collapseMenu) {
      toggleClass = [...toggleClass, 'on'];
    }

    let mainLogo = logo;

    let navHtml;
    if (!this.state.rightToggle && this.props.windowWidth < 992) {
      navHtml = '';
    } else {
      navHtml = (
        <div className="collapse navbar-collapse d-flex">
          <NavLeft />
          <NavRight rtlLayout={this.props.rtlLayout} logOut={this.props.logout} />
        </div>
      );
    }
    const collapseUrl = '#';
    let navBar = (
      <Aux>
        <div className={clsx('m-header', AuthUtils.isClientPortal() && 'client-portal-header')}>
          <a
            className={toggleClass.join(' ')}
            id="mobile-collapse1"
            href={collapseUrl}
            onClick={this.props.onToggleNavigation}
          >
            <span />
          </a>
          {!AuthUtils.isClientPortal() && (
            <>
              <a
                href={routePaths.shipments.new}
                className="d-none d-md-block d-lg-none btn btn-add-new btn-rounded-border position-absolute font-12 tablet-add-shipment"
              >
                <span className="m-r-15">
                  <PlusIcon fill="#FFFFFF" height={18} width={17} />
                </span>
                <span>{LocaleUtilities.i18n('sidebar-btn-Add new shipment', 'sidebar', true)}</span>
              </a>
              <a href={SYSTEM.BLANK_LINK} className="b-brand">
                <img id="main-logo" src={mainLogo} alt="" className="logo" />{' '}
                <span style={{ fontSize: '18px' }} className="text-white m-l-10 ">
                  {SYSTEM.SITE_NAME}
                </span>
              </a>
            </>
          )}
          <a
            className="mob-toggler"
            href={collapseUrl}
            onClick={() =>
              this.setState((prevState) => {
                return { rightToggle: !prevState.rightToggle };
              })
            }
          >
            <i className="feather icon-more-vertical" />
          </a>
        </div>
        {navHtml}
      </Aux>
    );

    if (this.props.layout === 'horizontal' && this.props.subLayout === 'horizontal-2') {
      navBar = <div className="container">{navBar}</div>;
    }

    return (
      <Aux>
        <header className={headerClass.join(' ')}>{navBar}</header>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rtlLayout: state.settings.rtlLayout,
    headerBackColor: state.settings.headerBackColor,
    headerFixedLayout: state.settings.headerFixedLayout,
    collapseMenu: state.settings.collapseMenu,
    layout: state.settings.layout,
    subLayout: state.settings.subLayout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleNavigation: () => {
      dispatch({ type: actionTypes.COLLAPSE_MENU });
    },
    logout: () => dispatch(logout(null)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(NavBar));
