import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import { TenantApi } from 'api/Tenant.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { TenantResponseViewModel } from '../../../ViewModels';

const actionCreator = actionCreatorFactory();
const type = 'TENANT/EDIT/START';
export const asyncActions = actionCreator.async<{}, { tenantId: number }, ErrorWrapper>(type);

export default function start(tenantId: number) {
  return async (dispatch: Dispatch<any>) => {
    var tenantInfo: any = new TenantResponseViewModel();

    async function mainAction() {
      dispatch(asyncActions.started({}));
      const tenant = await TenantApi.getSingle(tenantId);
      if (!tenant) {
        throw new NotFoundErrorWrapper(`Item with Id ${tenantId} not found`);
      }
      tenantInfo = tenant;
      dispatch(asyncActions.done({ params: {}, result: { tenantId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return tenantInfo;
  };
}
