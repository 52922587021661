export class NumberUtilities {
  public static tryFormatFloat(strVal: string | undefined | null): number | undefined {
    try {
      if (strVal && strVal.length > 0) {
        strVal = strVal.replace(/[,]/, '.');
        return parseFloat(strVal);
      }
    } catch (e) {}
    return undefined;
  }

  public static tryFormatInt(strVal: string | undefined | null): number {
    try {
      if (strVal && strVal.length > 0) {
        strVal = strVal.replace(/[,]/, '.');
        return parseInt(strVal);
      }
    } catch (e) {}
    return 0;
  }

  public static formatCurrencyText(numberVal: number) {
    if (numberVal === null || numberVal === undefined) return '';
    return numberVal.toFixed(2).toString().replace(/[.]/, ',');
  }
}
