import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { PaginatedListResult } from 'store/base';
import {
  TerminalResponseViewModel,
  TerminalsRequestViewModel,
  TerminalsResponseViewModel,
} from '../App/routes/Tenant/Terminal/ViewModels';
import { AddressBookItemContactListResponseViewModel } from '../App/routes/Tenant/AddressBook/ViewModels';
import AddressBookItemContactCreateOrUpdateResponseViewModel from '../viewModels/address/AddressBookItemContactCreateOrUpdateResponseViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/terminal`;
}

export class TerminalApi {
  public static getList(
    params: TerminalsRequestViewModel,
  ): Promise<PaginatedListResult<TerminalsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<TerminalsResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<TerminalResponseViewModel | null> {
    return ApiUtilities.handleGet<TerminalResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: TerminalResponseViewModel): Promise<string> {
    return ApiUtilities.handlePost<string>(getRoot(), formValues);
  }

  public static update(id: string, formValues: TerminalResponseViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static getContactList(id: string): Promise<AddressBookItemContactListResponseViewModel[]> {
    return ApiUtilities.handleGet<AddressBookItemContactListResponseViewModel[]>(
      `${getRoot()}/${id}/contact`,
      {},
    );
  }

  public static addContact(
    id: string,
    formValues: AddressBookItemContactCreateOrUpdateResponseViewModel,
  ): Promise<number> {
    return ApiUtilities.handlePost<number>(`${getRoot()}/${id}/contact`, formValues);
  }

  public static updateContact(
    id: string,
    formValues: AddressBookItemContactCreateOrUpdateResponseViewModel,
  ) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}/contact/`, formValues);
  }

  public static getContact(
    id: string,
    contactId: number,
  ): Promise<AddressBookItemContactCreateOrUpdateResponseViewModel> {
    return ApiUtilities.handleGet<AddressBookItemContactCreateOrUpdateResponseViewModel>(
      `${getRoot()}/${id}/contact/${contactId}`,
      {},
    );
  }

  public static deleteContact(id: string, contactId: number): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/contact/${contactId}/delete`, {});
  }
}
