import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryColor } from 'theme';

const HouseRoundedIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 25,
  height = 25,
  fill = primaryColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100 0C44.8027 0 1.56757e-05 44.8027 0 100C-1.51351e-05 155.197 44.8027 200 100 200C155.197 200 200 155.197 200 100C200 44.8027 155.197 0 100 0ZM100 5.2576C152.353 5.2576 194.732 47.6471 194.732 100C194.732 152.353 152.353 194.732 100 194.732C47.6471 194.732 5.25759 152.353 5.2576 100C5.25762 47.6471 47.6471 5.2576 100 5.2576Z"
        fill={fill}
      />
      <path
        d="M42.7886 148.624V72.8241C42.7886 72.3864 42.8999 71.956 43.1121 71.5732C43.3242 71.1904 43.6303 70.8678 44.0014 70.6359L98.783 36.3974C99.1467 36.1701 99.5669 36.0496 99.9958 36.0496C100.425 36.0496 100.845 36.1701 101.209 36.3974L155.99 70.6359C156.361 70.8678 156.667 71.1904 156.88 71.5732C157.092 71.956 157.203 72.3864 157.203 72.8241V148.624C157.203 149.003 157.052 149.367 156.784 149.635C156.516 149.903 156.152 150.054 155.773 150.054H137.18C136.801 150.054 136.437 149.903 136.169 149.635C135.901 149.367 135.75 149.003 135.75 148.624V79.9749C135.75 79.5956 135.6 79.2318 135.331 78.9636C135.063 78.6954 134.699 78.5448 134.32 78.5448H65.6715C65.2922 78.5448 64.9284 78.6954 64.6602 78.9636C64.392 79.2318 64.2413 79.5956 64.2413 79.9749V148.624C64.2413 149.003 64.0906 149.367 63.8224 149.635C63.5542 149.903 63.1904 150.054 62.8111 150.054H44.2188C43.8395 150.054 43.4757 149.903 43.2075 149.635C42.9393 149.367 42.7886 149.003 42.7886 148.624Z"
        fill={fill}
      />
      <path
        d="M69.9624 82.835C69.7746 82.835 69.5886 82.872 69.4151 82.9438C69.2416 83.0157 69.0839 83.1211 68.9511 83.2539C68.8183 83.3867 68.713 83.5443 68.6411 83.7178C68.5692 83.8913 68.5323 84.0773 68.5323 84.2652V148.623C68.5323 149.003 68.6829 149.366 68.9512 149.635C69.2194 149.903 69.5831 150.053 69.9624 150.053H130.03C130.409 150.053 130.773 149.903 131.041 149.635C131.309 149.366 131.46 149.003 131.46 148.623V84.2652C131.46 84.0773 131.423 83.8913 131.351 83.7178C131.279 83.5443 131.174 83.3867 131.041 83.2539C130.909 83.1211 130.751 83.0157 130.577 82.9438C130.404 82.872 130.218 82.835 130.03 82.835H69.9624ZM88.1246 139.444H111.865C112.775 139.444 113.51 140.179 113.51 141.09V141.855C113.51 142.765 112.775 143.5 111.865 143.5H88.1246C87.2142 143.5 86.4821 142.765 86.4821 141.855V141.09C86.4821 140.179 87.2142 139.444 88.1246 139.444Z"
        fill={fill}
        fillOpacity="0.97"
      />
    </svg>
  );
};

export default HouseRoundedIcon;
