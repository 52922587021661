import * as queryString from 'querystring';
import * as moment from 'moment';

export const MOMENT_FORMAT = 'DD.MM.YYYY';

export const URL_TAB = 'tabId';

export default class RouterUtilities {
  public static objectToUrlString(
    params: Object | null,
    urlPrefix: string | null = null,
  ): string | null {
    let aggregatedParams: string = '';
    if (params) {
      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          const value = params[key];
          if (!value) {
            continue;
          }

          if (moment.isMoment(value)) {
            aggregatedParams = RouterUtilities.updateQueryStringParameter(
              aggregatedParams,
              key,
              value.format(MOMENT_FORMAT),
            );
          } else {
            aggregatedParams = RouterUtilities.updateQueryStringParameter(
              aggregatedParams,
              key,
              value,
            );
          }
        }
      }
    } else {
      return null;
    }

    return urlPrefix ? urlPrefix + aggregatedParams : aggregatedParams;
  }

  public static changeQueryParamsInUrl(obj: Object | null): void {
    const params = RouterUtilities.objectToUrlString(obj);

    // if (!params) {
    //     return;
    // }

    // eslint-disable-next-line no-restricted-globals
    const newUrl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + params;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  public static updateQueryStringParameter(uri: string, key: string, value: string): string {
    const re = new RegExp('([?&])' + key + '=.*?(&|#|$)', 'i');
    if (value === undefined) {
      if (uri.match(re)) {
        return uri.replace(re, '$1$2');
      } else {
        return uri;
      }
    } else {
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
      } else {
        let hash = '';
        if (uri.indexOf('#') !== -1) {
          hash = uri.replace(/.*#/, '#');
          uri = uri.replace(/#.*/, '');
        }
        const separator = uri.indexOf('?') !== -1 ? '&' : '?';
        return uri + separator + key + '=' + value + hash;
      }
    }
  }

  public static locationSearchStringParse(location: Location): any | null {
    let searchString = location.search;
    if (!searchString) {
      return null;
    }

    if (searchString[0] === '?') {
      searchString = searchString.substr(1);
    }

    return queryString.parse(searchString);
  }

  public static getActiveRoute(location: Location): any | null {
    let searchString = location.pathname;
    if (!searchString) {
      return null;
    }

    if (searchString[0] === '/') {
      searchString = searchString.substr(1);
    }
    var paths = searchString.split('/');
    if (paths.length > 1) {
      return '/' + paths[0] + '/' + paths[1];
    }
    return '/' + paths[0];
  }
}
