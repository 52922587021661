import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { Dispatch } from 'redux';
import { PublicApi } from 'api/Public.api';
import { PublicInvoiceViewModel } from 'viewModels/public/PublicInvoiceViewModel';

const actionCreator = actionCreatorFactory();

export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('APP/PUBLIC_INVOICE/START');

export default function fetchPublicInvoiceRequest(tenantCode: string, invoiceId: string) {
  let result: PublicInvoiceViewModel = new PublicInvoiceViewModel();

  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      const data = await PublicApi.getInvoiceInfo(tenantCode, invoiceId);

      if (!data) {
        throw new NotFoundErrorWrapper(`Item not found`);
      }
      result = data;

      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return result;
  };
}
