import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { PaginatedListResult } from 'store/base';
import {
  CustomerContactListResponseViewModel,
  CustomerResponseViewModel,
  CustomersRequestViewModel,
  CustomersResponseViewModel,
} from 'App/routes/Tenant/Customer/ViewModels';
import CustomerContactCreateOrUpdateResponseViewModel from 'App/routes/Tenant/Customer/ViewModels/CustomerContactCreateOrUpdateResponseViewModel';
import EBusinessRegisterRequestViewModel from '../viewModels/customer/EBusinessRegisterRequestViewModel';
import { AddressViewModel } from '../viewModels/common/AddressViewModel';
import { ExcelImportBaseResponse } from '../viewModels/imports/ExcelImportBaseResponse';
import { BasicIdRequestViewModel } from '../viewModels/common/BasicIdRequestViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/customer`;
}

export class CustomerApi {
  public static getList(
    params: CustomersRequestViewModel,
  ): Promise<PaginatedListResult<CustomersResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<CustomersResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<CustomerResponseViewModel | null> {
    return ApiUtilities.handleGet<CustomerResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: CustomerResponseViewModel): Promise<string> {
    return ApiUtilities.handlePost<string>(getRoot(), formValues);
  }

  public static update(id: string, formValues: CustomerResponseViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static getContactList(id: string): Promise<CustomerContactListResponseViewModel[]> {
    return ApiUtilities.handleGet<CustomerContactListResponseViewModel[]>(
      `${getRoot()}/${id}/contact`,
      {},
    );
  }

  public static addContact(
    id: string,
    formValues: CustomerContactCreateOrUpdateResponseViewModel,
  ): Promise<string> {
    return ApiUtilities.handlePost<string>(`${getRoot()}/${id}/contact`, formValues);
  }

  public static updateContact(
    id: string,
    formValues: CustomerContactCreateOrUpdateResponseViewModel,
  ) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}/contact`, formValues);
  }

  public static setContactUserDefaultAddress(
    id: string,
    contactId: number,
    formValues: BasicIdRequestViewModel,
  ) {
    return ApiUtilities.handlePut<boolean>(
      `${getRoot()}/${id}/contact-default-address/${contactId}`,
      formValues,
    );
  }

  public static getContact(
    id: string,
    contactId: number,
  ): Promise<CustomerContactCreateOrUpdateResponseViewModel> {
    return ApiUtilities.handleGet<CustomerContactCreateOrUpdateResponseViewModel>(
      `${getRoot()}/${id}/contact/${contactId}`,
      {},
    );
  }

  public static inviteContact(id: string, contactId: number): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/contact/${contactId}/invite`, {});
  }

  public static deleteContact(id: string, contactId: number): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/contact/${contactId}/delete`, {});
  }

  public static getCompaniesFromBusinessReg(
    formValues: EBusinessRegisterRequestViewModel,
  ): Promise<AddressViewModel[]> {
    return ApiUtilities.handlePost<AddressViewModel[]>(`${getRoot()}/arireg`, formValues);
  }

  public static getEInvoiceServiceProvider(id: string): Promise<string> {
    return ApiUtilities.handleGet<string>(`${getRoot()}/${id}/eInvoiceServiceProvider`, {});
  }

  public static importExcelRows(formData: FormData): Promise<ExcelImportBaseResponse | null> {
    return ApiUtilities.handleFilePost(`${getRoot()}/import-from-excel/`, formData);
  }
}
