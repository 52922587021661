import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const ContainerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 50,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="30 43 15 9"
      xmlns="http://www.w3.org/2000/svg">
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 30.527013,49.90632 v -0.508128 h 0.26095 v -3.810968 h -0.26095 v -0.508128 h 14.352224 v 0.508128 h -0.26095 l 10e-7,3.810968 h 0.260949 v 0.508128 z"
         id="path51108" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 31.831762,45.587225 v 3.810968"
         id="path51110" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 33.136508,45.587225 v 3.810968"
         id="path51112" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 34.441256,45.587225 v 3.810968"
         id="path51114" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 35.746005,45.587225 v 3.810968"
         id="path51116" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 37.05075,45.587225 v 3.810968"
         id="path51118" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 38.3555,45.587225 v 3.810968"
         id="path51120" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 39.660248,45.587225 v 3.810968"
         id="path51122" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 40.964995,45.587225 v 3.810968"
         id="path51124" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 42.269742,45.587225 v 3.810968"
         id="path51126" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19986" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 43.574489,45.587225 v 3.810968"
         id="path51128" />
    </svg>
  );
};

export default ContainerIcon;
