import { Action } from 'redux';

export const REDUCER_NAME__TENANTS = 'tenants';

export interface TenantListReduxState {
  type?: string;
  loading: boolean;
}

const defaultState: TenantListReduxState = {
  loading: false,
};

export default (
  state: TenantListReduxState = defaultState,
  action: Action,
): TenantListReduxState => {
  return state;
};
