import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const TractorUnitIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 50,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="32 91 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-05-5-2-3-2"
         ry="1.0902205"
         rx="1.0904409"
         cy="98.028519"
         cx="35.322353" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-07-3-9-9-4"
         ry="1.090219"
         rx="1.0904411"
         cy="98.027557"
         cx="41.40744" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-16-0-1-2-5"
         cx="35.322384"
         cy="98.02906"
         rx="0.56152439"
         ry="0.56140983" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200014" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-7-5-4-7-2"
         cx="41.407219"
         cy="98.028084"
         rx="0.56152439"
         ry="0.56140983" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199872" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 42.522356,97.795897 h 1.298541 V 96.672853 H 33.172853 l 0.01429,1.123044 h 1.024554"
         id="path23925-2-9-4-4" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199872" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 36.549062,97.795897 H 40.18498"
         id="path28474-6-4-7-7" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199696" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 38.000152,96.208485 -4.827387,-10e-7 v -1.778512 l 0.762219,-2.032587 1.77851,10e-7 2.286658,-1.016293 z"
         id="path46857" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199696" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 33.172765,94.429972 h 2.540729 v -1.52444 H 33.68091"
         id="path46859" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19983" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 40.581165,96.208419 h 1.916893 l -1.916893,-0.593924 z"
         id="path46861" />
    </svg>
  );
};

export default TractorUnitIcon;
