import { createTheme } from '@material-ui/core/styles';

export const white = '#ffffff';
export const primaryColor = '#D16A1F';
export const primaryDarkColor = '#081A33';

export const theme = createTheme({
  palette: {
    custom: {
      white,
      primaryColor,
    },
  },
});
