import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper } from '../../../../../../../store/base';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';
import { UserApi } from '../../../../../../../api/User.api';

const actionCreator = actionCreatorFactory();
const type = 'TENANT/USER/EXISTING/ADD';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function start(tenantId: number, userId: string) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      await UserApi.addUserForTenant(tenantId, userId);
      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
