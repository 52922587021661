import CustomerRequestViewModel from './CustomerRequestViewModel';
import CustomersResponseViewModel from './CustomersResponseViewModel';
import CustomerResponseViewModel from './CustomerResponseViewModel';
import CustomersRequestViewModel from './CustomersRequestViewModel';
import CustomerContactListResponseViewModel from './CustomerContactListResponseViewModel';

export {
  CustomerRequestViewModel,
  CustomersResponseViewModel,
  CustomerResponseViewModel,
  CustomersRequestViewModel,
  CustomerContactListResponseViewModel,
};
