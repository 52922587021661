import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import { TerminalApi } from 'api/Terminal.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { TerminalResponseViewModel } from '../../ViewModels';

const actionCreator = actionCreatorFactory();
const type = 'TERMINAL/EDIT/START';
export const asyncActions = actionCreator.async<{}, { terminalId: string }, ErrorWrapper>(type);

export default function start(terminalId: string) {
  return async (dispatch: Dispatch<any>) => {
    let terminalInfo: any = new TerminalResponseViewModel();

    async function mainAction() {
      dispatch(asyncActions.started({}));
      const terminal = await TerminalApi.getSingle(terminalId);
      if (!terminal) {
        throw new NotFoundErrorWrapper(`Item with Id ${terminalId} not found`);
      }
      terminalInfo = terminal;
      dispatch(asyncActions.done({ params: {}, result: { terminalId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return terminalInfo;
  };
}
