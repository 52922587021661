import { BACKEND_HOST_MAIN } from '../store/api.constants';
import ApiUtilities from '../helpers/ApiUtils';
import { PaginatedListResult } from '../store/base';
import {
  FleetCreateOrUpdateRequestViewModel,
  FleetResponseViewModel,
  FleetsRequestViewModel,
  FleetsResponseViewModel,
} from '../App/routes/Tenant/Fleet/ViewModels';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/fleet`;
}

export class FleetApi {
  public static getList(
    params: FleetsRequestViewModel,
  ): Promise<PaginatedListResult<FleetsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<FleetsResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<FleetResponseViewModel | null> {
    return ApiUtilities.handleGet<FleetResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: FleetCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static update(id: string, formValues: FleetCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }
}
