import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { ErrorWrapper, TableData } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { UserApi } from 'api/User.api';
import UsersRequestViewModel from 'viewModels/user/UsersRequestViewModel';
import UsersResponseViewModel from 'viewModels/user/UsersResponseViewModel';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<
  {},
  { tableData: TableData<UsersResponseViewModel> },
  ErrorWrapper
>('TENANT/GET-USERS');

export default function submit(params: UsersRequestViewModel, onSuccess: Function | undefined) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const paginatedList = await UserApi.getList(params);
      dispatch(
        asyncActions.done({
          params: {},
          result: { tableData: TableData.createTableDataFrom(paginatedList, 1, 35) },
        }),
      );
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
