import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import Aux from 'hoc/_Aux';
import DEMO, { IS_PRODUCTION, languages } from 'store/constant';
import Avatar1 from 'assets/images/user/Avatar-6.png';
import Avatar2 from 'assets/images/user/avatar-2.jpg';
import { AuthUtils, UserModelRoles } from 'helpers/AuthUtils/AuthUtils';

import { routePaths } from 'store/api.constants';
import LocaleUtilities, { ValidLanguage } from 'helpers/LocaleUtilities';
import { Tooltip } from '@material-ui/core';
import FlagComponent from 'components/Flag/FlagComponent';
import { generateKey } from 'shared/functions/generateKey';

export type NavRightProps = {
  rtlLayout: boolean;
  logOut: () => void;
};

class NavRight extends React.Component<NavRightProps> {
  state = {
    listOpen: false,
  };

  switchLanguage = (lang: ValidLanguage) => {
    LocaleUtilities.setCurrentLanguage(lang);
    window.location.reload();
  };

  render() {
    const userData = AuthUtils.currentUserData();
    const currentLang = LocaleUtilities.getCurrentLanguage();
    const flag = languages.filter(({ name }) => name === currentLang);
    return (
      <Aux>
        <ul className="navbar-nav ml-auto">
          <li className="hidden">
            <Dropdown alignRight={!this.props.rtlLayout} drop={'right'} className={'dropdown'}>
              <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                <i className="feather icon-bell icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="notification">
                <div className="noti-head">
                  <h6 className="d-inline-block m-b-0">Notifications</h6>
                  <div className="float-right">
                    <a href={DEMO.BLANK_LINK} className="m-r-10">
                      mark as read
                    </a>
                    <a href={DEMO.BLANK_LINK}>clear all</a>
                  </div>
                </div>
                <div style={{ height: '300px' }}>
                  <PerfectScrollbar>
                    <ul className="noti-body">
                      <li className="n-title">
                        <p className="m-b-0">NEW</p>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src={Avatar1} alt="Generic placeholder" />
                          <div className="media-body">
                            <p>
                              <strong>John Doe</strong>
                              <span className="n-time text-muted">
                                <i className="icon feather icon-clock m-r-10" />5 min
                              </span>
                            </p>
                            <p>New ticket Added</p>
                          </div>
                        </div>
                      </li>
                      <li className="n-title">
                        <p className="m-b-0">EARLIER</p>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src={Avatar2} alt="Generic placeholder" />
                          <div className="media-body">
                            <p>
                              <strong>Joseph William</strong>
                              <span className="n-time text-muted">
                                <i className="icon feather icon-clock m-r-10" />
                                10 min
                              </span>
                            </p>
                            <p>Prchace New Theme and make payment</p>
                          </div>
                        </div>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src={Avatar1} alt="Generic placeholder" />
                          <div className="media-body">
                            <p>
                              <strong>Sara Soudein</strong>
                              <span className="n-time text-muted">
                                <i className="icon feather icon-clock m-r-10" />
                                12 min
                              </span>
                            </p>
                            <p>currently login</p>
                          </div>
                        </div>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src={Avatar2} alt="Generic placeholder" />
                          <div className="media-body">
                            <p>
                              <strong>Joseph William</strong>
                              <span className="n-time text-muted">
                                <i className="icon feather icon-clock m-r-10" />
                                30 min
                              </span>
                            </p>
                            <p>Prchace New Theme and make payment</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </PerfectScrollbar>
                </div>
                <div className="noti-footer">
                  <a href={DEMO.BLANK_LINK}>show all</a>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          {!IS_PRODUCTION && (AuthUtils.isAdmin() || AuthUtils.powerUsers()) ? (
            <li>
              <Tooltip title="Turn on translations modification" placement="bottom">
                <span
                  className={
                    !LocaleUtilities.getDebug()
                      ? 'header-icon waves-effect waves-dark text-dark'
                      : 'header-icon waves-effect waves-dark text-dark active'
                  }
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={(e) => {
                    LocaleUtilities.toggleDebug();
                    window.location.reload();
                  }}
                >
                  <i className="fa fa-key text-white mr-3" />
                </span>
              </Tooltip>
            </li>
          ) : null}
          <li className="mr-3">
            <Dropdown
              alignRight={!this.props.rtlLayout}
              drop={'down'}
              className={'dropdown language-switch'}
            >
              <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                <FlagComponent flag={flag[0].icon} />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="page-language">
                <div className="noti-head text-center">
                  <h6 className="d-inline-block m-b-0 m-t-0 p-l-5 text-white">
                    {LocaleUtilities.i18n(`Choose language`, 'base.language', true)}
                  </h6>
                </div>
                <ul className="pro-body">
                  {languages.map((item, index) => {
                    return (
                      <li
                        key={generateKey(index, 'language')}
                        className="pointer"
                        onClick={() => this.switchLanguage(item.name)}
                      >
                        <FlagComponent flag={item.icon} /> {item.label}
                      </li>
                    );
                  })}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li>
            <Dropdown
              alignRight={!this.props.rtlLayout}
              drop={'right'}
              className="drp-user dropdown"
            >
              <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                <i className="icon feather icon-user" /> {userData?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="profile-notification">
                {AuthUtils.isClientPortal() && userData?.clientName && (
                  <div className="font-bold text-center border-bottom p-3">
                    {userData?.clientName}
                  </div>
                )}
                <ul className="pro-body">
                  {!AuthUtils.isClientPortal() &&
                    AuthUtils.hasValidRoles([UserModelRoles.tenantAdmin]) && (
                      <li>
                        <NavLink to={routePaths.settings} className="dropdown-item">
                          <i className="feather icon-settings" />{' '}
                          {LocaleUtilities.i18n('menu-link-Settings', 'base', true)}
                        </NavLink>
                      </li>
                    )}
                  <li>
                    <NavLink to={routePaths.profile} className="dropdown-item">
                      <i className="feather icon-user" />{' '}
                      {LocaleUtilities.i18n('menu-link-Profile', 'base', true)}
                    </NavLink>
                  </li>
                  <li className="hidden">
                    <a href={DEMO.BLANK_LINK} className="dropdown-item">
                      <i className="feather icon-mail" /> My Messages
                    </a>
                  </li>
                  <li className="hidden">
                    <a href={DEMO.BLANK_LINK} className="dropdown-item">
                      <i className="feather icon-lock" /> Lock Screen
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#"
                      onClick={() => this.props.logOut()}
                      className="dropdown-item pointer"
                    >
                      <i className="feather icon-log-out" />{' '}
                      {LocaleUtilities.i18n('menu-link-Log out', 'base', true)}
                    </a>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </Aux>
    );
  }
}

export default NavRight;
