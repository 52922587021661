import { AddressViewModel } from 'viewModels/common/AddressViewModel';
import { OpeningHourViewModel } from 'viewModels/common/OpeningHourViewModel';

export default class TerminalResponseViewModel {
  code: string;
  loadingGateNumber: string;
  openFrom: string;
  openTo: string;
  address: AddressViewModel;
  openingHours: OpeningHourViewModel[];
}
