import { SpotRequestStatus } from 'store/enums';
import Badge from 'reactstrap/lib/Badge';
import LocaleUtilities from '../LocaleUtilities';
import * as React from 'react';
import SpotRequestPriceOfferViewModel from 'viewModels/spotRequest/SpotRequestPriceOfferViewModel';
import { NumberUtilities } from '../NumberUtilities';

export default class SpotRequestUtilities {
  public static formatOfferFormValuesNumbers(formValues: SpotRequestPriceOfferViewModel) {
    formValues.prices.map((item) => {
      item.price = NumberUtilities.tryFormatFloat(item.price?.toString()) ?? 0;
      return true;
    });
  }

  public static getInvoiceStatusColor(status: string) {
    switch (status) {
      case SpotRequestStatus[SpotRequestStatus.Rejected]:
        return 'danger';
      case SpotRequestStatus[SpotRequestStatus.WaitingPrices]:
        return 'secondary';
      case SpotRequestStatus[SpotRequestStatus.PricesOffered]:
        return 'primary';
      case SpotRequestStatus[SpotRequestStatus.Approved]:
        return 'success';

      default:
        return 'secondary';
    }
  }

  public static statusFormat(status: string): JSX.Element {
    if (!status) {
      return <div />;
    }
    return (
      <>
        <Badge color={SpotRequestUtilities.getInvoiceStatusColor(status)}>
          {SpotRequestUtilities.getStatusTranslation(status)}
        </Badge>
      </>
    );
  }

  public static getStatusTranslation(str: string): string {
    return LocaleUtilities.i18n(`spotRequest-status-${str}`, 'priceRequest.status', true);
  }
}
