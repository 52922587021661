import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as editSubmitActions } from './actions/Partner.submit.action';
import { asyncActions as contactSubmitActions } from './actions/Partner.contact.submit.action';
import { ErrorWrapper } from 'store/base';

export const REDUCER_KEY__PARTNER = 'partner';

export class PartnerState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  errorWrapperContact?: ErrorWrapper;
  partnerId?: string;
  loading: boolean;
}

const defaultState: PartnerState = {
  loading: false,
};
export default (state: PartnerState = defaultState, action: Action): PartnerState => {
  // Edit actions
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }
  // Contact submit actions
  if (isType(action, contactSubmitActions.started)) {
    return { ...state, type: action.type, loading: true, errorWrapperContact: undefined };
  }
  if (isType(action, contactSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, contactSubmitActions.failed)) {
    return {
      ...state,
      type: action.type,
      errorWrapperContact: action.payload.error,
      loading: false,
    };
  }

  return state;
};
