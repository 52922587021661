import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const SideLoaderTruckIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 50,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="30 61 15 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-05-5-6-2"
         ry="1.0901316"
         rx="1.090403"
         cy="68.394356"
         cx="32.676056" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-07-3-2-1"
         ry="1.0901302"
         rx="1.0904032"
         cy="68.394348"
         cx="41.406792" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-16-0-8-8"
         cx="32.676086"
         cy="68.394882"
         rx="0.5615049"
         ry="0.56136405" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-7-5-1-2"
         cx="41.40657"
         cy="68.394875"
         rx="0.5615049"
         ry="0.56136405" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 42.529828,68.162795 h 2.348439 V 67.038868 H 30.526698 v 1.123927 h 1.043749"
         id="path23925-2-8-4" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 33.866698,68.180477 h 6.349446"
         id="path28474-6-44-5" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 34.031281,66.574897 v -3.769685 h -2.753056 l -0.750832,2.261811 v 1.507874 z"
         id="path44993-8-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 31.027948,63.307838 h 2.00222 l -0.03153,1.740191 -2.471252,0.019"
         id="path44997-0-9" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 35.016539,63.539466 H 34.568878"
         id="path50255-1" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 35.55407,66.57511 v -0.508177 h 0.257912 V 62.25561 H 35.55407 v -0.508175 h 7.737315 v 0.508175 h -0.257909 v 3.811323 h 0.257909 v 0.508177 z"
         id="path50259-6" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 36.843621,66.066933 V 62.25561"
         id="path50261-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 38.133174,66.066933 V 62.25561"
         id="path50263-7" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 39.422728,66.066933 V 62.25561"
         id="path50265-6" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 40.71228,66.066933 V 62.25561"
         id="path50267-4" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 42.001832,66.066933 V 62.25561"
         id="path50269-5" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199898" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 35.089635,66.575051 h -0.593853 v -4.827186 h 0.593853 z"
         id="path60490" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.200047" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 44.276936,63.540495 H 43.829313"
         id="path50255-1-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199946" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 43.756223,66.57778 h 0.593804 v -4.82989 h -0.593804 z"
         id="path60490-0" />
    </svg>
  );
};

export default SideLoaderTruckIcon;
