import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__PRICE_REQUESTS = 'priceRequests';

export interface PriceRequestListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: PriceRequestListReduxState = {
  loading: false,
};

export default (
  state: PriceRequestListReduxState = defaultState,
  action: Action,
): PriceRequestListReduxState => {
  return state;
};
