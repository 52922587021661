import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as createSubmitActions } from './actions/AddressBookItem.create.submit.action';
import { asyncActions as editStartActions } from './actions/AddressBookItem.edit.start.action';
import { asyncActions as editSubmitActions } from './actions/AddressBookItem.edit.submit.action';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__ADDRESS_BOOK = 'address_book';

export class AddressBookItemState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  errorWrapperContact?: ErrorWrapper;
  addressBookItemId?: string;
  loading: boolean;
}

const defaultState: AddressBookItemState = {
  loading: false,
};
export default (
  state: AddressBookItemState = defaultState,
  action: Action,
): AddressBookItemState => {
  // Create actions

  if (isType(action, createSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, createSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }

  if (isType(action, createSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Edit actions
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editStartActions.done)) {
    return {
      type: action.type,
      addressBookItemId: action.payload.result.addressBookItemId,
      ...defaultState,
    };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }
  // Edit actions
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  return state;
};
