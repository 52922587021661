import React, { FC, ReactElement } from 'react';
import { AddressViewModel } from 'viewModels/common/AddressViewModel';
import {
  AddressBookItemContactListResponseViewModel,
  AddressBookItemResponseViewModel,
} from 'App/routes/Tenant/AddressBook/ViewModels';
import { AddressContactViewModel } from 'viewModels/common/AddressContactViewModel';
import FlagAndAddressComponent from 'components/Flag/FlagAndAddressComponent';
import LocaleUtilities from '../LocaleUtilities';
import { SelectComponentValue } from 'viewModels/common/SelectComponentValue';
import { NumberUtilities } from '../NumberUtilities';
import clsx from 'clsx';
import MobilePhoneIcon from '../../components/Icons/Phone/MobilePhoneIcon';

export default class AddressUtilities {
  public static formatFormValuesNumbers(formValues: AddressBookItemResponseViewModel): any {
    if (!formValues) {
      return;
    }
    if (formValues.address?.lng) formValues.address.lng = formValues.address?.lng.toString();
    if (formValues.address?.lat) formValues.address.lat = formValues.address?.lat.toString();
    if (formValues.openingHours) {
      formValues.openingHours.map((item) => {
        item.weekDay = NumberUtilities.tryFormatInt(item.weekDay?.toString()) ?? null;
        return true;
      });
    }
  }

  public static fullAddressFormat(address: AddressViewModel): JSX.Element {
    return (
      <>
        {address.firstAddressLine}, {address.city} {address.postalCode} {address.state}{' '}
        {address.country}
      </>
    );
  }

  public static contactsSelectOptions(
    contacts: AddressBookItemContactListResponseViewModel[],
  ): SelectComponentValue[] {
    let result: SelectComponentValue[] = contacts.map((item) => {
      return new SelectComponentValue({ label: item.contact.name, value: item.id.toString() });
    });
    result.unshift({ label: LocaleUtilities.i18n('form-Select', 'base.form', true), value: '' });
    return result;
  }
}

interface SidebarAddressComponentProps {
  address: AddressViewModel;
  showCompanyName?: boolean;
  showContact?: boolean;
  showNotes?: boolean;
  showAddressLine2?: boolean;
  hideAddress?: boolean;
  hideFlag?: boolean;
  showPostcode?: boolean;
}

const AddressComponent: FC<SidebarAddressComponentProps> = ({
  address,
  showContact,
  showAddressLine2,
  showNotes,
  showCompanyName,
  hideAddress,
  hideFlag,
  showPostcode,
}): ReactElement => {
  const contactData: AddressContactViewModel | undefined = address?.contacts
    ? address.contacts[0]
    : undefined;
  return (
    address && (
      <span className="address-info">
        {showCompanyName && address.name && (
          <p className={clsx('mb-1', !hideFlag && 'company-name')}>{address.name}</p>
        )}
        {!hideFlag && address.country && (
          <p>
            <FlagAndAddressComponent
              flag={address.country}
              address={`${address.country}-${address.postalCode} ${address.city}`}
            />
          </p>
        )}
        {!hideAddress && address.firstAddressLine && <p>{address.firstAddressLine}</p>}
        {showAddressLine2 && address.secondAddressLine && <p>{address.secondAddressLine}</p>}
        {showPostcode && address.postalCode && (
          <p>
            {address.country}-{address.postalCode} {address.city}
          </p>
        )}
        {showContact && contactData && (
          <p>
            <span style={{ marginRight: '3px' }}>
              <MobilePhoneIcon width={14} height={14} />
            </span>
            {contactData.name} {contactData.phone}
          </p>
        )}
        {showNotes && address.notes && (
          <span>
            {LocaleUtilities.i18n('shipment-Notes', 'shipment', true)}:<p>{address.notes}</p>
          </span>
        )}
      </span>
    )
  );
};

export { AddressComponent };
