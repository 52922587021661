import { LOCAL_STORAGE_TOKEN_KEY } from '../store/constant';
import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  ErrorWrapper,
  FailedResponseErrorWrapper,
  InternalServerErrorResponseErrorWrapper,
  ServiceResult,
} from '../store/base';
import DateUtilities from './DateUtilities';
import AuthUtils from './AuthUtils/AuthUtils';
import qs from 'qs';
import { routePaths } from '../store/api.constants';
import LocaleUtilities from './LocaleUtilities';

export default class ApiUtils {
  public static objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      // @ts-ignore
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
  }

  // Reusable headers, because they are used in 90%+ of all the cases
  public static getDefaultHeaders(accessToken: string | null = null) {
    const token = accessToken || localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || null;

    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'CargoKeep-Application-Type': 'CargoKeep',
    };
  }

  public static getFileHeaders() {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || null;
    return {
      // NB! 'Content-Type' must no exist or file upload will fail!
      Authorization: `Bearer ${token}`,
    };
  }

  //
  // Main Methods
  //

  public static async handleGet<T>(
    URL: string,
    params: Object | null = null,
    token: string | null = null,
    paramsSerializer: any | null = null,
  ): Promise<T> {
    const fixedParams = DateUtilities.tryConvertAllMomentsToDates(params);
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(token),
      params: fixedParams,
      paramsSerializer: paramsSerializer,
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(axios.get<ServiceResult<T>>(URL, config));
    return ApiUtils.returnOrThrow(response);
  }

  public static async handleGetWithArrayParams<T>(
    URL: string,
    params: Object | null = null,
    token: string | null = null,
  ): Promise<T> {
    return this.handleGet<T>(URL, params, token, function (params) {
      return qs.stringify(params, { arrayFormat: 'indices' });
    });
  }

  public static async handlePost<T>(URL: string, data: object): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(),
      method: 'POST',
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(
      axios.post<ServiceResult<T>>(URL, JSON.stringify(data), config),
    );

    return ApiUtils.returnOrThrow(response);
  }

  public static async handlePatch<T>(URL: string, data: object | null): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(),
      method: 'PATCH',
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(
      axios.patch<ServiceResult<T>>(URL, JSON.stringify(data), config),
    );
    return ApiUtils.returnOrThrow(response);
  }

  public static async handlePut<T>(URL: string, data: object | null): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(),
      method: 'PUT',
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(
      axios.put<ServiceResult<T>>(URL, JSON.stringify(data), config),
    );
    return ApiUtils.returnOrThrow(response);
  }

  public static async handleDelete(URL: string, data: any | null = null): Promise<boolean> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(),
      method: 'DELETE',
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(axios.delete(URL, config));
    // @ts-ignore
    return ApiUtils.returnOrThrow(response);
  }

  public static returnOrThrow<T>(response: ServiceResult<T> | undefined): T {
    if (!response?.isSuccessful && response?.validation) {
      const errorWrapper = new ErrorWrapper(
        LocaleUtilities.i18n('form-error-title-Validation error', 'base.error', true),
        response?.message,
      );
      if (response?.validation) {
        errorWrapper.formValidations = response.validation;
      }
      throw errorWrapper;
    } else if (!response?.isSuccessful) {
      const errorWrapper = new ErrorWrapper(
        LocaleUtilities.i18n(
          'base-error-title-Please remove or report about errors',
          'base.error',
          true,
        ),
        response?.message,
      );
      if (response?.validation) {
        errorWrapper.formValidations = response.validation;
      }
      throw errorWrapper;
    }
    return response.payload;
  }

  //
  // Edge cases
  //

  public static async handleFilePost<T>(
    URL: string,
    data: FormData,
    configProp?: Partial<AxiosRequestConfig>,
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getFileHeaders(),
      method: 'POST',
      ...configProp,
    };
    const response = await ApiUtils.handleApi(axios.post<ServiceResult<T>>(URL, data, config));
    return ApiUtils.returnOrThrow(response);
  }

  public static async handleFileGet(URL: string, params: Object | null = null): Promise<Blob> {
    const fixedParams = DateUtilities.tryConvertAllMomentsToDates(params);
    var urlParams = this.objToQueryString(fixedParams);
    var url = URL + '?' + urlParams;
    const data: any = await fetch(url, {
      headers: new Headers(ApiUtils.getDefaultHeaders()),
    });
    if (!data.ok) {
      var body = await data.json();
      return ApiUtils.returnOrThrow(body);
    }
    return await data.blob();
  }

  public static async handleFileGetPost(URL: string, data: any): Promise<Blob> {
    const dataResponse: any = await fetch(URL, {
      headers: new Headers(ApiUtils.getDefaultHeaders()),
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!dataResponse.ok) {
      var body = await dataResponse.json();
      return ApiUtils.returnOrThrow(body);
    }
    return await dataResponse.blob();
  }

  /**
   * Every API call goes through this method
   * @param {AxiosPromise<T>} promise
   * @param {boolean} throwT
   * @param useTokenRefreshCondition
   * @returns {Promise<T>}
   */
  public static async handleApi<T>(
    promise: AxiosPromise<T>,
    throwT: boolean = false,
    useTokenRefreshCondition: boolean = true,
  ): Promise<T> {
    const authUtil = new AuthUtils();

    if (authUtil.tokenNeedsRefreshing() && useTokenRefreshCondition) {
      console.info('Refreshing token. UseTokenRefreshCondition: ', useTokenRefreshCondition);
      return authUtil.refreshToken(() => doApiCall());
    } else {
      return doApiCall();
    }

    function doApiCall(): Promise<T> {
      return promise
        .then(function onFulfilled(value): T {
          if (value.status === 422) {
            //console.log('Validation error');
          }
          return value.data;
        })
        .catch(function onRejected(error: AxiosError) {
          /*if (error?.message === 'Network Error') {
            new AuthUtils().unsetToken();
            //eslint-disable-next-line no-restricted-globals
            location.href = routePaths.errors.offline;
          }*/
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // toastr.error(error.response.status.toString(), 'Request was not successful');
            // console.info(error.response.data, error.response.status, error.response.headers);
            if (error.response.status === 500) {
              throw new InternalServerErrorResponseErrorWrapper(
                error.response.data?.message,
              );
            }
            if (error.response.status === 401) {
              // console.log('handleApi - status 401 - not authorized - redirecting to login');
              new AuthUtils().unsetToken();
              // TODO: push to
              setTimeout(() => {
                if (!AuthUtils.isClientPortal()) {
                  // eslint-disable-next-line no-restricted-globals
                  location.href = routePaths.login;
                } else {
                  // eslint-disable-next-line no-restricted-globals
                  location.href = routePaths.loginClientPortal;
                }
              }, 1000);
            }

            if (throwT && error.response.data) {
              throw error.response.data;
            } else {
              if (error.response.data?.validation?.errors?.length > 0) {
                throw new FailedResponseErrorWrapper(
                  error.response.data?.validation?.errors.map((item, index) => {
                    return item.errorMessage + ';';
                  }),
                );
              } else {
                throw new FailedResponseErrorWrapper(
                  error.response.data?.message,
                );
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.info(error.request);
            throw new FailedResponseErrorWrapper('No response was received');
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.info('Error', error.message);
            // toastr.error('Error', error.message);
            throw new FailedResponseErrorWrapper(error.message);
          }
        });
    }
  }
}
