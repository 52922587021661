import { AddressViewModel } from 'viewModels/common/AddressViewModel';

export default class CustomerResponseViewModel {
  number: string = '';
  operatorId: string = '';
  vatNr: string = '';
  registerNr: string = '';
  name: string = '';
  lastName: string = '';
  paymentTermDays?: number | undefined = 7;
  address: AddressViewModel;
  invoiceAddress: AddressViewModel;
  preferredLanguage: string = 'Estonian';
}
