import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper } from 'store/base';
import { CustomerApi } from 'api/Customer.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import CustomerContactCreateOrUpdateResponseViewModel from '../../ViewModels/CustomerContactCreateOrUpdateResponseViewModel';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'CUSTOMER/CONTACT/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const submitAction =
  (formValues: CustomerContactCreateOrUpdateResponseViewModel, id: string, contactId?: number) =>
  async (dispatch) => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      if (!contactId) {
        await CustomerApi.addContact(id, formValues);
      } else {
        formValues.id = contactId;
        await CustomerApi.updateContact(id, formValues);
      }

      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };

export default submitAction;
