import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper } from 'store/base';
import AccountApi from 'api/Account.api';
import CommonUtilities from 'helpers/CommonUtilities';
import AuthUtilities, { NullableLocalStorageUserModel } from 'helpers/AuthUtils/AuthUtils';
import { AppScope } from 'store/constant';
import LoginRequestViewModel from '../viewModels/LoginRequestViewModel';
import { routePaths } from 'store/api.constants';
import { Platforms } from 'store/enums';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<
  { lastUsername: string | undefined; lastClientCode: string | undefined },
  { user: NullableLocalStorageUserModel | null },
  ErrorWrapper
>('APP/CLIENT/LOGIN/SUBMIT');

const startAction = (formValues: LoginRequestViewModel) => async (dispatch) => {
  async function mainAction(): Promise<void> {
    formValues.grant_type = 'password';
    formValues.scope = 'openid offline_access'; // for refresh token
    formValues.appScope = Platforms[Platforms.ClientPortal].toString();
    const { access_token, expires_in, refresh_token, appScope } = await AccountApi.loginUser(
      formValues,
    );
    const user = await AccountApi.getCurrentUser(access_token);

    var authUtl = new AuthUtilities();
    const dateData = authUtl.calculateExpirationDateAndHalfExpirationDate(expires_in);
    user.expiresOn = dateData.expiresOn;
    user.halfTimeExpiresOn = dateData.halfTimeExpiresOn;

    new AuthUtilities().setToken(access_token, user, refresh_token);
    AuthUtilities.setAppScope(AppScope[appScope]);
    dispatch(
      asyncActions.done({
        params: { lastUsername: undefined, lastClientCode: undefined },
        result: { user },
      }),
    );
    window.location.href = routePaths.dashboard;
  }

  async function catchAction(exception: any): Promise<void> {
    dispatch(
      asyncActions.failed({
        params: { lastUsername: undefined, lastClientCode: undefined },
        error: exception,
      }),
    );
  }

  dispatch(
    asyncActions.started({
      lastUsername: formValues.username,
      lastClientCode: formValues.clientCode,
    }),
  );
  await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
};

export default startAction;
