import { PaginatedSearchBaseOptionsResult } from 'store/base';
import RouterUtilities from 'helpers/RouterUtilities';

export default class ShipmentsRequestViewModel extends PaginatedSearchBaseOptionsResult {
  keyword: string;
  number: string;
  consignor: string;
  pickupPeriod?: string;
  pickupDateFrom?: string;
  pickupDateTo?: string;
  consignee: string;
  deliveryPeriod?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  statuses: string[];
  pickupCountries: string[];
  deliveryCountries: string[];
  status: string;
  projectId: string;
  operatorId: string;
  withOutProject: boolean;
  withOutInvoice: boolean;
  customer: string;
  excludeIds: string[];
  public parse(location: Location) {
    const obj = RouterUtilities.locationSearchStringParse(location);
    if (!obj) {
      return;
    }
    super.baseParse(obj);
    const keys = Object.keys(new ShipmentsRequestViewModel());
    for (let i = 0; i < keys.length; i++) {
      try {
        const classKey = keys[i];
        if (classKey in obj && typeof obj[classKey] === 'string') {
          this[classKey] = obj[classKey];
        }
      } catch (e) {}
    }
  }
}
