import { BACKEND_HOST_AUTH } from '../store/api.constants';
import ApiUtilities from '../helpers/ApiUtils';
import { InvitationRequestViewModel } from '../viewModels/common/InvitationRequestViewModel';
import { InvitationSubmitRequestViewModel } from '../App/routes/Sessions/Invitation/viewModels/InvitationSubmitRequestViewModel';
import { InvitationResponseViewModel } from '../viewModels/common/InvitationResponseViewModel';

function getRoot() {
  return `${BACKEND_HOST_AUTH()}api/invitation`;
}

export class InvitationApi {
  public static addUser(formValues: InvitationSubmitRequestViewModel): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}`, formValues);
  }

  public static getList(tenantId: string): Promise<InvitationResponseViewModel[]> {
    const params = {};
    return ApiUtilities.handleGet<InvitationResponseViewModel[]>(
      `${getRoot()}/pending/${tenantId}`,
      params,
    );
  }

  public static getActiveInvitation(
    token: string,
    email: string,
  ): Promise<InvitationRequestViewModel> {
    const params = { token, email };
    return ApiUtilities.handleGet<InvitationRequestViewModel>(`${getRoot()}`, params);
  }

  public static inviteTenantAdmin(formValues: InvitationRequestViewModel, id: string) {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/tenant-admin/${id}`, formValues);
  }

  public static inviteTenantUser(formValues: InvitationRequestViewModel, id: string) {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/tenant-user/${id}`, formValues);
  }

  public static inviteTenantDriver(formValues: InvitationRequestViewModel, id: string) {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/tenant-driver/${id}`, formValues);
  }

  public static resendEmail(id: string, tenantId: string): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/resend/${id}/${tenantId}`, {});
  }

  public static removeInvitation(id: string, tenantId?: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}/${tenantId}`);
  }
}
