import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper } from 'store/base';
import { TenantApi } from 'api/Tenant.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import LocaleUtilities from 'helpers/LocaleUtilities';
import TenantCreateOrUpdateRequestViewModel from '../../../ViewModels/TenantCreateOrUpdateRequestViewModel';
import { NumberUtilities } from 'helpers/NumberUtilities';

const actionCreator = actionCreatorFactory();
const type = 'TENANT/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const submitAction =
  (id: number, formValues: TenantCreateOrUpdateRequestViewModel) => async (dispatch) => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      if (formValues?.offeredPrice) {
        formValues.offeredPrice =
          NumberUtilities.tryFormatFloat(formValues?.offeredPrice.toString()) ?? 0;
      } else {
        formValues.offeredPrice = undefined;
      }
      if (formValues?.maxUsers)
        formValues.maxUsers = NumberUtilities.tryFormatInt(formValues.maxUsers.toString()) ?? 1;
      await TenantApi.update(id, formValues);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };

export default submitAction;
