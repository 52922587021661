import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__COMMENTS = 'comments';

export interface CommentListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: CommentListReduxState = {
  loading: false,
};

export default (
  state: CommentListReduxState = defaultState,
  action: Action,
): CommentListReduxState => {
  return state;
};
