import { PriceRequestResponseViewModel } from './index';
import PriceRequestOfferedPriceViewModel from './PriceRequestOfferedPriceViewModel';
import { Moment } from 'moment';

export default class PriceRequestDetailResponseViewModel extends PriceRequestResponseViewModel {
  number?: string;
  customer: string;
  statusString: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  validUntilDate: Date | Moment;
  offeredPrices: PriceRequestOfferedPriceViewModel[];
  statusComment: string;
  shipmentNumber: string;
  shipmentId: string;
  selectedOffer: number;
}
