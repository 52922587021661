import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import { AddressBookApi } from 'api/AddressBook.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { AddressBookItemResponseViewModel } from '../../ViewModels';

const actionCreator = actionCreatorFactory();
const type = 'ADDRESS_BOOK/EDIT/START';
export const asyncActions = actionCreator.async<{}, { addressBookItemId: string }, ErrorWrapper>(
  type,
);

export default function start(addressBookItemId: string) {
  return async (dispatch: Dispatch<any>) => {
    let customerInfo: any = new AddressBookItemResponseViewModel();

    async function mainAction() {
      dispatch(asyncActions.started({}));
      const customer = await AddressBookApi.getSingle(addressBookItemId);
      if (!customer) {
        throw new NotFoundErrorWrapper(`Item with Id ${addressBookItemId} not found`);
      }
      customerInfo = customer;
      dispatch(asyncActions.done({ params: {}, result: { addressBookItemId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return customerInfo;
  };
}
