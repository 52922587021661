import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__CUSTOMERS_IMPORT = 'customers_import';

export interface CustomerImportReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: CustomerImportReduxState = {
  loading: false,
};

export default (
  state: CustomerImportReduxState = defaultState,
  action: Action,
): CustomerImportReduxState => {
  return state;
};
