import * as React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { routePaths } from 'store/api.constants';
import asyncComponent from 'util/asyncComponent';
import { injectAsyncReducer } from 'store';
import { store } from 'index';
import AdminLayout2 from '../../layout/AdminLayout';
import PrivateRoute from 'components/PrivateRoute.component';
import { UserModelRoles } from 'helpers/AuthUtils';
import { REDUCER_NAME__FLEETS } from './Fleet/Fleet.List/FleetList.reducer';
import { REDUCER_KEY__FLEET } from './Fleet/Fleet.Display/Fleet.reducer';
import { REDUCER_KEY__TERMINAL } from './Terminal/Terminal.Display/Terminal.reducer';
import { REDUCER_NAME__TERMINALS } from './Terminal/Terminal.List/TerminalList.reducer';
import { REDUCER_KEY__CUSTOMER } from './Customer/Customer.Display/Customer.reducer';
import { REDUCER_NAME__CUSTOMERS } from './Customer/Customer.List/CustomerList.reducer';
import { REDUCER_NAME__ADDRESS_BOOKS } from './AddressBook/AddressBook.List/AddressBook.reducer';
import { REDUCER_NAME__ADDRESS_BOOK } from './AddressBook/AddressBook.Display/AddressBookItem.reducer';
import { REDUCER_NAME__PARTNERS } from './Partner/Partner.List/PartnerList.reducer';
import { REDUCER_KEY__PARTNER } from './Partner/Partner.Display/Partner.reducer';
import { REDUCER_NAME__SHIPMENTS } from './Shipment/Shipment.List/ShipmentList.reducer';
import { REDUCER_KEY__SHIPMENT } from './Shipment/Shipment.Display/Shipment.reducer';
import { REDUCER_NAME__PROJECTS } from './Project/Project.List/ProjectList.reducer';
import { REDUCER_NAME__SHIPPING_ORDERS } from './ShippingOrder/ShippingOrder.List/ShippingOrderList.reducer';
import { REDUCER_KEY__SHIPPING_ORDER } from './ShippingOrder/ShippingOrder.Display/ShippingOrder.reducer';
import { REDUCER_NAME__EMPLOYEES } from './Settings/Employees/Employees.reducer';
import { REDUCER_NAME__INVOICES } from './Invoice/Invoice.List/InvoiceList.reducer';
import { REDUCER_KEY__INVOICE } from './Invoice/Invoice.Display/Invoice.reducer';
import { REDUCER_NAME__PROJECT } from './Project/Project.Display/ProjectDisplay.reducer';
import { REDUCER_KEY__PRICE_REQUEST } from './PriceRequest/PriceRequest.Display/PriceRequest.reducer';
import { REDUCER_NAME__PRICE_REQUESTS } from './PriceRequest/PriceRequest.List/PriceRequestList.reducer';
import { REDUCER_NAME__CONTROL_TOWER } from './ControlTower/ControlTower.reducer';
import { REDUCER_NAME__CUSTOMERS_IMPORT } from './Customer/Customer.Import/CustomerImport.reducer';
import { REDUCER_NAME__COMMENTS } from './Comment/CommentList.reducer';

/** REDUCERS **/

class ForTenantRoutesComponent extends React.Component<any> {
  render() {
    return (
      <AdminLayout2>
        <Switch>
          {[routePaths.dashboard, routePaths.dashboard1].map((path, index) => (
            <Route
              exact={true}
              path={path}
              key={index}
              component={asyncComponent(async () => {
                const container = await import('./Dashboard/Dashboard.container');
                const reducer = await import('./Dashboard/Dashboard.reducer');
                injectAsyncReducer(store, reducer.REDUCER_NAME__DASHBOARD, reducer.default);
                return container;
              })}
            />
          ))}
          <Route
            exact={true}
            path={routePaths.profile}
            key={'profile'}
            component={asyncComponent(async () => {
              const container = await import('../Sessions/Profile/Profile.container');
              const reducer = await import('../Sessions/Profile/Profile.reducer');
              injectAsyncReducer(store, reducer.REDUCER_KEY__PROFILE, reducer.default);
              return container;
            })}
          />
          {[routePaths.inbox.list].map((path, index) => (
            <Route
              exact={true}
              path={path}
              key={index}
              component={asyncComponent(async () => {
                const container = await import('./Inbox/Inbox.container');
                const reducer = await import('./Inbox/Inbox.reducer');
                injectAsyncReducer(store, reducer.REDUCER_NAME__INBOX, reducer.default);
                return container;
              })}
            />
          ))}
          ==========================| ====| SETTINGS ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'employees'}
            path={routePaths.settingsNavs.users}
            component={asyncComponent(async () => {
              const reducer = await import('./Settings/Employees/Employees.reducer');
              injectAsyncReducer(store, REDUCER_NAME__EMPLOYEES, reducer.default);
              return await import('./Settings/Employees');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'settings'}
            path={routePaths.settings}
            component={asyncComponent(async () => {
              return await import('./Settings/Profile');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'invoiceSettings'}
            path={routePaths.settingsNavs.invoice}
            component={asyncComponent(async () => {
              return await import('./Settings/InvoiceSettings');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'pdfSettings'}
            path={routePaths.settingsNavs.pdf}
            component={asyncComponent(async () => {
              return await import('./Settings/PdfSettings');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'messageTemplates'}
            path={routePaths.settingsNavs.messageTemplates}
            component={asyncComponent(async () => {
              const container = await import(
                './Settings/MessageTemplate/MessageTemplate.container'
              );
              const reducer = await import('./Settings/MessageTemplate/MessageTemplate.reducer');
              injectAsyncReducer(
                store,
                reducer.REDUCER_NAME__MESSAGE_TEMPLATE_TYPE,
                reducer.default,
              );
              return container;
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'termsAndConditions'}
            path={routePaths.settingsNavs.termsAndConditions}
            component={asyncComponent(async () => {
              const container = await import(
                './Settings/TermsAndConditions/TermsAndConditions.container'
              );
              const reducer = await import('./Settings/TermsAndConditions/TermsAndConditions.reducer');
              injectAsyncReducer(
                store,
                reducer.REDUCER_NAME__TERMS_AND_CONDITIONS_TYPE,
                reducer.default,
              );
              return container;
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'package_types'}
            path={routePaths.settingsNavs.packageTypes}
            component={asyncComponent(async () => {
              const container = await import('./Settings/PackageType/PackageType.container');
              const reducer = await import('./Settings/PackageType/PackageType.reducer');
              injectAsyncReducer(store, reducer.REDUCER_NAME__PACKAGE_TYPE, reducer.default);
              return container;
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'vat_rates'}
            path={routePaths.settingsNavs.vat}
            component={asyncComponent(async () => {
              const container = await import('./Settings/VatRate/VatRate.container');
              const reducer = await import('./Settings/VatRate/VatRate.reducer');
              injectAsyncReducer(store, reducer.REDUCER_NAME__VAT_RATES, reducer.default);
              return container;
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'ip_access_management'}
            path={routePaths.settingsNavs.ipAccessManagement}
            component={asyncComponent(async () => {
              const container = await import(
                './Settings/IpAccessManagement/IpAccessManagement.container'
              );
              const reducer = await import(
                './Settings/IpAccessManagement/IpAccessManagement.reducer'
              );
              injectAsyncReducer(
                store,
                reducer.REDUCER_NAME__IP_ACCESS_MANAGEMENT,
                reducer.default,
              );
              return container;
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'preferences'}
            path={routePaths.settingsNavs.preferences}
            component={asyncComponent(async () => {
              return await import('./Settings/Preferences');;
            })}
          />
          ==========================| ====| FLEET ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'fleets'}
            path={routePaths.fleet.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Fleet/Fleet.List/FleetList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__FLEETS, reducer.default);
              return await import('./Fleet/Fleet.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.fleet.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Fleet/Fleet.Display/Fleet.reducer');
              injectAsyncReducer(store, REDUCER_KEY__FLEET, reducer.default);
              return await import('./Fleet/Fleet.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.fleet.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Fleet/Fleet.Display/Fleet.reducer');
              injectAsyncReducer(store, REDUCER_KEY__FLEET, reducer.default);
              return await import('./Fleet/Fleet.Display/Edit');
            })}
          />
          ==========================| ====| TERMINAL ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'terminals'}
            path={routePaths.terminals.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Terminal/Terminal.List/TerminalList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__TERMINALS, reducer.default);
              return await import('./Terminal/Terminal.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.terminals.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Terminal/Terminal.Display/Terminal.reducer');
              injectAsyncReducer(store, REDUCER_KEY__TERMINAL, reducer.default);
              return await import('./Terminal/Terminal.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.terminals.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Terminal/Terminal.Display/Terminal.reducer');
              injectAsyncReducer(store, REDUCER_KEY__TERMINAL, reducer.default);
              return await import('./Terminal/Terminal.Display/Edit');
            })}
          />
          ==========================| ====| SHIPMENT ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'shipments'}
            path={routePaths.shipments.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.List/ShipmentList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__SHIPMENTS, reducer.default);
              return await import('./Shipment/Shipment.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.shipments.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.Display/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__SHIPMENT, reducer.default);
              return await import('./Shipment/Shipment.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.shipments.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.Display/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__SHIPMENT, reducer.default);
              return await import('./Shipment/Shipment.Display/Edit');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.shipments.display}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.Display/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__SHIPMENT, reducer.default);
              return await import('./Shipment/Shipment.Display/Display');
            })}
          />
          ==========================| ====| PRICE REQUEST ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'priceRequests'}
            path={routePaths.priceRequests.list}
            component={asyncComponent(async () => {
              const reducer = await import(
                './PriceRequest/PriceRequest.List/PriceRequestList.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__PRICE_REQUESTS, reducer.default);
              return await import('./PriceRequest/PriceRequest.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.priceRequests.new}
            component={asyncComponent(async () => {
              const reducer = await import(
                './PriceRequest/PriceRequest.Display/PriceRequest.reducer'
              );
              injectAsyncReducer(store, REDUCER_KEY__PRICE_REQUEST, reducer.default);
              return await import('./PriceRequest/PriceRequest.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.priceRequests.edit}
            component={asyncComponent(async () => {
              const reducer = await import(
                './PriceRequest/PriceRequest.Display/PriceRequest.reducer'
              );
              injectAsyncReducer(store, REDUCER_KEY__PRICE_REQUEST, reducer.default);
              return await import('./PriceRequest/PriceRequest.Display/Edit');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.priceRequests.display}
            component={asyncComponent(async () => {
              const reducer = await import(
                './PriceRequest/PriceRequest.Display/PriceRequest.reducer'
              );
              injectAsyncReducer(store, REDUCER_KEY__PRICE_REQUEST, reducer.default);
              return await import('./PriceRequest/PriceRequest.Display/Display');
            })}
          />
          ==========================| ====| CUSTOMER ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'clients'}
            path={routePaths.clients.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Customer/Customer.List/CustomerList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__CUSTOMERS, reducer.default);
              return await import('./Customer/Customer.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin]}
            exact={true}
            key={'customers_import'}
            path={routePaths.clients.import}
            component={asyncComponent(async () => {
              const reducer = await import('./Customer/Customer.Import/CustomerImport.reducer');
              injectAsyncReducer(store, REDUCER_NAME__CUSTOMERS_IMPORT, reducer.default);
              return await import('./Customer/Customer.Import');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.clients.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Customer/Customer.Display/Customer.reducer');
              injectAsyncReducer(store, REDUCER_KEY__CUSTOMER, reducer.default);
              return await import('./Customer/Customer.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.clients.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Customer/Customer.Display/Customer.reducer');
              injectAsyncReducer(store, REDUCER_KEY__CUSTOMER, reducer.default);
              return await import('./Customer/Customer.Display/Edit');
            })}
          />
          ==========================| ====| SHIPPING ORDERS ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'shippingOrders'}
            path={routePaths.shippingOrders.list}
            component={asyncComponent(async () => {
              const reducer = await import(
                './ShippingOrder/ShippingOrder.List/ShippingOrderList.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__SHIPPING_ORDERS, reducer.default);
              return await import('./ShippingOrder/ShippingOrder.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.shippingOrders.edit}
            component={asyncComponent(async () => {
              const reducer = await import(
                './ShippingOrder/ShippingOrder.Display/ShippingOrder.reducer'
              );
              injectAsyncReducer(store, REDUCER_KEY__SHIPPING_ORDER, reducer.default);
              return await import('./ShippingOrder/ShippingOrder.Display');
            })}
          />
          ==========================| ====| COMMENTS ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'comments'}
            path={routePaths.comments.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Comment/CommentList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__COMMENTS, reducer.default);
              return await import('./Comment');
            })}
          />
          ==========================| ====| PROJECT ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'projects'}
            path={routePaths.projects.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Project/Project.List/ProjectList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__PROJECTS, reducer.default);
              return await import('./Project/Project.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'project'}
            path={routePaths.projects.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Project/Project.Display/ProjectDisplay.reducer');
              injectAsyncReducer(store, REDUCER_NAME__PROJECT, reducer.default);
              return await import('./Project/Project.Display');
            })}
          />
          ==========================| ====| PARTNERS ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'partners'}
            path={routePaths.partners.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Partner/Partner.List/PartnerList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__PARTNERS, reducer.default);
              return await import('./Partner/Partner.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.partners.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Partner/Partner.Display/Partner.reducer');
              injectAsyncReducer(store, REDUCER_KEY__PARTNER, reducer.default);
              return await import('./Partner/Partner.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.partners.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Partner/Partner.Display/Partner.reducer');
              injectAsyncReducer(store, REDUCER_KEY__PARTNER, reducer.default);
              return await import('./Partner/Partner.Display/Edit');
            })}
          />
          ==========================| ====| ADDRESS BOOK ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'addressBook'}
            path={routePaths.addressBook.list}
            component={asyncComponent(async () => {
              const reducer = await import('./AddressBook/AddressBook.List/AddressBook.reducer');
              injectAsyncReducer(store, REDUCER_NAME__ADDRESS_BOOKS, reducer.default);
              return await import('./AddressBook/AddressBook.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.addressBook.new}
            component={asyncComponent(async () => {
              const reducer = await import(
                './AddressBook/AddressBook.Display/AddressBookItem.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__ADDRESS_BOOK, reducer.default);
              return await import('./AddressBook/AddressBook.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.addressBook.edit}
            component={asyncComponent(async () => {
              const reducer = await import(
                './AddressBook/AddressBook.Display/AddressBookItem.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__ADDRESS_BOOK, reducer.default);
              return await import('./AddressBook/AddressBook.Display/Edit');
            })}
          />
          ==========================| ====| INVOICES ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'invoices'}
            path={routePaths.invoices.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Invoice/Invoice.List/InvoiceList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__INVOICES, reducer.default);
              return await import('./Invoice/Invoice.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            path={routePaths.invoices.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Invoice/Invoice.Display/Invoice.reducer');
              injectAsyncReducer(store, REDUCER_KEY__INVOICE, reducer.default);
              return await import('./Invoice/Invoice.Display');
            })}
          />
          ==========================| ====| CONTROL TOWER ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantAdmin, UserModelRoles.tenantUser]}
            exact={true}
            key={'controlTower'}
            path={routePaths.controlTower}
            component={asyncComponent(async () => {
              const reducer = await import('./ControlTower/ControlTower.reducer');
              injectAsyncReducer(store, REDUCER_NAME__CONTROL_TOWER, reducer.default);
              return await import('./ControlTower');
            })}
          />
        </Switch>
      </AdminLayout2>
    );
  }
}

export default ForTenantRoutesComponent;
