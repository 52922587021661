import { SelectComponentApi } from 'api/SelectComponent.api';
import { SimpleRequest } from 'viewModels/common/SimpleRequest';
import { PlatformSearchIndexResult } from '../types/PlatformSearchIndexResult';

const fetchPlatformSearch = async (params: SimpleRequest): Promise<PlatformSearchIndexResult[]> => {
  try {
    const response = await SelectComponentApi.platformSearchIndex(params);
    if (response) {
      return response;
    }
  } catch {}

  return [];
};

export default fetchPlatformSearch;
