import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__ADDRESS_BOOKS = 'address_book_list';

export interface AddressBookListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: AddressBookListReduxState = {
  loading: false,
};

export default (
  state: AddressBookListReduxState = defaultState,
  action: Action,
): AddressBookListReduxState => {
  return state;
};
