import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryColor } from 'theme';

const SalesIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 76,
  height = 76,
  fill = primaryColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 75C58.2107 75 75 58.2107 75 37.5C75 16.7893 58.2107 0 37.5 0C16.7893 0 0 16.7893 0 37.5C0 58.2107 16.7893 75 37.5 75Z"
        fill="white"
      />
      <path
        d="M37.5 71.2746C56.1533 71.2746 71.2747 56.1532 71.2747 37.5C71.2747 18.8467 56.1533 3.72528 37.5 3.72528C18.8468 3.72528 3.72534 18.8467 3.72534 37.5C3.72534 56.1532 18.8468 71.2746 37.5 71.2746Z"
        stroke="#0A6CBA"
        stroke-width="0.973686"
      />
      <path
        d="M45.2541 23.802C51.6974 23.802 56.9207 22.1254 56.9207 20.0572C56.9207 17.989 51.6974 16.3124 45.2541 16.3124C38.8107 16.3124 33.5874 17.989 33.5874 20.0572C33.5874 22.1254 38.8107 23.802 45.2541 23.802Z"
        fill="#0A6CBA"
      />
      <path
        d="M33.5874 23.802V26.2986C33.5874 26.7582 33.9307 27.2768 34.3587 27.4458C41.5055 30.2684 48.7631 30.4372 56.1522 27.4521C56.5788 27.2798 56.9207 26.7579 56.9207 26.2983V23.8017C56.9207 23.3421 56.5792 23.1197 56.1529 23.293C48.6661 26.3366 41.4227 26.0449 34.3598 23.282C33.9313 23.1144 33.5874 23.3422 33.5874 23.8019V23.802Z"
        fill="#0A6CBA"
      />
      <path
        d="M33.5874 30.0435C33.5874 30.5031 33.9514 30.9579 34.3963 31.0756C37.7353 31.9595 39.2559 33.1243 39.8774 33.8908C40.1631 34.2431 40.2541 34.9931 40.2541 35.4527C40.2541 35.6825 40.6263 35.8939 41.0857 35.9217C46.6737 36.2598 51.3367 36.1454 56.1807 33.7546C56.5933 33.5509 56.9207 32.9996 56.9207 32.54V30.0435C56.9207 29.5839 56.5904 29.3849 56.1738 29.5802C49.456 32.7283 41.0667 32.1976 34.3572 29.5299C33.9296 29.3599 33.5874 29.5838 33.5874 30.0434V30.0435Z"
        fill="#0A6CBA"
      />
      <path
        d="M40.2539 38.7814V41.2779C40.2539 41.7375 40.627 42.1139 41.0872 42.1202C46.2292 42.1908 51.2633 42.366 56.1806 39.991C56.5937 39.7914 56.9206 39.241 56.9206 38.7814V36.2848C56.9206 35.8252 56.6083 35.6574 56.2063 35.8809C52.8231 37.7622 47.9722 38.4853 41.0847 38.0114C40.6257 37.9797 40.2539 38.3218 40.2539 38.7814Z"
        fill="#0A6CBA"
      />
      <path
        d="M40.2539 45.0227V47.5192C40.2539 47.9788 40.6264 48.3726 41.0861 48.3942C48.0801 48.7234 52.9999 48.153 56.2166 46.2997C56.6151 46.0701 56.9206 45.4823 56.9206 45.0227V42.5261C56.9206 42.0665 56.5982 41.882 56.1906 42.0954C51.3378 44.6358 46.8123 44.458 41.0864 44.2242C40.6266 44.2055 40.2539 44.5631 40.2539 45.0227Z"
        fill="#0A6CBA"
      />
      <path
        d="M27.3373 39.1974C33.7806 39.1974 39.004 37.5207 39.004 35.4525C39.004 33.3843 33.7806 31.7077 27.3373 31.7077C20.894 31.7077 15.6707 33.3843 15.6707 35.4525C15.6707 37.5207 20.894 39.1974 27.3373 39.1974Z"
        fill="#0A6CBA"
      />
      <path
        d="M15.6707 39.1974V41.694C15.6707 42.1536 16.0147 42.6703 16.4435 42.8375C23.4792 45.581 30.7162 45.9129 38.2364 42.8501C38.6626 42.6765 39.004 42.1535 39.004 41.6939V39.1974C39.004 38.7378 38.6632 38.517 38.2379 38.6926C29.9355 42.1192 23.0035 41.0159 16.4523 38.6534C16.0195 38.4972 15.6707 38.7377 15.6707 39.1973V39.1974Z"
        fill="#0A6CBA"
      />
      <path
        d="M15.6707 45.4953V47.9919C15.6707 48.4515 16.0147 48.9682 16.4435 49.1354C23.4792 51.8789 30.7162 52.2108 38.2364 49.148C38.6626 48.9744 39.004 48.4514 39.004 47.9918V45.4953C39.004 45.0357 38.6632 44.8149 38.2379 44.9905C29.9355 48.4171 23.0035 47.3138 16.4523 44.9513C16.0195 44.7952 15.6707 45.0356 15.6707 45.4952V45.4953Z"
        fill="#0A6CBA"
      />
      <path
        d="M15.6707 51.7367V54.2333C15.6707 54.6929 16.0147 55.2096 16.4435 55.3768C23.4792 58.1203 30.7162 58.4522 38.2364 55.3894C38.6626 55.2158 39.004 54.6928 39.004 54.2332V51.7367C39.004 51.2771 38.6632 51.0563 38.2379 51.2319C29.9355 54.6585 23.0035 53.5552 16.4523 51.1927C16.0195 51.0365 15.6707 51.277 15.6707 51.7366V51.7367Z"
        fill="#0A6CBA"
      />
    </svg>
  );
};

export default SalesIcon;
