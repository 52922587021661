import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const BoxTruckIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 50,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="32 72 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-05-5"
         ry="1.0898695"
         rx="1.0904982"
         cy="79.506538"
         cx="35.321751" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-07-3"
         ry="1.0898681"
         rx="1.0904984"
         cy="79.506531"
         cx="44.053249" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-16-0"
         cx="35.321781"
         cy="79.507065"
         rx="0.5615539"
         ry="0.56122905" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-7-5"
         cx="44.053028"
         cy="79.507057"
         rx="0.5615539"
         ry="0.56122905" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 45.176386,79.27503 H 47.52503 V 78.151374 H 33.172208 v 1.123656 h 1.04384"
         id="path23925-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 37.141582,77.687584 v -4.827251 l 10.383502,10e-7 v 4.82725 z"
         id="path23927-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 36.5125,79.292708 h 6.35"
         id="path28474-6" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 36.677097,77.687514 v -3.768778 h -2.753296 l -0.750898,2.261267 v 1.507511 z"
         id="path44993" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 33.673502,74.421241 h 2.002395 l -0.03153,1.739772 -2.471468,0.019"
         id="path44997" />
    </svg>
  );
};

export default BoxTruckIcon;
