import actionCreatorFactory from 'typescript-fsa';

import { ErrorWrapper } from 'store/base';
import { CustomerApi } from 'api/Customer.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import CustomerResponseViewModel from '../../ViewModels/CustomerResponseViewModel';
import { Dispatch } from 'redux';
import CustomerUtilities from 'helpers/ModelUtilities/CustomerUtilities';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'CUSTOMER/CREATE/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submitCreateAction(formValues: CustomerResponseViewModel) {
  return async (dispatch: Dispatch<any>) => {
    let id: string | undefined = undefined;

    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      CustomerUtilities.formatFormValuesNumbers(formValues);
      const response = await CustomerApi.add(formValues);
      if (response) id = response;
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
    return id;
  };
}
