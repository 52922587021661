import TerminalRequestViewModel from './TerminalRequestViewModel';
import TerminalsResponseViewModel from './TerminalsResponseViewModel';
import TerminalResponseViewModel from './TerminalResponseViewModel';
import TerminalsRequestViewModel from './TerminalsRequestViewModel';

export {
  TerminalRequestViewModel,
  TerminalsResponseViewModel,
  TerminalResponseViewModel,
  TerminalsRequestViewModel,
};
