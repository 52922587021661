import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as createSubmitActions } from './actions/Customer.create.submit.action';
import { asyncActions as editStartActions } from './actions/Customer.edit.start.action';
import { asyncActions as editSubmitActions } from './actions/Customer.edit.submit.action';
import { asyncActions as contactSubmitActions } from './actions/Customer.contact.submit.action';
import { asyncActions as contactInviteActions } from './actions/Customer.contact.invite.action';
import { ErrorWrapper } from 'store/base';

export const REDUCER_KEY__CUSTOMER = 'customer';

export class CustomerState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  errorWrapperUser?: ErrorWrapper;
  errorInviteCustomer?: ErrorWrapper;
  customerId?: string;
  loading: boolean;
}

const defaultState: CustomerState = {
  loading: false,
};
export default (state: CustomerState = defaultState, action: Action): CustomerState => {
  // Create actions

  if (isType(action, createSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, createSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }

  if (isType(action, createSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Edit actions
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editStartActions.done)) {
    return { type: action.type, customerId: action.payload.result.customerId, ...defaultState };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }
  // Edit actions
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }
  // Contact submit actions
  if (isType(action, contactSubmitActions.started)) {
    return { ...state, type: action.type, loading: true, errorWrapperUser: undefined };
  }
  if (isType(action, contactSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, contactSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapperUser: action.payload.error, loading: false };
  }

  // Contact invite actions
  if (isType(action, contactInviteActions.started)) {
    return { ...state, type: action.type, loading: true, errorInviteCustomer: undefined };
  }
  if (isType(action, contactInviteActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, contactInviteActions.failed)) {
    return {
      ...state,
      type: action.type,
      errorInviteCustomer: action.payload.error,
      loading: false,
    };
  }

  return state;
};
