import { Action } from 'redux';
import { ErrorWrapper } from 'store/base';

export const REDUCER_NAME__SHIPMENTS = 'shipments';

export interface ShipmentListReduxState {
  type?: string;
  loading: boolean;
  errorWrapper?: ErrorWrapper;
}

const defaultState: ShipmentListReduxState = {
  loading: false,
};

export default (
  state: ShipmentListReduxState = defaultState,
  action: Action,
): ShipmentListReduxState => {
  return state;
};
