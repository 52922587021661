import { Moment } from 'moment';
import { ShipmentPackageViewModel } from 'App/routes/Tenant/Shipment/ViewModels/ShipmentResponseViewModel';
import { AddressViewModel } from '../common/AddressViewModel';
import { SpotRequestPriceOfferPriceViewModel } from './SpotRequestPriceOfferViewModel';

export default class SpotRequestPublicViewModel {
  number: string;
  tenantName: string;
  partner: string;
  partnerContact: string;
  contactPerson: string;
  contactPersonPhone: string;
  comment: string;
  status: string;
  shipments: SpotRequestShipmentViewModel[];
  validUntil?: Date | Moment;
  totalLdm?: number;
  totalWeight?: number;
  totalVolume?: number;
  canAddOffer: boolean;
  isExpired: boolean;
  prices: SpotRequestPriceOfferPriceViewModel[];
  contactPersonEmail: string;
}

export class SpotRequestShipmentViewModel {
  pickUpDate?: Date | Moment;
  pickUpTime: string | null;
  pickUpTimeTo: string | null;
  latestPickUpDate?: Date | Moment;
  latestPickUpTime: string | null;
  latestPickUpTimeTo: string | null;
  deliveryDate?: Date | Moment;
  deliveryTime: string | null;
  deliveryTimeTo: string | null;
  latestDeliveryDate?: Date | Moment;
  latestDeliveryTime: string | null;
  latestDeliveryTimeTo: string | null;
  totalWeight: number | string;
  totalQuantity: number | string;
  totalVolume: number | string;
  ldmCorrection: number | null;
  collection: AddressViewModel;
  delivery: AddressViewModel;
  collectionTailLiftTruck: boolean;
  deliveryTailLiftTruck: boolean;
  packages: ShipmentPackageViewModel[];
}
