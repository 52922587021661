import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import { CustomerApi } from 'api/Customer.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { CustomerResponseViewModel } from '../../ViewModels';

const actionCreator = actionCreatorFactory();
const type = 'CUSTOMER/EDIT/START';
export const asyncActions = actionCreator.async<{}, { customerId: string }, ErrorWrapper>(type);

export default function start(customerId: string) {
  return async (dispatch: Dispatch<any>) => {
    let customerInfo: any = new CustomerResponseViewModel();

    async function mainAction() {
      dispatch(asyncActions.started({}));
      const customer = await CustomerApi.getSingle(customerId);
      if (!customer) {
        throw new NotFoundErrorWrapper(`Item with Id ${customerId} not found`);
      }
      customerInfo = customer;
      dispatch(asyncActions.done({ params: {}, result: { customerId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return customerInfo;
  };
}
