import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { InvitationSubmitRequestViewModel } from '../viewModels/InvitationSubmitRequestViewModel';
import { Dispatch } from 'redux';
import { InvitationApi } from 'api/Invitation.api';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('APP/INVITATION/SUBMIT');

export default function submitInvitation(formValues: InvitationSubmitRequestViewModel) {
  let result: boolean = false;

  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      formValues.email = encodeURI(formValues.email);
      const data = await InvitationApi.addUser(formValues);

      if (!data) {
        throw new NotFoundErrorWrapper(`Item not found`);
      }
      result = data;

      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return result;
  };
}
