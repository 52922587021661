import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper } from 'store/base';
import { CustomerApi } from 'api/Customer.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { toastr } from 'react-redux-toastr';
const actionCreator = actionCreatorFactory();
const type = 'CUSTOMER/CONTACT/INVITE';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const submitAction = (id: string, contactId: number) => async (dispatch) => {
  async function mainAction(): Promise<void> {
    dispatch(asyncActions.started({}));
    await CustomerApi.inviteContact(id, contactId);
    dispatch(asyncActions.done({ params: {}, result: {} }));
    toastr.success('Success', 'Invitation sent');
  }

  async function catchAction(exception: any): Promise<void> {
    dispatch(asyncActions.failed({ params: {}, error: exception }));
  }

  dispatch(asyncActions.started({}));
  await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
};

export default submitAction;
