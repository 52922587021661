import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryColor } from 'theme';

const LogisticsIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 76,
  height = 76,
  fill = primaryColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 75C58.2107 75 75 58.2107 75 37.5C75 16.7893 58.2107 0 37.5 0C16.7893 0 0 16.7893 0 37.5C0 58.2107 16.7893 75 37.5 75Z"
        fill="white"
      />
      <path
        d="M37.5 71.2746C56.1533 71.2746 71.2747 56.1532 71.2747 37.5C71.2747 18.8467 56.1533 3.72528 37.5 3.72528C18.8468 3.72528 3.72534 18.8467 3.72534 37.5C3.72534 56.1532 18.8468 71.2746 37.5 71.2746Z"
        stroke="#0A6CBA"
        strokeWidth="0.973686"
      />
      <path
        d="M16.6641 48.4141V23.1133C16.6641 22.9817 16.7163 22.8555 16.8094 22.7625C16.9024 22.6695 17.0286 22.6172 17.1602 22.6172H41.9648C42.0964 22.6172 42.2226 22.6695 42.3156 22.7625C42.4087 22.8555 42.4609 22.9817 42.4609 23.1133V48.4141C42.4609 48.5456 42.4087 48.6718 42.3156 48.7649C42.2226 48.8579 42.0964 48.9102 41.9648 48.9102H17.1602C17.0286 48.9102 16.9024 48.8579 16.8094 48.7649C16.7163 48.6718 16.6641 48.5456 16.6641 48.4141Z"
        fill="#0A6CBA"
      />
      <path
        d="M44.4455 48.9102H57.344C57.4756 48.9102 57.6018 48.8579 57.6948 48.7649C57.7878 48.6718 57.8401 48.5456 57.8401 48.4141V37.9961C57.8401 37.6736 57.7385 37.3593 57.5496 37.0978L51.6813 28.9725C51.5914 28.848 51.4732 28.7466 51.3365 28.6767C51.1997 28.6068 51.0484 28.5703 50.8948 28.5703H44.4455C44.314 28.5703 44.1878 28.6226 44.0948 28.7156C44.0017 28.8087 43.9495 28.9348 43.9495 29.0664V48.4141C43.9495 48.5456 44.0017 48.6718 44.0948 48.7649C44.1878 48.8579 44.314 48.9102 44.4455 48.9102Z"
        fill="#0A6CBA"
      />
      <path
        d="M53.1634 32.6974L56.0671 36.8455C56.2523 37.1101 56.3516 37.4251 56.3516 37.748V38.2441C56.3516 38.3757 56.2993 38.5019 56.2063 38.5949C56.1132 38.688 55.9871 38.7402 55.8555 38.7402H46.9258C46.7942 38.7402 46.668 38.688 46.575 38.5949C46.482 38.5019 46.4297 38.3757 46.4297 38.2441V32.7871C46.4297 32.6555 46.482 32.5294 46.575 32.4363C46.668 32.3433 46.7942 32.291 46.9258 32.291H52.3828C52.536 32.291 52.687 32.328 52.8228 32.3987C52.9587 32.4695 53.0756 32.5719 53.1634 32.6974Z"
        fill="white"
      />
      <path
        d="M27.5779 53.129C29.9078 53.129 31.7966 51.2402 31.7966 48.9102C31.7966 46.5803 29.9078 44.6915 27.5779 44.6915C25.2479 44.6915 23.3591 46.5803 23.3591 48.9102C23.3591 51.2402 25.2479 53.129 27.5779 53.129Z"
        fill="#0A6CBA"
      />
      <path
        d="M49.4065 53.129C51.7364 53.129 53.6252 51.2402 53.6252 48.9102C53.6252 46.5803 51.7364 44.6915 49.4065 44.6915C47.0765 44.6915 45.1877 46.5803 45.1877 48.9102C45.1877 51.2402 47.0765 53.129 49.4065 53.129Z"
        fill="#0A6CBA"
      />
      <path
        d="M27.5779 51.7227C29.1312 51.7227 30.3904 50.4635 30.3904 48.9102C30.3904 47.3569 29.1312 46.0977 27.5779 46.0977C26.0246 46.0977 24.7654 47.3569 24.7654 48.9102C24.7654 50.4635 26.0246 51.7227 27.5779 51.7227Z"
        fill="white"
      />
      <path
        d="M49.4065 51.7227C50.9598 51.7227 52.219 50.4635 52.219 48.9102C52.219 47.3569 50.9598 46.0977 49.4065 46.0977C47.8532 46.0977 46.594 47.3569 46.594 48.9102C46.594 50.4635 47.8532 51.7227 49.4065 51.7227Z"
        fill="white"
      />
    </svg>
  );
};

export default LogisticsIcon;
