import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const DumpTruckIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 50,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="30 52 15 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-05-5-6"
         ry="1.0901316"
         rx="1.090403"
         cy="59.664474"
         cx="32.676449" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-07-3-2"
         ry="1.0901302"
         rx="1.0904032"
         cy="59.664467"
         cx="41.407185" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-16-0-8"
         cx="32.676479"
         cy="59.665001"
         rx="0.5615049"
         ry="0.56136405" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-7-5-1"
         cx="41.406963"
         cy="59.664993"
         rx="0.5615049"
         ry="0.56136405" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 42.530223,59.432912 h 2.348439 V 58.308985 H 30.527093 v 1.123927 h 1.043749"
         id="path23925-2-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 33.867093,59.450594 h 6.349446"
         id="path28474-6-44" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 34.031676,57.845014 V 54.075329 H 31.27862 l -0.750832,2.261811 v 1.507874 z"
         id="path44993-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 31.028343,54.577955 h 2.00222 l -0.03153,1.740191 -2.471252,0.019"
         id="path44997-0" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199766" stroke-linecap="butt" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 34.495716,57.843867 v -3.768985 h 0.778769 v 0.753798 h 8.306856 l 1.297948,3.015187 z"
         id="path48528" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199766" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 43.062164,55.582477 0.519177,1.507594"
         id="path48530" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199766" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 40.466271,55.582477 0.519178,1.507594"
         id="path48532" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199766" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 37.870377,55.582477 0.519179,1.507594"
         id="path48534" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199766" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 35.274485,55.582477 0.519178,1.507594"
         id="path48536" />
    </svg>
  );
};

export default DumpTruckIcon;
