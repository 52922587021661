import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper, NotFoundErrorWrapper } from 'store/base';
import CommonUtilities from 'helpers/CommonUtilities';
import { Dispatch } from 'redux';
import { SpotRequestApi } from 'api/SpotRequest.api';
import SpotRequestPublicViewModel from 'viewModels/spotRequest/SpotRequestPublicViewModel';

const actionCreator = actionCreatorFactory();

export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('APP/SPOT_REQUEST/START');

export default function startSpotRequest(token: string, saveOpenedTime: boolean) {
  let result: SpotRequestPublicViewModel = new SpotRequestPublicViewModel();

  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      const data = await SpotRequestApi.getActiveRequest(token, saveOpenedTime);

      if (!data) {
        throw new NotFoundErrorWrapper(`Item not found`);
      }
      result = data;

      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);

    return result;
  };
}
