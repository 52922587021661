import * as React from 'react';
import clsx from 'clsx';

interface Props {
  isLoading: boolean;
  centered?: boolean;
  showOverlayType?: string | 'white-overlay';
  error?: any;
}

class LoadingComponent extends React.Component<Props> {
  render() {
    const { isLoading, error, centered } = this.props;
    if (isLoading) {
      return (
        <div
          className={clsx(
            'overlay text-center',
            this.props.showOverlayType ? this.props.showOverlayType : null,
          )}
        >
          <div
            className={clsx(centered && 'overlay-centered', 'spinner-border mr-1 text-primary')}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else if (error) {
      return <div>Sorry, there was a problem loading the page.</div>;
    } else {
      return null;
    }
  }
}

export default LoadingComponent;
