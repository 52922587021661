import { BACKEND_HOST_AUTH } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { SpotRequestUpdateViewModel } from 'viewModels/spotRequest/SpotRequestUpdateViewModel';
import { PaginatedListResult } from 'store/base';
import SpotRequestsRequestViewModel from 'viewModels/spotRequest/SpotRequestsRequestViewModel';
import SpotRequestsResponseViewModel from 'viewModels/spotRequest/SpotRequestsResponseViewModel';
import SpotRequestPublicViewModel from 'viewModels/spotRequest/SpotRequestPublicViewModel';
import SpotRequestPriceOfferViewModel from 'viewModels/spotRequest/SpotRequestPriceOfferViewModel';
import {
  SpotRequestPrivatePriceOfferPriceViewModel,
  SpotRequestPrivateResponseViewModel,
} from 'viewModels/spotRequest/SpotRequestPrivateResponseViewModel';
import { BasicIdsRequestViewModel } from 'viewModels/common/BasicIdsRequestViewModel';

function getRoot() {
  return `${BACKEND_HOST_AUTH()}api/spot_request`;
}

export class SpotRequestApi {
  public static getList(
    params: SpotRequestsRequestViewModel,
  ): Promise<PaginatedListResult<SpotRequestsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<SpotRequestsResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<SpotRequestPrivateResponseViewModel | null> {
    return ApiUtilities.handleGet<SpotRequestPrivateResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static selectPrice(
    id: string,
    formValues: SpotRequestPrivatePriceOfferPriceViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/select-price`, formValues);
  }

  public static addSpotRequests(formValues: SpotRequestUpdateViewModel): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}`, formValues);
  }

  public static submitOfferPricesForm(
    token: string,
    formValues: SpotRequestPriceOfferViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${token}/offer_prices`, formValues);
  }

  public static sendRejectEmail(formValues: BasicIdsRequestViewModel): Promise<number> {
    return ApiUtilities.handlePost<number>(`${getRoot()}/reject-email`, formValues);
  }

  public static getActiveRequest(
    token: string,
    saveOpenedTime: boolean,
  ): Promise<SpotRequestPublicViewModel> {
    const params = { saveOpenedTime };
    return ApiUtilities.handleGet<SpotRequestPublicViewModel>(
      `${getRoot()}/${token}/public`,
      params,
    );
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static resendEmail(id: string): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/resend/${id}`, {});
  }
}
