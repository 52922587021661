import React from 'react';

export default class FlagComponent extends React.Component<{
  flag: string;
}> {
  render() {
    const { flag } = this.props;
    try {
      return (
        <img
          alt={flag}
          className={'mr-1 flag-component'}
          src={require(`../../assets/images/flag-icons/svg/${flag.toLowerCase()}.svg`)}
        />
      );
    } catch (error) {
      return <span>{flag}</span>;
    }
  }
}
