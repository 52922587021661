import { BACKEND_HOST_TRANSLATIONS } from '../store/api.constants';
import { PaginatedListResult } from '../store/base';
import ApiUtilities from '../helpers/ApiUtils';
import TranslationsRequestViewModel from '../App/routes/Admin/Translations/viewModels/TranslationsRequestViewModel';
import TranslationsResponseViewModel from '../App/routes/Admin/Translations/viewModels/TranslationsResponseViewModel';
import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';
import TranslationsInsertUpdateViewModel from '../App/routes/Admin/Translations/viewModels/TranslationsInsertUpdateViewModel';
import CommonUtilities from '../helpers/CommonUtilities';

function getRoot() {
  return `${BACKEND_HOST_TRANSLATIONS()}api/translation`;
}

export default class TranslationApi {
  public static async getAll(): Promise<any> {
    return await ApiUtilities.handleGet<any>(`${getRoot()}/app/WebUI`);
  }

  public static async getList(
    options: TranslationsRequestViewModel,
  ): Promise<PaginatedListResult<TranslationsResponseViewModel>> {
    return await ApiUtilities.handleGet<PaginatedListResult<TranslationsResponseViewModel>>(
      `${getRoot()}/app/WebUI/grouped-by-key`,
      options,
    );
  }

  /**
   * Updates or inserts an item
   */
  public static async create(
    key: string,
    translations: { English: string | null; Finnish: string | null; Estonian: string | null },
    group?: string,
  ) {
    if (group !== null) {
      // var pieces = key.toLowerCase().split('-');
      // group = pieces[0];
    }
    let viewmodel = new TranslationsInsertUpdateViewModel();

    viewmodel.translations = translations;
    viewmodel.translationGroup = group ?? '';
    viewmodel.translationKey = key;
    let system = 'WebUI';
    viewmodel.cargoKeepApplicationEnum = system;
    const URL = `${getRoot()}/create`;
    return await ApiUtilities.handlePost<boolean>(URL, viewmodel);
  }

  /**
   * Updates or inserts an item
   */
  public static async upsert(
    key: string,
    translations: { english: string | null; finnish: string | null; estonian: string | null },
    group?: string,
    translatableSystem?: string,
  ) {
    if (group !== null) {
      // var pieces = key.toLowerCase().split('-');
      // group = pieces[0];
    }
    let viewmodel = new TranslationsInsertUpdateViewModel();

    viewmodel.translations = translations;
    viewmodel.translationGroup = group ?? '';
    viewmodel.translationKey = key;
    let system = 'WebUI';
    if (translatableSystem !== null && translatableSystem !== undefined) {
      system = translatableSystem;
    }
    viewmodel.cargoKeepApplicationEnum = system;
    const URL = `${getRoot()}/insert-update`;
    return await ApiUtilities.handlePut<boolean>(URL, viewmodel);
  }

  public static getGroupsSelectComponentList(params: {}): Promise<Array<SelectComponentValue>> {
    const URL = `${getRoot()}/app/WebUI/groups/`;
    return ApiUtilities.handleGet<Array<SelectComponentValue>>(URL, { ...params });
  }

  public static importExcelRows(formData: FormData): Promise<boolean | null> {
    return ApiUtilities.handleFilePost(`${getRoot()}/import-from-excel/`, formData);
  }

  public static async downloadExcelFile() {
    const URL = `${getRoot()}/excel/WebUI`;
    const file = await ApiUtilities.handleFileGet(URL);
    const filename = 'translations.xlsx';
    CommonUtilities.downloadFile(file, false, filename);
  }
}
