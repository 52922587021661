import { Moment } from 'moment';

export default class TenantResponseViewModel {
  clientCode: string;
  businessArea: string;
  vatNr: string;
  registerNr: string;
  name: string;
  email: string;
  phone: string;

  // address
  country: string;
  postalCode: string;
  city: string;
  region: string;
  state: string;
  firstAddressLine: string;
  secondAddressLine: string;
  additionalData: string;

  //
  // Personal data
  //
  contactName: string;
  contactPhoneNumber: string;
  contactEmail: string;

  //package info
  locked: boolean;
  isDemoAccount: boolean;
  bookingPortalEnabled: boolean;
  allowAccountingSystemIntegration: boolean;
  allowSendingEInvoices: boolean;

  maxUsers?: number;
  activeUntil?: Date | Moment;
  offeredPrice?: number;
  notes: string;
}
