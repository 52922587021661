import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const HeavyLoadTrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="9 65 15 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19991" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-98-7"
         ry="0.82608747"
         rx="0.8260867"
         cy="71.305206"
         cx="18.388542" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199946" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-5"
         ry="0.82606715"
         rx="0.82606888"
         cy="71.305206"
         cx="20.505209" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19954" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-3"
         ry="0.82627183"
         rx="0.82627147"
         cy="71.305206"
         cx="22.621876" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19997" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="m 23.547917,71.4375 1.5875,5e-6 v -1.322922 h -12.7 l -1.5875,-1.322917 H 6.614583 V 69.85 h 3.96875 l 1.852084,1.5875 H 17.4625"
         id="path13905-0-67" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199716" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3-8"
         cx="18.388542"
         cy="71.305206"
         rx="0.29701763"
         ry="0.29701686" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199716" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3-8-8"
         cx="20.505207"
         cy="71.305206"
         rx="0.29701763"
         ry="0.29701686" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199716" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3-8-1"
         cx="22.621874"
         cy="71.305206"
         rx="0.29701763"
         ry="0.29701686" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19997" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 19.317439,71.346358 h 0.261728"
         id="path26254" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 21.497396,71.355729 h 0.198437"
         id="path26258" />
    </svg>
  );
};

export default HeavyLoadTrailerIcon;
