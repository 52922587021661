import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const WalkingFloorTrailerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="8 8 15 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20037" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-98-0"
         ry="1.0889932"
         rx="1.0907005"
         cy="14.686005"
         cx="17.063471" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20037" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-8-3"
         ry="1.0889932"
         rx="1.0907006"
         cy="14.686005"
         cx="19.710058" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.20037" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-7-8"
         ry="1.0889918"
         rx="1.0907009"
         cy="14.686005"
         cx="22.356646" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199778" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="m 23.471633,14.454071 h 1.563959 V 13.332473 H 12.002596 v 1.121598 h 3.909899"
         id="path13905-0-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.1999" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 18.254436,14.471696 h 0.264658"
         id="path13907-7-7" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.1999" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 20.901022,14.471696 h 0.264659"
         id="path13909-75-0" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19982" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-3-2"
         cx="17.063471"
         cy="14.686005"
         rx="0.56165814"
         ry="0.56077784" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19982" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-7-1"
         cx="19.710058"
         cy="14.686005"
         rx="0.56165814"
         ry="0.56077784" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.19982" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-6-9"
         cx="22.356646"
         cy="14.686005"
         rx="0.56165814"
         ry="0.56077784" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 25.035499,12.864665 V 8.0389015 H 6.7159849 v 4.8257635 z"
         id="path22979" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 11.950132,8.0389015 V 12.864665"
         id="path22981" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 19.801351,8.0389015 V 12.864665"
         id="path22983" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 8.0245216,11.594728 H 10.641594 L 9.8564728,11.086753"
         id="path22985" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 10.641594,11.594728 9.8564729,12.102703"
         id="path22987" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 14.567206,11.594728 h 2.617071 l -0.78512,-0.507975"
         id="path22989" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 17.184277,11.594728 -0.78512,0.507975"
         id="path22991" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 21.109888,11.594728 h 2.617073 L 22.94184,11.086753"
         id="path22993" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199836" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 23.726961,11.594728 22.94184,12.102703"
         id="path22995" />
    </svg>
  );
};

export default WalkingFloorTrailerIcon;
