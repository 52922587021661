import { Moment } from 'moment';

export default class ShippingOrderPublicViewModel {
  id: string;
  number: string;
  purchasePrice: number;
  shipments: ShippingOrderPublicResponseViewModel[];
  partnerConfirmationDateTime?: Date | Moment;
  tenantName: string;
};

export type ShippingOrderPublicResponseViewModel = {
  id: string;
  number: string;
  pickupCountry: string;
  pickupPostalCode: string;
  deliveryCountry: string;
  deliveryPostalCode: string;
};