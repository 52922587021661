import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const PlusIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 21,
  height = 20,
  fill = '#0A6CBA',
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0031 1.89478e-06C9.92491 -0.000195628 9.84749 0.0150519 9.77524 0.0448729C9.70299 0.0746938 9.63736 0.118499 9.58209 0.173769C9.52682 0.22904 9.48301 0.294702 9.45319 0.366954C9.42337 0.439206 9.40809 0.516626 9.40829 0.59479V9.40828H0.594798C0.43834 9.40787 0.288081 9.46927 0.176684 9.57914C0.0652875 9.689 0.00177337 9.83844 1.06353e-05 9.99489C-0.000458159 10.0732 0.0145753 10.1509 0.0442748 10.2234C0.0739743 10.2959 0.117746 10.3617 0.173043 10.4172C0.228341 10.4727 0.294065 10.5167 0.366452 10.5467C0.438838 10.5766 0.516461 10.5919 0.594798 10.5917H9.40829V19.4052C9.40809 19.4834 9.42337 19.5608 9.45319 19.633C9.48301 19.7053 9.52682 19.771 9.58209 19.8262C9.63736 19.8815 9.70299 19.9253 9.77524 19.9551C9.84749 19.9849 9.92491 20.0002 10.0031 20C10.0812 20.0002 10.1587 19.9849 10.2309 19.9551C10.3032 19.9253 10.3688 19.8815 10.4241 19.8262C10.4793 19.771 10.5231 19.7053 10.553 19.633C10.5828 19.5608 10.5981 19.4834 10.5979 19.4052V10.5917H19.4134C19.4917 10.5919 19.5694 10.5766 19.6417 10.5467C19.7141 10.5167 19.7799 10.4727 19.8351 10.4172C19.8904 10.3617 19.9342 10.2959 19.9639 10.2234C19.9936 10.1509 20.0086 10.0732 20.0082 9.99489C20.0064 9.83844 19.9429 9.689 19.8315 9.57914C19.7201 9.46927 19.5699 9.40787 19.4134 9.40828H10.5979V0.59479C10.5981 0.516626 10.5828 0.439206 10.553 0.366954C10.5231 0.294702 10.4793 0.22904 10.4241 0.173769C10.3688 0.118499 10.3032 0.0746938 10.2309 0.0448729C10.1587 0.0150519 10.0812 -0.000195628 10.0031 1.89478e-06Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIcon;
