import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as editStartActions } from './actions/PriceRequest.edit.start.action';
import { asyncActions as editSubmitActions } from './actions/PriceRequest.edit.submit.action';
import { asyncActions as pricesSubmitActions } from './actions/PriceRequest.prices.submit.action';
import { ErrorWrapper } from 'store/base';

export const REDUCER_KEY__PRICE_REQUEST = 'priceRequest';

export class PriceRequestState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  priceRequestId?: string;
  loading: boolean;
}

const defaultState: PriceRequestState = {
  loading: false,
};
export default (state: PriceRequestState = defaultState, action: Action): PriceRequestState => {
  // Edit actions
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editStartActions.done)) {
    return {
      type: action.type,
      priceRequestId: action.payload.result.priceRequestId,
      ...defaultState,
    };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }

  // Edit actions submit
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true, errorWrapper: undefined };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Edit prices submit
  if (isType(action, pricesSubmitActions.started)) {
    return { ...state, type: action.type, loading: true, errorWrapper: undefined };
  }
  if (isType(action, pricesSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, pricesSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  return state;
};
