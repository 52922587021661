import actionCreatorFactory from 'typescript-fsa';

import { ErrorWrapper } from 'store/base';
import { FleetApi } from 'api/Fleet.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import FleetResponseViewModel from '../../../ViewModels/FleetResponseViewModel';
import FleetUtilities from 'helpers/ModelUtilities/FleetUtilities';
import LocaleUtilities from '../../../../../../../helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'FLEET/CREATE/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const submitAction = (formValues: FleetResponseViewModel) => async (dispatch) => {
  async function mainAction(): Promise<void> {
    dispatch(asyncActions.started({}));
    FleetUtilities.formatFormValuesNumbers(formValues);
    await FleetApi.add(formValues);
    dispatch(asyncActions.done({ params: {}, result: {} }));
    toastr.success(
      LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
      LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
    );
  }

  async function catchAction(exception: any): Promise<void> {
    dispatch(asyncActions.failed({ params: {}, error: exception }));
    toastr.error(
      LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
      LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
    );
  }

  dispatch(asyncActions.started({}));
  await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
};

export default submitAction;
