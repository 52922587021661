import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { primaryDarkColor } from 'theme';

const TautlinerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 58,
  height = 21,
  fill = primaryDarkColor,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="9 44 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-9"
         ry="1.0892769"
         rx="1.0906283"
         cy="51.727604"
         cx="17.063803" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-2-2"
         ry="1.0892769"
         rx="1.0906284"
         cy="51.727604"
         cx="19.710213" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         id="path13858-0-8"
         ry="1.0892755"
         rx="1.0906286"
         cy="51.727604"
         cx="22.356625" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1"
         d="m 23.471539,51.495611 h 1.563856 V 50.37372 H 12.003262 v 1.121891 h 3.90964"
         id="path13905-2" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 18.254688,51.513241 h 0.26464"
         id="path13907-8" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 20.901098,51.513241 H 21.16574"
         id="path13909-7" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-2"
         cx="17.063803"
         cy="51.727604"
         rx="0.56162089"
         ry="0.56092387" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-0-1"
         cx="19.710213"
         cy="51.727604"
         rx="0.56162089"
         ry="0.56092387" />
      <ellipse
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199989" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         id="path13911-9-4"
         cx="22.356625"
         cy="51.727604"
         rx="0.56162089"
         ry="0.56092387" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="m 25.03548,49.906314 v -4.82721 H 6.7145203 v 4.82721 z"
         id="path14770" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 22.418199,49.906314 V 49.144123"
         id="path14778" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 19.80092,49.906314 V 49.144123"
         id="path14780" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 17.183639,49.906314 V 49.144123"
         id="path14782" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 14.566361,49.906314 V 49.144123"
         id="path14784" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 11.949081,49.906314 V 49.144123"
         id="path14786" />
      <path
         fill="none" fill-opacity="1" stroke="#000000" stroke-width="0.199874" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="none" stroke-opacity="1" paint-order="stroke markers fill"
         d="M 9.3318002,49.906314 V 49.144123"
         id="path14788" />
    </svg>
  );
};

export default TautlinerIcon;
