import actionCreatorFactory from 'typescript-fsa';
import { ErrorWrapper } from 'store/base';
import { PriceRequestApi } from 'api/PriceRequest.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from 'helpers/CommonUtilities';
import PriceRequestUtilities from 'helpers/ModelUtilities/PriceRequestUtilities';
import { PriceRequestResponseViewModel } from '../../ViewModels';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'PRICE_REQUEST/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

const submitAction =
  (formValues: PriceRequestResponseViewModel, id: string | undefined) => async (dispatch) => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      PriceRequestUtilities.formatFormValuesNumbers(formValues);
      if (!id) {
        const response = await PriceRequestApi.add(formValues);
        if (response) id = response;
      } else {
        await PriceRequestApi.update(id, formValues);
      }
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
    return id;
  };

export default submitAction;
