import { NumberUtilities } from '../NumberUtilities';
import { PartnerResponseViewModel } from 'App/routes/Tenant/Partner/ViewModels';
import { SelectComponentValue } from '../../viewModels/common/SelectComponentValue';
import LocaleUtilities from '../LocaleUtilities';
import AddressBookItemContactListResponseViewModel from 'App/routes/Tenant/AddressBook/ViewModels/AddressBookItemContactListResponseViewModel';

export default class PartnerUtilities {
  public static formatFormValuesNumbers(formValues: PartnerResponseViewModel): any {
    if (!formValues) {
      return;
    }
    if (formValues.address?.lng) formValues.address.lng = formValues.address?.lng.toString();
    if (formValues.address?.lat) formValues.address.lat = formValues.address?.lat.toString();
    formValues.paymentTerms = NumberUtilities.tryFormatFloat(formValues.paymentTerms?.toString());
  }

  public static partnerContactSelectComponents(
    partnerContacts: AddressBookItemContactListResponseViewModel[],
  ): SelectComponentValue[] {
    const contactSelect: SelectComponentValue[] = partnerContacts.map((item) => {
      return { value: item.id?.toString() ?? '0', label: item.contact.name };
    });

    contactSelect.unshift({
      label: LocaleUtilities.i18n('form-Select', 'base.form', true),
      value: '',
    });
    return contactSelect;
  }
}
