import * as React from 'react';
import { ReactElement } from 'react';
import { NumberUtilities } from '../NumberUtilities';
import FleetResponseViewModel from 'App/routes/Tenant/Fleet/ViewModels/FleetResponseViewModel';
import { FleetType } from 'store/enums';
import VehicleIcon from 'components/Icons/FleetType/VehicleIcon';
import BoxTruckIcon from 'components/Icons/FleetType/BoxTruckIcon';
import ContainerIcon from 'components/Icons/FleetType/ContainerIcon';
import ForkliftIcon from 'components/Icons/FleetType/ForkliftIcon';
import CraneTruckIcon from 'components/Icons/FleetType/CraneTruckIcon';
import VanIcon from 'components/Icons/FleetType/VanIcon';
import TailLiftTruckIcon from 'components/Icons/FleetType/TailLiftTruckIcon';
import BoxSemitrailerIcon from 'components/Icons/FleetType/BoxSemitrailerIcon';
import FlatbedTrailerIcon from 'components/Icons/FleetType/FlatbedTrailerIcon';
import ContainerTruckIcon from 'components/Icons/FleetType/ContainerTruckIcon';
import TractorUnitIcon from 'components/Icons/FleetType/TractorUnitIcon';
import TautlinerIcon from 'components/Icons/FleetType/TautlinerIcon';
import MegaTrailerIcon from 'components/Icons/FleetType/MegaTrailerIcon';
import RefrigeratedTrailerIcon from 'components/Icons/FleetType/RefrigeratedTrailerIcon';
import FullTrailerIcon from 'components/Icons/FleetType/FullTrailerIcon';
import DumpTruckIcon from 'components/Icons/FleetType/DumpTruckIcon';
import DumpTrailerIcon from 'components/Icons/FleetType/DumpTrailerIcon';
import WalkingFloorTrailerIcon from 'components/Icons/FleetType/WalkingFloorTrailerIcon';
import SideLoaderTruckIcon from 'components/Icons/FleetType/SideLoaderTruckIcon';
import SideLoaderTrailerIcon from 'components/Icons/FleetType/SideLoaderTrailerIcon';
import InLoaderTrailerIcon from 'components/Icons/FleetType/InLoaderTrailerIcon';
import HeavyLoadTrailerIcon from 'components/Icons/FleetType/HeavyLoadTrailerIcon';

export default class FleetUtilities {
  public static formatFormValuesNumbers(formValues: FleetResponseViewModel): any {
    if (!formValues) {
      return;
    }
    formValues.height = NumberUtilities.tryFormatFloat(formValues.height?.toString());
    formValues.length = NumberUtilities.tryFormatFloat(formValues.length?.toString());
    formValues.width = NumberUtilities.tryFormatFloat(formValues.width?.toString());
    formValues.weight = NumberUtilities.tryFormatFloat(formValues.weight?.toString());
    formValues.maxWeight = NumberUtilities.tryFormatFloat(formValues.maxWeight?.toString());
    formValues.maxLdm = NumberUtilities.tryFormatFloat(formValues.maxLdm?.toString());
    formValues.maxVolume = NumberUtilities.tryFormatFloat(formValues.maxVolume?.toString());
    formValues.averageConsumption = NumberUtilities.tryFormatFloat(
      formValues.averageConsumption?.toString(),
    );
    formValues.costPerHour = NumberUtilities.tryFormatFloat(formValues.costPerHour?.toString());
    formValues.costPerKm = NumberUtilities.tryFormatFloat(formValues.costPerKm?.toString());
  }

  public static FleetIconByType(type: string): ReactElement {
    switch (type) {
      case FleetType.Vehicle.toString():
        return <VehicleIcon />;
      case FleetType.Forklift.toString():
        return <ForkliftIcon />;
      case FleetType.CraneTruck.toString():
        return <CraneTruckIcon />;
      case FleetType.Van.toString():
        return <VanIcon />;
      case FleetType.TailLiftTruck.toString():
        return <TailLiftTruckIcon />;
      case FleetType.BoxTruck.toString():
        return <BoxTruckIcon />;
      case FleetType.TractorUnit.toString():
        return <TractorUnitIcon />;
      case FleetType.BoxSemiTrailer.toString():
        return <BoxSemitrailerIcon />;
      case FleetType.FlatbedTrailer.toString():
        return <FlatbedTrailerIcon />;
      case FleetType.Container.toString():
        return <ContainerIcon />;
      case FleetType.ContainerTruck.toString():
        return <ContainerTruckIcon />;
      case FleetType.Tautliner.toString():
        return <TautlinerIcon />;
      case FleetType.MegaTrailer.toString():
        return <MegaTrailerIcon />;
      case FleetType.RefrigeratedTrailer.toString():
        return <RefrigeratedTrailerIcon />;
      case FleetType.FullTrailer.toString():
        return <FullTrailerIcon />;
      case FleetType.DumpTruck.toString():
        return <DumpTruckIcon />;
      case FleetType.DumpTrailer.toString():
        return <DumpTrailerIcon />;
      case FleetType.WalkingFloorTrailer.toString():
        return <WalkingFloorTrailerIcon />;
      case FleetType.SideLoaderTruck.toString():
        return <SideLoaderTruckIcon />;
      case FleetType.SideLoaderTrailer.toString():
        return <SideLoaderTrailerIcon />;
      case FleetType.InLoaderTrailer.toString():
        return <InLoaderTrailerIcon />;
      case FleetType.HeavyLoadTrailer.toString():
        return <HeavyLoadTrailerIcon />;
    }
    return <></>;
  }
}
