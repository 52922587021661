import * as React from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { UserModelRoles } from '../helpers/AuthUtils';
import AuthUtilities from '../helpers/AuthUtils/AuthUtils';
import Component404 from './Session/Component_404';

const PrivateRoute = (
  // TODO: fix any
  {
    component: TheComponent,
    currentUser = null,
    allowedRoles = [UserModelRoles.superAdmin],
    ...rest
  }: any,
) => {
  const utils = new AuthUtilities();
  return (
    <Route
      {...rest}
      render={(props) =>
        !utils.isCurrentUserLoggedIn(currentUser) ? (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        ) : !utils.hasValidRole(allowedRoles, currentUser) ? (
          <Component404 />
        ) : (
          <TheComponent {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
