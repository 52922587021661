import { routePaths } from './store/api.constants';

export default {
  items: [
    {
      id: 'menu',
      title: '',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: routePaths.dashboard1,
          classes: 'nav-item',
          icon: 'feather icon-home',
        },
        {
          id: 'tenants',
          title: 'Tenants',
          type: 'item',
          url: routePaths.tenants.list,
          classes: 'nav-item',
          icon: 'fa fas fa-briefcase',
        },
        {
          id: 'translations',
          title: 'Translations',
          type: 'item',
          url: routePaths.translations.list,
          classes: 'nav-item',
          icon: 'fa fas fa-briefcase',
        },
      ],
    },
  ],
};
