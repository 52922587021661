import { NumberUtilities } from '../NumberUtilities';
import { CustomerResponseViewModel } from 'App/routes/Tenant/Customer/ViewModels';
import React from 'react';
import { CustomerPosition } from 'store/enums';
import HouseIcon from 'components/Icons/CustomerContactType/HouseIcon';
import LogisticsIcon from 'components/Icons/CustomerContactType/LogisticsIcon';
import EllipsisIcon from 'components/Icons/CustomerContactType/EllipsisIcon';
import AccountingIcon from '../../components/Icons/CustomerContactType/AccountingIcon';
import SalesIcon from '../../components/Icons/CustomerContactType/SalesIcon';

export default class CustomerUtilities {
  public static formatFormValuesNumbers(formValues: CustomerResponseViewModel): any {
    if (!formValues) {
      return;
    }
    if (formValues.address?.lng) formValues.address.lng = formValues.address?.lng.toString();
    if (formValues.address?.lat) formValues.address.lat = formValues.address?.lat.toString();
    formValues.paymentTermDays = NumberUtilities.tryFormatFloat(
      formValues.paymentTermDays?.toString(),
    );
  }

  public static customerIcon(type: string): JSX.Element {
    if (type === CustomerPosition.Warehouse.toString()) {
      return <HouseIcon />;
    } else if (type === CustomerPosition.Logistics.toString()) {
      return <LogisticsIcon />;
    } else if (type === CustomerPosition.Accounting.toString()) {
      return <AccountingIcon />;
    } else if (type === CustomerPosition.Sales.toString()) {
      return <SalesIcon />;
    } else {
      return <EllipsisIcon />;
    }
  }
}
