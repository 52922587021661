import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';

import Aux from '../../../../../../hoc/_Aux';
import NavIcon from './../NavIcon';
import NavBadge from './../NavBadge';
import * as actionTypes from '../../../../../../store/actions';
import clsx from 'clsx';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';

class NavItem extends Component {
  render() {
    let itemTitle = LocaleUtilities.i18n(this.props.item.title, 'sidebar', true);
    if (this.props.item.icon) {
      itemTitle = <span className="pcoded-mtext">{itemTitle}</span>;
    }

    let itemTarget = '';
    if (this.props.item.target) {
      itemTarget = '_blank';
    }

    let subContent;
    if (this.props.item.external) {
      subContent = (
        <a href={this.props.item.url} target="_blank" rel="noopener noreferrer">
          <NavIcon items={this.props.item} />
          {itemTitle}
          <NavBadge layout={this.props.layout} items={this.props.item} />
        </a>
      );
    } else {
      let paths = [];
      if (this.props.currentLink) paths = this.props.currentLink.split('/');
      subContent = (
        <NavLink
          to={this.props.item.url}
          className={clsx(
            'nav-item',
            this.props.currentLink === this.props.item.url && 'active',
            paths.length > 2 && this.props.item.url.startsWith(`/${paths[1]}`) && 'active',
          )}
          exact={true}
          target={itemTarget}
        >
          <NavIcon items={this.props.item} />
          {itemTitle}
          <NavBadge layout={this.props.layout} items={this.props.item} />
        </NavLink>
      );
    }
    let mainContent = '';
    if (this.props.layout === 'horizontal') {
      mainContent = <li onClick={this.props.onItemLeave}>{subContent}</li>;
    } else {
      if (this.props.windowWidth < 992) {
        mainContent = (
          <li className={this.props.item.classes} onClick={this.props.onItemClick}>
            {subContent}
          </li>
        );
      } else {
        mainContent = <li className={this.props.item.classes}>{subContent}</li>;
      }
    }

    return <Aux>{mainContent}</Aux>;
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onItemClick: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    onItemLeave: () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(windowSize(NavItem)));
