import * as React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { routePaths } from 'store/api.constants';
import asyncComponent from 'util/asyncComponent';
import { injectAsyncReducer } from 'store';
import { store } from 'index';
import AdminLayout2 from '../../layout/AdminLayout';
import PrivateRoute from 'components/PrivateRoute.component';
import { UserModelRoles } from 'helpers/AuthUtils';
import { REDUCER_NAME__ADDRESS_BOOKS } from 'App/routes/Tenant/AddressBook/AddressBook.List/AddressBook.reducer';
import { REDUCER_NAME__ADDRESS_BOOK } from 'App/routes/Tenant/AddressBook/AddressBook.Display/AddressBookItem.reducer';
import { REDUCER_NAME__SHIPMENTS } from 'App/routes/Tenant/Shipment/Shipment.List/ShipmentList.reducer';
import { REDUCER_KEY__SHIPMENT } from 'App/routes/Tenant/Shipment/Shipment.Display/Shipment.reducer';
import { REDUCER_NAME__PRICE_REQUESTS } from '../Tenant/PriceRequest/PriceRequest.List/PriceRequestList.reducer';
import { REDUCER_KEY__PRICE_REQUEST } from '../Tenant/PriceRequest/PriceRequest.Display/PriceRequest.reducer';

/** REDUCERS **/

class ForClientPortalRoutesComponent extends React.Component<any> {
  render() {
    return (
      <AdminLayout2>
        <Switch>
          {[routePaths.dashboard, routePaths.dashboard1].map((path, index) => (
            <Route
              exact={true}
              path={path}
              key={index}
              component={asyncComponent(async () => {
                const container = await import('./Dashboard/Dashboard.container');
                const reducer = await import('./Dashboard/Dashboard.reducer');
                injectAsyncReducer(store, reducer.REDUCER_NAME__DASHBOARD, reducer.default);
                return container;
              })}
            />
          ))}
          <Route
            exact={true}
            path={routePaths.profile}
            key={'profile'}
            component={asyncComponent(async () => {
              const container = await import('../Sessions/Profile/Profile.container');
              const reducer = await import('../Sessions/Profile/Profile.reducer');
              injectAsyncReducer(store, reducer.REDUCER_KEY__PROFILE, reducer.default);
              return container;
            })}
          />
          ==========================| ====| SHIPMENT ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            exact={true}
            key={'shipments'}
            path={routePaths.shipments.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.List/ShipmentList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__SHIPMENTS, reducer.default);
              return await import('./Shipment/Shipment.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.shipments.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.Display/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__SHIPMENT, reducer.default);
              return await import('./Shipment/Shipment.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.shipments.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.Display/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__SHIPMENT, reducer.default);
              return await import('./Shipment/Shipment.Display/Edit');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.shipments.display}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.Display/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__SHIPMENT, reducer.default);
              return await import('./Shipment/Shipment.Display/Display');
            })}
          />
          ==========================| ====| PRICE REQUEST ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            exact={true}
            key={'priceRequests'}
            path={routePaths.priceRequests.list}
            component={asyncComponent(async () => {
              const reducer = await import(
                'App/routes/Tenant/PriceRequest/PriceRequest.List/PriceRequestList.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__PRICE_REQUESTS, reducer.default);
              return await import('App/routes/Tenant/PriceRequest/PriceRequest.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.priceRequests.new}
            component={asyncComponent(async () => {
              const reducer = await import(
                './PriceRequest/PriceRequest.Display/PriceRequest.reducer'
              );
              injectAsyncReducer(store, REDUCER_KEY__PRICE_REQUEST, reducer.default);
              return await import('./PriceRequest/PriceRequest.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.priceRequests.edit}
            component={asyncComponent(async () => {
              const reducer = await import(
                './PriceRequest/PriceRequest.Display/PriceRequest.reducer'
              );
              injectAsyncReducer(store, REDUCER_KEY__PRICE_REQUEST, reducer.default);
              return await import('./PriceRequest/PriceRequest.Display/Edit');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.priceRequests.display}
            component={asyncComponent(async () => {
              const reducer = await import(
                './PriceRequest/PriceRequest.Display/PriceRequest.reducer'
              );
              injectAsyncReducer(store, REDUCER_KEY__PRICE_REQUEST, reducer.default);
              return await import('./PriceRequest/PriceRequest.Display/Display');
            })}
          />
          ==========================| ====| ADDRESS BOOK ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            exact={true}
            key={'addressBook'}
            path={routePaths.addressBook.list}
            component={asyncComponent(async () => {
              const reducer = await import(
                'App/routes/Tenant/AddressBook/AddressBook.List/AddressBook.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__ADDRESS_BOOKS, reducer.default);
              return await import('App/routes/Tenant/AddressBook/AddressBook.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.addressBook.new}
            component={asyncComponent(async () => {
              const reducer = await import(
                'App/routes/Tenant/AddressBook/AddressBook.Display/AddressBookItem.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__ADDRESS_BOOK, reducer.default);
              return await import('App/routes/Tenant/AddressBook/AddressBook.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.tenantCustomer]}
            path={routePaths.addressBook.edit}
            component={asyncComponent(async () => {
              const reducer = await import(
                'App/routes/Tenant/AddressBook/AddressBook.Display/AddressBookItem.reducer'
              );
              injectAsyncReducer(store, REDUCER_NAME__ADDRESS_BOOK, reducer.default);
              return await import('App/routes/Tenant/AddressBook/AddressBook.Display/Edit');
            })}
          />
        </Switch>
      </AdminLayout2>
    );
  }
}

export default ForClientPortalRoutesComponent;
