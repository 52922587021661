import { routePaths } from './store/api.constants';

export default {
  items: [
    {
      id: 'support',
      title: '',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'dashboard',
          title: 'sidebar-menu-Dashboard',
          type: 'item',
          url: routePaths.dashboard,
          classes: 'nav-item',
          icon: 'feather icon-home',
        },
        {
          id: 'price-requests',
          title: 'sidebar-menu-Price requests',
          type: 'item',
          url: routePaths.priceRequests.list,
          classes: 'nav-item',
          icon: 'feather icon-layers',
          badge: {
            type: ' badge-light pull-right sidebar-badge sidebar-badge-inbox',
            title: '0',
          },
        },
        {
          id: 'shipments',
          title: 'sidebar-menu-Shipments',
          type: 'item',
          url: routePaths.shipments.list,
          classes: 'nav-item',
          icon: 'fa fa-boxes',
        },
        {
          id: 'addressBook',
          title: 'sidebar-menu-Address book',
          type: 'item',
          url: routePaths.addressBook.list,
          classes: 'nav-item',
          icon: 'fa fas fa-address-card',
        },
      ],
    },
  ],
};
