/**
 * Created by zekar on 3/3/2017.
 */

import axios from 'axios';
import * as qs from 'qs';
import { BACKEND_HOST_AUTH, endpoints } from 'store/api.constants';
import ApiUtils from 'helpers/ApiUtils';
import { LocalStorageUserModel } from 'helpers/AuthUtils';
import LoginRequestViewModel from 'App/routes/Sessions/Login/viewModels/LoginRequestViewModel';
import { ErrorWrapper } from 'store/base';
import AccountChangePasswordRequestViewModel from 'App/routes/Sessions/ChangePassword/viewModels/AccountChangePasswordRequestViewModel';
import AccountResponseViewModel from '../App/routes/Sessions/Profile/viewModels/AccountResponseViewModel';

/////////////////////////////
/// USER SERVICE
/////////////////////////////

/* tslint:disable:variable-name */
export class TokenInfo {
  access_token: string;
  expires_in: number;
  resource: string;
  token_type: string;
  refresh_token: string;
  appScope: string;
}

/* tslint:enable:variable-name */

function getRoot(): string {
  return `${BACKEND_HOST_AUTH()}api/account`;
}

export default class AccountApi {
  public static async loginUser(viewModel: LoginRequestViewModel): Promise<TokenInfo> {
    // let isSuccess = true;

    const url = BACKEND_HOST_AUTH() + endpoints.auth.login;
    try {
      return await ApiUtils.handleApi<TokenInfo>(
        axios.post<TokenInfo>(url, qs.stringify(viewModel)),
        true,
        false,
      );
    } catch (exception: any) {
      throw new ErrorWrapper(exception, exception.description);
    }
  }

  public static getCurrentUser(accessToken: string): Promise<LocalStorageUserModel> {
    return ApiUtils.handleGet<LocalStorageUserModel>(
      `${BACKEND_HOST_AUTH()}api/account/me`,
      {},
      accessToken,
    );
  }

  public static changePassword(formValues: AccountChangePasswordRequestViewModel) {
    return ApiUtils.handlePost<boolean>(`${getRoot()}/change-password`, formValues);
  }

  public static forgotPassword(formValues: any) {
    return ApiUtils.handlePost<boolean>(
      `${BACKEND_HOST_AUTH()}api/authorize/forgot-password`,
      formValues,
    );
  }

  public static resetPassword(formValues: any) {
    return ApiUtils.handlePost<boolean>(
      `${BACKEND_HOST_AUTH()}api/authorize/reset-password`,
      formValues,
    );
  }

  public static toggleActivation(userId: number, activated: boolean) {
    return ApiUtils.handlePut<boolean>(
      `${getRoot()}/toggle-activation/${userId}/${activated}`,
      null,
    );
  }

  public static userProfile(): Promise<AccountResponseViewModel> {
    return ApiUtils.handleGet<AccountResponseViewModel>(`${getRoot()}/profile`, {});
  }

  public static saveProfile(formValues: AccountResponseViewModel) {
    return ApiUtils.handlePost<boolean>(`${getRoot()}/profile`, formValues);
  }
}
