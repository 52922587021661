import * as React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { routePaths } from 'store/api.constants';
import asyncComponent from 'util/asyncComponent';
import { injectAsyncReducer } from 'store';
import { store } from 'index';
import AdminLayout from '../../layout/AdminLayout';
import PrivateRoute from 'components/PrivateRoute.component';
import { UserModelRoles } from 'helpers/AuthUtils';
import { REDUCER_NAME__TENANTS } from './Tenants/Tenant.List/TenantList.reducer';
import { REDUCER_KEY__TENANT } from './Tenants/Tenant.Display/Tenant.reducer';
import { REDUCER_NAME__TRANSLATIONS } from './Translations/Translations.List/Translations.reducer';

/** REDUCERS **/

class ForAdminRoutesComponent extends React.Component<any> {
  componentDidMount() {}

  render() {
    return (
      <AdminLayout>
        <Switch>
          {[routePaths.dashboard, routePaths.dashboard1].map((path, index) => (
            <Route
              exact={true}
              path={path}
              key={index}
              component={asyncComponent(async () => {
                const container = await import('./Dashboard/Dashboard.container');
                const reducer = await import('./Dashboard/Dashboard.reducer');
                injectAsyncReducer(store, reducer.REDUCER_NAME__DASHBOARD, reducer.default);
                return container;
              })}
            />
          ))}
          ==========================| ====| TENANTS ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.superAdmin]}
            exact={true}
            key={'test'}
            path={routePaths.tenants.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Tenants/Tenant.List/TenantList.reducer');
              injectAsyncReducer(store, REDUCER_NAME__TENANTS, reducer.default);
              return await import('./Tenants/Tenant.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.superAdmin]}
            path={routePaths.tenants.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Tenants/Tenant.Display/Tenant.reducer');
              injectAsyncReducer(store, REDUCER_KEY__TENANT, reducer.default);
              return await import('./Tenants/Tenant.Display/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.superAdmin]}
            path={routePaths.tenants.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Tenants/Tenant.Display/Tenant.reducer');
              injectAsyncReducer(store, REDUCER_KEY__TENANT, reducer.default);
              return await import('./Tenants/Tenant.Display/Edit');
            })}
          />
          ==========================| ====| TRNSLATIONS ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.superAdmin]}
            exact={true}
            key={'translations'}
            path={routePaths.translations.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Translations/Translations.List/Translations.reducer');
              injectAsyncReducer(store, REDUCER_NAME__TRANSLATIONS, reducer.default);
              return await import('./Translations/Translations.List');
            })}
          />
        </Switch>
      </AdminLayout>
    );
  }
}

export default ForAdminRoutesComponent;
