import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { ErrorWrapper } from '../../../../../../../store/base';
import { UserApi } from '../../../../../../../api/User.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../../../helpers/CommonUtilities';
import LocaleUtilities from '../../../../../../../helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
const type = 'TENANT/USER//DELETE/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(userId: number, tenantId?: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({ confirmLoading: true }));
      await UserApi.remove(userId, tenantId);
      toastr.success(
        LocaleUtilities.i18n('toastr-success-title-Successful', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data saved successfully', 'base.message', true),
      );
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error(
        LocaleUtilities.i18n('toastr-error-title-Error', 'base.message', true),
        LocaleUtilities.i18n('toastr-Data not saved', 'base.message', true),
      );
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
